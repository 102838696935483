import React, { useEffect, useState } from "react";
import DisplayContent from "../component/DisplayContent";
import imgDemo from "../assets/img/image_5.png";
import iconImg from "../assets/icon/img.svg";
import ModalCardNFT from "../component/modal/ModalCardNFT";
import { useNavigate, useParams } from "react-router-dom";
import client from "../axios/client";
import { metaData } from "../model/metadata";

import {
  Commitment,
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
} from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import * as anchor from "@project-serum/anchor";
import IDL from "../program/blatant_program_idl.json";
import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";
import { PROGRAM_ID } from "../utils/program";

import { useRecoilState } from "recoil";
import { currentUser } from "../store/recoil";
import { useWallet } from "@solana/wallet-adapter-react";
import { toast } from "react-toastify";
import Loader from "../component/Loader";
import baseUrl from "../config/base";
const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);

var payload: {
  auction_id: string;
  wallet_address: string;
  quantity: number;
  escrow_address: string;
  details: {
    nft_name: string;
    image_url: string;
    mint_address: string;
    token_account: string;
    token_account_escrow?: string;
    txn_in?: string;
    sended: boolean;
  }[];
} = {
  auction_id: "",
  wallet_address: "",
  quantity: 0,
  escrow_address: "",
  details: [],
};

export default function DetailsAuctionOffer() {
  const navigate = useNavigate();
  const { auction_id } = useParams();
  const walletAddres: any = localStorage.getItem("walletAddres");

  const [dataMeta, setDataMeta] = useState<metaData>({} as metaData);
  const [confirm, setConfirm] = useState(false);
  const [user, curUser] = useRecoilState(currentUser);
  const [loading, setLoading] = useState(true);

  const [auction, setAuction]: any = useState({
    aution_id: "",
    nft_name: "",
    image_url: "",
  });

  const wallet = useWallet();
  const opts = {
    preflightCommitment: "processed" as Commitment,
  };
  function getConnection() {
    // const url = "https://virulent-dimensional-sanctuary.solana-mainnet.discover.quiknode.pro/a369a0013a3a344c8d8e193174d3ea3f88f17168";
    const url = baseUrl;
    let network = localStorage.getItem("network") || url;
    const connection = new Connection(network, opts.preflightCommitment);
    return connection;
  }

  const program = new anchor.Program(
    IDL as any as anchor.Idl,
    PROGRAM_ID,
    new anchor.AnchorProvider(
      getConnection(),
      wallet as any as NodeWallet,
      opts
    )
  );

  async function getParsedNfts() {
    // const wallet = user.wallet;

    const bodyLoad = {
      method: "qn_fetchNFTs",
      params: [walletAddres, []],
    };
    const options = {
      method: "POST",
      body: JSON.stringify(bodyLoad),
    };

    const res = await fetch(baseUrl,options
    );
    setDataMeta(await res.json());
    setLoading(false);
  }
  async function getAuction() {
    try {
      const res = await client.get(
        `/api/member/auction/find?aution_id=${auction_id}`
      );
      setAuction(res.data.auction);
      payload.auction_id = res.data.auction.id;
    } catch (error: any) {
      console.log(error);
    }
  }

  async function findAssociatedTokenAddress(
    walletAddress: PublicKey,
    tokenMintAddress: PublicKey
  ): Promise<PublicKey> {
    const solana = new Connection(baseUrl);
    const account = await solana.getTokenAccountsByOwner(walletAddress, {
      mint: tokenMintAddress,
    });
    console.log(account.value[0].pubkey.toString());
    return account.value[0].pubkey;
  }

  async function handleChange(e: any) {
    const { checked, value } = e.target;
    const data = JSON.parse(value);

    if (checked) {
      const tokenacc = await findAssociatedTokenAddress(
        new PublicKey(user.wallet),
        new PublicKey(data.tokenAddress)
      );

      payload.details.push({
        nft_name: data.name,
        image_url: data.imageUrl,
        mint_address: data.tokenAddress,
        token_account: tokenacc.toString(),
        sended: false,
      });
    } else {
      payload.details = payload.details.filter(
        (item) => item.mint_address !== data.tokenAddress
      );
    }
    console.log("Datanya", data);
    console.log("Bodynya", payload.details);
  }

  function msgBox(type: string = "warning", message: string) {
    if (type == "warning") {
      toast.warning(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "success") {
      toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "error") {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }
  function modalConfirm() {
    setConfirm(true);
  }

  let escrow: PublicKey;

  async function getEscrowTokenAccountAddress(
    seller: PublicKey,
    initTokenAccountA: PublicKey,
    mintNft: PublicKey,
    e: any
  ) {
    e.target.disabled = true;
    e.target.innerHTML = "Sending...";
    [escrow] = await PublicKey.findProgramAddress(
      [anchor.utils.bytes.utf8.encode("blatant"), seller.toBuffer()],
      program.programId
    );
    const escrowedXTokens = Keypair.generate();
    console.log(`escrowedXTokens :: `, escrowedXTokens.publicKey.toString());

    payload.details.map((item) => {
      if (item.mint_address === mintNft.toString()) {
        item.token_account_escrow = escrowedXTokens.publicKey.toString();
      }
    });

    const tx = await program.methods
      .initialize(new anchor.BN(1))
      .accounts({
        seller: seller,
        xMint: mintNft,
        sellerXToken: initTokenAccountA.toString(),
        escrow: escrow,
        escrowedXTokens: escrowedXTokens.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        rent: SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
      })
      .signers([escrowedXTokens])
      .rpc({ skipPreflight: true });

    console.log("Tx Hash nya: ", tx);

    payload.details.map((item) => {
      if (item.mint_address === mintNft.toString()) {
        item.txn_in = tx;
        item.sended = true;
      }
    });

    refresh();
  }

  function refresh() {
    setLoading(true);
    setLoading(false);
  }

  function submit() {
    console.log(payload);
    let ketemu: boolean = false;
    payload.details.map((item) => {
      if (item.sended == false) {
        ketemu = true;
      }
    });

    if (ketemu) {
      msgBox("error", "Please send all NFT first before submit");
      return false;
    }

    client
      .post("/api/member/auction/bid", payload)
      .then((res) => {
        msgBox("success", res.data.message);
        navigate("/auction");
        window.location.reload();
      })
      .catch((err) => {
        msgBox("error", err.response.data.message);
      });
  }
  async function getEscrow(address: PublicKey) {
    [escrow] = await PublicKey.findProgramAddress(
      [anchor.utils.bytes.utf8.encode("blatant"), address.toBuffer()],
      program.programId
    );
    payload.escrow_address = escrow.toString();
    console.log("Escrownya ", escrow.toString());
  }
  useEffect(() => {
    getAuction();
    getParsedNfts();
    payload.wallet_address = walletAddres;
    getEscrow(new PublicKey(walletAddres));
  }, [payload]);

  return (
    <>
      <DisplayContent>
        <div className="row">
          <h5 className="d-none d-sm-block">
            Auction offer From {auction.nft_name}
          </h5>
          <div className="col-md-4">
            <div className="position-relative">
              <div className="bg-linear position-absolute"></div>
              <img
                src={auction.image_url}
                className="rounded"
                height="355px"
                width="100%"
                alt="NFT"
              />
            </div>
            <div className="d-md-none d-lg-none d-xl-none title-img">
              <span className="bottom-title">{auction.nft_name}</span>
            </div>
          </div>
          <div className="col-md-8">
            <div className="d-none d-sm-block">
              <div className="card bg-ungu">
                <div className="card-body">
                  <h6 className="text-center">Item Offer</h6>
                </div>
              </div>
            </div>
            <div className="card bg-ungu mt-2">
              <div className="card-body">
                <div className="row">
                  {loading == false ? (
                    dataMeta.result?.assets?.length > 0 ? (
                      dataMeta.result.assets.map((asset) => {
                        return (
                          <div className="col-sm-3" key={asset.tokenAddress}>
                            <input
                              type="checkbox"
                              value={JSON.stringify(asset)}
                              name={asset.tokenAddress}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            <img
                              src={asset.imageUrl}
                              alt={asset.tokenAddress}
                              className="img-fluid rounded"
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div className="col mx-auto">
                        <div className="text-center">NFT not Available</div>
                      </div>
                    )
                  ) : (
                    <>
                      <div className="col-lg-3 col-6">
                        <Loader></Loader>
                      </div>
                      <div className="col-lg-3 col-6">
                        <Loader></Loader>
                      </div>
                      <div className="col-lg-3 col-6">
                        <Loader></Loader>
                      </div>
                      <div className="col-lg-3 col-6">
                        <Loader></Loader>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalCardNFT"
              >
                <div className="card bg-ungu mt-3" style={{ height: "220px" }}>
                  <div className="card-body">
                    <div className="container d-flex justify-content-center img-center">
                      <img src={iconImg} width="45" alt="icon" />
                    </div>
                    <h6 className="text-center" style={{ fontSize: "16px" }}>
                      place the items to be barter
                    </h6>
                  </div>
                </div>
              </a>
              <ModalCardNFT></ModalCardNFT> */}
            </div>

            {/* <div className="d-md-none d-lg-none d-xl-none">
              <div className="card bg-ungu">
                <div className="card-body">
                  <div className="row row-cols-1 row-cols-md-4 g-4">
                    <div className="col-3 col-6">
                      <img
                        src={imgDemo}
                        className="img-fluid rounded"
                        alt="..."
                      />
                      <h5 className="card-title">Blatant</h5>
                      <p className="card-text">Blatant</p>
                    </div>
                    <div className="col-3 col-6">
                      <img
                        src={imgDemo}
                        className="img-fluid rounded"
                        alt="..."
                      />
                      <h5 className="card-title">Blatant</h5>
                      <p className="card-text">Blatant</p>
                    </div>
                    <div className="col-3 col-6">
                      <img
                        src={imgDemo}
                        className="img-fluid rounded"
                        alt="..."
                      />
                      <h5 className="card-title">Blatant</h5>
                      <p className="card-text">Blatant</p>
                    </div>
                    <div className="col-3 col-6">
                      <img
                        src={imgDemo}
                        className="img-fluid rounded"
                        alt="..."
                      />
                      <h5 className="card-title">Blatant</h5>
                      <p className="card-text">Blatant</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row mt-2">
              <div className="col-lg-12 mt-2">
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-warning"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    onClick={() => {
                      modalConfirm();
                    }}
                  >
                    Comfirm Offer
                  </button>
                </div>
              </div>
              {/* <div className="col-lg-6 mt-2">
                <div className="d-grid gap-2">
                  <button className="btn btn-outline-secondary" type="button">
                    Decline
                  </button>
                </div>
              </div> */}
            </div>
            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content bg-ungu">
                  <div className="modal-header">
                    <div className="col d-flex justify-content-center">
                      <h6
                        className="modal-title text-center"
                        id="staticBackdropLabel"
                      >
                        Confirm Auction Offer
                      </h6>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="card bg-ungu" style={{ border: "1px solid #fff" }}>
                          <div
                            className="card-header"
                            style={{ borderBottom: "1px solid #fff" }}
                          >
                            <h6 className="card-title text-center">
                              Item will send
                            </h6>
                          </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table">
                              <tbody>
                                {confirm ? (
                                  payload.details.map((item) => {
                                    return (
                                      <tr key={item.mint_address} style={{ color: "white" }}>
                                        <td>{item.nft_name}</td>
                                        <td className="color-E42575">
                                          {item.mint_address
                                            .toString()
                                            .substring(0, 8) +
                                            "..." +
                                            item.mint_address
                                              .toString()
                                              .substring(
                                                item.mint_address.toString()
                                                  .length - 5
                                              )}
                                        </td>
                                        <td>
                                          {item.sended === false ? (
                                            <button
                                              className="btn btn-warning"
                                              onClick={(e) => {
                                                getEscrowTokenAccountAddress(
                                                  new PublicKey(user.wallet),
                                                  new PublicKey(item.token_account),
                                                  new PublicKey(item.mint_address),
                                                  e
                                                );
                                              }}
                                            >
                                              Send
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  })
                                ) : (
                                  <>
                                    <p>NFT not available</p>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        </div>
                      </div>
                      {/* <div className="col text-white">Item Send</div>
                      <div className="col"></div>
                      <div className="col"></div> */}
                    </div>
                    {/* {confirm ? (
                      payload.details.map((item) => {
                        return (
                          <div className="row" key={item.mint_address}>
                            <div className="col text-white">
                              {item.nft_name}
                            </div>
                            <div className="col color-E42575">
                              <p> {item.mint_address}</p>
                            </div>
                            <div className="col">
                              {item.sended === false ? (
                                <button
                                  className="btn btn-warning"
                                  onClick={(e) => {
                                    getEscrowTokenAccountAddress(
                                      new PublicKey(user.wallet),
                                      new PublicKey(item.token_account),
                                      new PublicKey(item.mint_address),
                                      e
                                    );
                                  }}
                                >
                                  Send
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <p>NFT not available</p>
                      </>
                    )} */}

                  </div>
                  <div className="modal-footer">
                    <div className="row mx-auto">
                      <div className="col-6">
                          <button
                            type="button"
                            className="btn btn-secondary w-100"
                            data-bs-dismiss="modal"
                          >
                            No
                          </button>
                        <div className="">
                        </div>
                      </div>
                      <div className="col-6">
                          <button
                            type="button"
                            className="btn btn-warning w-100"
                            onClick={() => {
                              submit();
                            }}
                          >
                            Yes
                          </button>
                        <div className="">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DisplayContent>
    </>
  );
}
