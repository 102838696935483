import { PublicKey, Transaction } from "@solana/web3.js";
import { FC, useEffect, useState } from "react";
// import DisplayNft from "./DisplayNft";
// import NFT from "./NFT";
// import Transactions from "./Transactions";
// import TransferNFT from "./TransferNFT";
import axios from "../axios/client";

import { useRecoilState } from "recoil";
import { currentUser } from "../store/recoil";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// var body: {
//   bio: string;
//   discord: string;
//   instagram: string;
//   twitter: string;
//   wallet: string;
// };
type PhantomEvent = "disconnect" | "connect" | "accountChanged";

interface ConnectOpts {
  onlyIfTrusted: boolean;
}
var walletConnect = "";
export interface PhantomProvider {
  connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>;
  disconnect: () => Promise<void>;
  on: (event: PhantomEvent, callback: (args: any) => void) => void;
  isPhantom: boolean;
  publicKey: PublicKey;
  signTransaction: (t: Transaction) => {};
}

type WindowWithSolana = Window & {
  solana?: PhantomProvider;
};

const ConnectWallet: FC = () => {
  const [hitung, setHtiung] = useState(0);
  const [walletAvail, setWalletAvail] = useState(false);
  const [provider, setProvider] = useState<PhantomProvider | null>(null);
  const [connected, setConnected] = useState(false);
  const [authUser, setAuthUser] = useRecoilState(currentUser);
  // const [walletConnect, setData] = useState("");
  let navigate = useNavigate();
  useEffect(() => {
    if ("solana" in window) {
      const solWindow = window as WindowWithSolana;
      if (solWindow?.solana?.isPhantom) {
        setProvider(solWindow.solana);
        setWalletAvail(true);
        // Attemp an eager connection
        solWindow.solana.connect({ onlyIfTrusted: true });
      }
    }
  }, []);

  async function register() {
    if (connected) {
      try {
        const { data } = await axios.post("/api/member/register", {
          name: "name",
          bio: "bio",
          discord: "discord",
          instagram: "instagram",
          twitter: "twitter",
          wallet: walletConnect,
        });

        // console.log(data);

        setAuthUser(data.data);
        const accessToken = data.token;
        localStorage.setItem("user", JSON.stringify(data.data));
        localStorage.setItem("token", accessToken);
      } catch (error) {
        console.log(error);
      }
      // }else{
      //   console.log('Wallet Registered');
    }
  }
  useEffect(() => {
    provider?.on("connect", (publicKey: PublicKey) => {
      walletConnect = `${publicKey}`;
      localStorage.setItem("walletAddres", walletConnect);
      // if (hitung === 0) {
      console.log("walletConnect ", walletConnect);
      register();
      // }
      setConnected(true);
    });
    provider?.on("disconnect", () => {
      // console.log("disconnect event");
      setConnected(false);
      localStorage.removeItem("token");
      navigate("/");
      // toast.error(`disconnect wallet`, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
    });
    // register();
  });

  return (
    <div className="text-center">
      {walletAvail ? (
        <>
          {/* <hr /> */}
          {/* {connected && provider ? <TransferSol provider={provider} /> : null} */}
          {/* {connected && provider ? <TransferNFT provider={provider} /> : null} */}
          {/* {connected && provider ? <EscrowActivity provider={provider} /> : null} */}
          {/* {connected && provider ? <Transactions provider={provider} /> : null} */}
          {/* add props dispalynft component */}
          {/* <DisplayNft address={walletConnect} />; */}
        </>
      ) : (
        <>
          <p>
            Opps!!! Phantom is not available. Go get it{" "}
            <a href="https://phantom.app/">https://phantom.app/</a>.
          </p>
        </>
      )}
    </div>
  );
};

export default ConnectWallet;
