import React, { useEffect } from "react";
import DisplayContent from "../component/DisplayContent";
import Carousel from "../component/home/Carousel";
import TableCollector from "../component/TableCollector";
import MultiCarousel from "../component/home/MultiCarousel";
import CarouselService from "../component/home/CarouselService";
import imgDemo from "../assets/img/image_5.png";
import { Link } from "react-router-dom";
import Bgl from "../assets/img/bg_l.png";
import Bgr from "../assets/img/bg_r.png";
import "../assets/css/home.css";
import iconExplore from "../assets/icon/explore_arrow.svg";
export default function Home() {
  return (
    <>
      <DisplayContent>
        <div className="row mt-3">
          <div className="col-lg-6 col-md-6 d-lg-flex flex-lg-column align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1">
            <h1 className="home-title text-white">
              Join the Caveler <br />
              With over 200 Verified <br />
              Collections included <br />
            </h1>
            <h3 className="text-white">(and more added constantly)</h3>
            <span className="home-desc text-white">
              Explore the array of conventional barter with digital technology
              with Barterplace. Also make Auctions for your NFTs.
            </span>
            <div className="mt-4">
              <div className="d-none d-lg-block">
                <Link to="/explore">
                  <h3 style={{ color: "#F99A00" }}>
                    Explore Blatant{" "}
                    <img src={iconExplore} width="25" alt="icon" />
                  </h3>
                </Link>
              </div>
              <div className="d-block d-sm-none">
                <Link to="/explore">
                  <button className="m-button">
                    Explore Blatant &nbsp;
                    <img src="/assets/img/icon/b_arrow.svg" alt="" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 d-lg-flex flex-lg-column align-items-stretch order-1 order-md-2">
            <div className="d-flex justify-content-center">
              <img
                className="position-absolute background-carousel"
                src={Bgr}
                alt=""
              />
              <img
                className="position-absolute background-carousel"
                style={{ marginLeft: "8px" }}
                src={Bgl}
                alt=""
              />
            </div>
            <div>
              <Carousel></Carousel>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="section-title mt-5 d-none d-sm-block">
          <h2>Top Collectors 24 Hours</h2>
        </div>
        <div className="d-md-none d-lg-none d-xl-none">
          <div className="row">
            <div className="col">
              <h2 className="title-colle">Top Collectors</h2>
            </div>
            <div className="col">
              <Link
                className="btn-link"
                to="/explore"
                style={{ textDecoration: "none", color: "#F99A00" }}
              >
                Go to top collector
              </Link>
            </div>
          </div>
        </div>
        <TableCollector />
        <div className="d-none d-sm-block">
          <div className="d-flex justify-content-center mt-5">
            <Link
              className="button-ranking btn btn-warning text-dark"
              to="/explore"
            >
              Go Collector Ranking
            </Link>
          </div>
        </div>

        <div className="treding-top">
          <div className="row d-flex justify-content-center mb-5">
            <h2 className="text-center text-white">Trending NFT</h2>
          </div>
          <MultiCarousel></MultiCarousel>
          <div className="d-none d-sm-block">
            <div className="d-flex justify-content-center mt-5">
              <Link
                className="button-ranking btn btn-warning text-dark"
                to="/explore"
              >
                Go Collector Ranking
              </Link>
            </div>
          </div>
          <div className="d-md-none d-lg-none d-xl-none">
            <div className="d-flex justify-content-center mt-2">
              {/* <button className="m-button">Go Collector Ranking</button> */}
              <Link
                className="button-ranking btn btn-outline-warning"
                to="/explore"
              >
                Go Collector Ranking
              </Link>
            </div>
          </div>
        </div>

        {/* <div className="service-top">
          <div className="section-title">
            <h2 className="text-white">Best Service or unique from Blatant</h2>
          </div>
          <div className="d-none d-sm-block">
            <div className="row text-white">
              <div className="col-lg-4">
                <div className="d-flex justify-content-center">
                  <img src={imgDemo} className="img-fluid" width="150" alt="" />
                </div>
                <br />
                <div className="text-center">
                  <b>Lorem ipsum dolor sit amet, consectetur</b>
                </div>
                <p className="text-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Malesuada interdum donec neque, consectetur vitae fringilla.
                </p>
              </div>
              <div className="col-lg-4">
                <div className="d-flex justify-content-center">
                  <img src={imgDemo} className="img-fluid" width="150" alt="" />
                </div>
                <br />
                <div className="text-center">
                  <b>Lorem ipsum dolor sit amet, consectetur</b>
                </div>
                <p className="text-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Malesuada interdum donec neque, consectetur vitae fringilla.
                </p>
              </div>
              <div className="col-lg-4">
                <div className="d-flex justify-content-center">
                  <img src={imgDemo} className="img-fluid" width="150" alt="" />
                </div>
                <br />
                <div className="text-center">
                  <b>Lorem ipsum dolor sit amet, consectetur</b>
                </div>
                <p className="text-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Malesuada interdum donec neque, consectetur vitae fringilla.
                </p>
              </div>
            </div>
          </div>
          <div className="d-md-none d-lg-none d-xl-none">
            <CarouselService></CarouselService>
          </div>
        </div> */}
      </DisplayContent>
    </>
  );
}
