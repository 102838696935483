import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavLink from "./NavLink";
import Logo from "../assets/logo/logo.png";
import iconSearch from "../assets/icon/search.svg";
import profile from "../assets/profile/profile.png";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentUser, searchKeyword } from "../store/recoil";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import client from "../axios/client";
export default function Navbar({ children }: any) {
  // const { count } = useRecoilValue(barterNotif);
  const [notif, setNotif] = useState<number>(0);
  const [authUser, setAuthUser] = useRecoilState(currentUser);
  const token = localStorage.getItem("token");
  // let navigate = useNavigate();

  // if (token) {
  //   console.log('ok')
  // } else {
  //   navigate('/')
  // }

  async function getNotif() {
    try {
      const { data } = await client.get("/api/member/notif-barter");
      setNotif(data);
      console.log(data);
    } catch (error: any) {
      console.log(error);
    }
  }
  const notify = () =>
    toast.warn(`you haven't connect the wallet`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const [keyword, setKeyword] = useRecoilState(searchKeyword);

  const navigate = useNavigate();

  function searchData(e: any) {
    e.preventDefault();
    navigate(`/search?keyword=${keyword}`, { replace: true });
  }

  useEffect(() => {
    // console.log("navbar",token)
    getNotif();
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-ungu d-none d-sm-block fixed-top">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={Logo} width="100" alt="logo" />
          </Link>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <form
              className="pencarian me-auto"
              role="search"
              onSubmit={(e) => {
                searchData(e);
              }}
            >
              <img className="img-search" src={iconSearch} alt="search" />
              <input
                type="text"
                className="placeh"
                placeholder="Search"
                aria-label="Search"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </form>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-auto">
              <li className="nav-item">
                <NavLink href="/explore">Explore</NavLink>
              </li>
              <li className="nav-item">
                <NavLink href="/auction">Auction</NavLink>
              </li>
              <li className="nav-item">
                <NavLink href="/barter">
                  Barter{" "}
                  {notif > 0 ? (
                    <span className="badge bg-danger">{notif}</span>
                  ) : (
                    <></>
                  )}
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink href="/vault">Vault</NavLink>
              </li> */}
              <li className="nav-item">
                <span>{children}</span>
              </li>
              {token != null ? (
                <li className="nav-item">
                  <NavLink
                    className="nav-link d-flex align-items-center"
                    href="/profile"
                  >
                    <img src={profile} alt="" />
                  </NavLink>
                  {/* <Link to="/profile">
                  <button style={{ borderRadius: "25px" }} className="btn btn-sm btn-outline-light">
                    <img src={profile} alt="icon" /> {authUser.name}
                  </button>
                </Link> */}
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <ToastContainer />
    </>
  );
}
