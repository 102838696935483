import React, { useEffect, useRef, useState } from "react";
import DisplayContent from "../component/DisplayContent";
import imgDemo from "../assets/img/image_5.png";
import iconLove from "../assets/icon/love.svg";
import iconHummer from "../assets/icon/hammer.svg";
import iconArrow from "../assets/icon/arrow_2.svg";
import Chart from "react-apexcharts";
import { useRecoilState } from "recoil";
import { currentUser, itemAuction } from "../store/recoil";
import client from "../axios/client";
import { useNavigate, useParams } from "react-router-dom";
// import { metaData } from "../model/metadata";
import axios from "axios";
import { Connection, PublicKey } from "@solana/web3.js";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import { meta } from "../model/meta";
import { toast } from "react-toastify";

import ReactDOM from "react-dom";
import { FetchNFTClient } from "@audius/fetch-nft";
import { activity } from "../model/activity";
import Loader from "../component/Loader";
import ReactGA from "react-ga";
export default function ItemsDetail() {
  var option = {
    options: {
      chart: {
        id: "area",
        background: "#24182F",
        colors: ["#E42575"],
        theme: {
          mode: "dark",
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#E42575"],
    },
    series: [
      {
        name: "series1",
        data: [31, 40, 28, 51, 42, 90, 100, 80, 65, 10, 55, 40],
      },
    ],
    xaxis: {
      type: "datetime",
      categories: [
        "2018-09-19T00:00:00.000Z",
        "2018-09-19T01:30:00.000Z",
        "2018-09-19T02:30:00.000Z",
        "2018-09-19T03:30:00.000Z",
        "2018-09-19T04:30:00.000Z",
        "2018-09-19T05:30:00.000Z",
        "2018-09-19T06:30:00.000Z",
      ],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    stroke: {
      curve: "smooth",
    },
  };

  const [dataMeta, setDataMeta] = useState<meta>({} as meta);
  const [auction, setAuction]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState<any>(null);
  // const [desc, setDesc]: string = useState("");
  const [activities, setActivities] = useState<activity>({} as activity);
  const [loadingActivity, setLoadingActivity] = useState(true);

  const [curUser, setUser] = useRecoilState(currentUser);
  const { auction_id, wallet, mint } = useParams();
  const mintPubkey = new PublicKey(`${mint}`);
  const navigate = useNavigate();
  const [count, setCount] = useState(60);
  const [minute, setMinute] = useState(60);
  const [second, setSecond] = useState(60);
  const [hours, setHours] = useState(60);
  // dateEnd = auction.end;
  const targetDate = new Date("2022-12-31 23:59:59");
  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = new Date();
      const diff = targetDate.getTime() - currentDate.getTime();
      var days = Math.floor(diff / (1000 * 60 * 60 * 24));
      var hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setSecond(seconds);
      setMinute(minutes);
      setHours(hours);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  function countDownTime(date: string) {
    const countDownDate = new Date(date).getTime();
    // Update the count down every 1 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();
      console.log(now);

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      ReactDOM.render(
        <h3 className="text-center">
          {days + "d " + hours + "h " + minutes + "m " + seconds + "s "}
        </h3>,
        document.getElementById("countdown")
      );

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        ReactDOM.render(
          <h3 className="text-center text-danger">EXPIRED</h3>,
          document.getElementById("countdown")
        );
      }
    }, 1000);

    return () => clearInterval(x);
  }
  function msgBox(type: string = "warning", message: string) {
    if (type == "warning") {
      toast.warning(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "success") {
      toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "error") {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }
  async function getAuction() {
    try {
      const res = await client.get(
        `/api/member/auction/find?mint_address=${mint}&aution_id=${auction_id}`
      );

      setAuction(res.data.auction);
      // countDownTime(res.data.auction.end);
      console.log(res.data.auction);
    } catch (error: any) {
      console.log(error);
    }
  }

  async function getData() {
    try {
      const { data } = await client.get("/api/metadata/" + mint);
      console.log(data);
      setDataMeta(data);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  }

  function makeOffer(id: string) {
    var date = new Date();
    var dateStr =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2) +
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2) +
      ":" +
      ("00" + date.getSeconds()).slice(-2);
    console.log(dateStr);
    // return;
    var end = auction.end;
    if (end > dateStr) {
      navigate("/details/auction-offer/" + id);
    } else {
      msgBox("warning", "Auctions Auctions already ended");
    }
  }
  async function getActivities() {
    try {
      const { data } = await client.get(`/api/activities/${mint}`);
      console.log("activities", data);
      setActivities(data);
      setLoadingActivity(false);
    } catch (error: any) {}
  }

  useEffect(() => {
    console.log(targetDate);
    getAuction();
    getData();
    getActivities();
    const pathName =
      window.location.hostname +
      window.location.pathname +
      window.location.search;
    ReactGA.pageview(pathName);
  }, []);

  return (
    <>
      <DisplayContent>
        <div className="row">
          <div className="col-lg-6">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <img src={dataMeta.data.image} alt="Ada" width="100%" />
            )}
            {auction ? (
              <div className="card bg-ungu mt-3">
                <div className="card-body">
                  <div className="row justify-content-center">
                    <h6 className="text-center">Auction End</h6>
                    {/* <p> */}
                    {/* <span id="countdown"></span> */}
                    {/* </p> */}
                    <h3 className="text-center">{auction.end}</h3>
                    {/* <h3>{hours} : {minute} : {second}</h3> */}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="col-lg-6">
            <div className="card bg-ungu">
              <div className="card-header">
                <h4 className="card-title">{dataMeta.data?.name}</h4>
              </div>
              {/* <div className="card-body">
                <h6>Description</h6>
                <p>{"lorem"}</p>
              </div> */}
            </div>
            {/* <div className="card bg-ungu">
              <div className="card-body">
                <h4>{dataMeta.name}</h4>
                <h4>Blatant</h4>
                <table>
                  <tr>
                    <td>Items</td>
                    <td>&nbsp; &nbsp; &nbsp;</td>
                    <td>&nbsp; &nbsp; &nbsp;</td>
                    <td>19203</td>
                  </tr>
                  <tr>
                    <td>Owner</td>
                    <td></td>
                    <td></td>
                    <td>23703</td>
                  </tr>
                  <tr>
                    <td>Total Volume</td>
                    <td></td>
                    <td></td>
                    <td>73094</td>
                  </tr>
                  <tr>
                    <td>Floor Price</td>
                    <td></td>
                    <td></td>
                    <td>69492</td>
                  </tr>
                </table>
              </div>
            </div> */}
            <div className="card bg-ungu mt-3">
              <div className="card-body">
                <h5 className="card-title">Attributtes</h5>
                <div className="row">
                  {dataMeta.data?.attributes?.map(
                    (item: any, index: number) => {
                      return (
                        <div key={index} className="col-6 my-2">
                          <div
                            className="card bg-ungu"
                            style={{ border: "solid 1px grey" }}
                          >
                            <div
                              className="card-header"
                              style={{ fontWeight: 300 }}
                            >
                              {item.trait_type}
                            </div>
                            <div className="card-body">
                              <h6>{item.value}</h6>
                            </div>
                          </div>
                        </div>
                      );
                      // return (
                      //   <button
                      //     key={index}
                      //     type="button"
                      //     style={{ borderRadius: "20px" }}
                      //     className="btn btn-outline-secondary mx-2 mt-2"
                      //   ></button>
                      // );
                    }
                  )}
                </div>
              </div>
            </div>
            <div className="card bg-ungu mt-4">
              <div className="card-body">
                <button type="button" className="btn btn-outline-warning mx-2">
                  <img src={iconLove} alt="icon" />
                </button>
                <button
                  type="button"
                  className="btn btn-warning item-button"
                  onClick={() => {
                    if (auction.member_id == curUser.id) {
                      msgBox("error", "You can't make offer for your own item");
                      return;
                    }
                    makeOffer(auction.id);
                  }}
                >
                  Make Your Offer
                </button>
              </div>
            </div>

            {/* <div className="card bg-ungu mt-4">
              <div className="card-body">
                <h5>Item Activity</h5>
                <div id="chart" className="text-dark">
                  <Chart
                    options={option.options}
                    series={option.series}
                    type="area"
                    height={350}
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-12">
          <div className="card bg-ungu mt-4">
            <div className="card-header">
              <h5>Last Activity</h5>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-borderless text-white">
                  <thead>
                    <tr>
                      <th scope="col">Activity</th>
                      <th scope="col">Transcation hash</th>
                      <th scope="col">From</th>
                      <th scope="col">To</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loadingActivity ? (
                      <Loader />
                    ) : activities.data?.length > 0 ? (
                      activities.data?.map((item: any, index: number) => {
                        return (
                          <tr key={index}>
                            <th scope="row">
                              <img
                                src={
                                  item.activity == "Auction"
                                    ? iconHummer
                                    : iconArrow
                                }
                                alt=""
                              />{" "}
                              &nbsp;
                              {item.activity}
                            </th>
                            <td>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  navigator.clipboard.writeText(item.txn);
                                  toast("Copied to clibboard", {
                                    position: "top-right",
                                    autoClose: 500,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                  });
                                }}
                              >
                                {item.txn.substring(0, 10) +
                                  "..." +
                                  item.txn.substring(
                                    item.txn.length - 5,
                                    item.txn.length
                                  )}
                              </span>{" "}
                            </td>
                            <td>
                              {item.from.name == "name"
                                ? "@noname"
                                : "@" + item.from.name}
                            </td>
                            <td>
                              {item.to.name == "name"
                                ? "@noname"
                                : "@" + item.to.name}
                            </td>
                            <td>{item.created_at}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          Activity not available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </DisplayContent>
    </>
  );
}
