import React, { useEffect, useState } from "react";
import DisplayContent from "../component/DisplayContent";
import imgDemo from "../assets/img/image_5.png";
import { Link, useParams } from "react-router-dom";
import "../assets/css/auction.css";
import iconSearch from "../assets/icon/search.svg";
import client from "../axios/client";
import { auctionDetail } from "../model/auctionDetail";
import { toast } from "react-toastify";
export default function DetailsAuction() {

  const { auction_id } = useParams();
  const [data, setData] = useState<auctionDetail>({} as auctionDetail);

  async function getAuction() {
    try {
      const res = await client.get(
        `/api/member/auction/find?aution_id=${auction_id}`
      );

      console.log(res.data);

      setData(res.data);
    } catch (error: any) {
      console.log(error);
    }
  }

  function searchOffer(e: any) {
    e.preventDefault();
    setData({
      ...data,
      auction: {
        ...data.auction,
        offers: data.auction.offers.filter((item) => {
          return item.wallet_address
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }),
      },
    });
  }
  function msgBox(type: string = "warning", message: string) {
    if (type == "warning") {
      toast.warning(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "success") {
      toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "error") {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }
  async function cancelAuction() {
    try {
      const { data } = await client.post(`/api/member/auction/cancel-draft`, {
        aution_id: auction_id,
      });
      console.log("data", data);
      msgBox("success", data.message);
      getAuction();
    } catch (error: any) {
      console.log(error);
    }
  }


  useEffect(() => {
    getAuction();

  }, []);

  return (
    <>
      <DisplayContent>
        <div className="row">
          <div className="col-lg-4">
            <div className="position-relative">
              <div className="bg-linear position-absolute"></div>
              <img
                src={data.auction?.image_url}
                height="355px"
                width="100%"
                alt={data.auction?.nft_name}
              />
            </div>
            <div className="title-img">
              <span className="bottom-title">{data.auction?.nft_name}</span>
              {data.auction?.status == 'Draft' ? (
                <div>
                  <button
                    className="btn btn-warning w-100"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalStop"
                  >Cancel</button>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="card d-md-none d-lg-none d-xl-none bg-ungu">
              <div className="card-body d-flex justify-content-center">
                <span className="color-F99A00">
                  <b>Auction End {data.auction?.end}</b>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <form
              className="length-search me-auto mb-4 d-none d-sm-block w-100"
              role="search"
            >
              <img className="icon-search" src={iconSearch} alt="search" />
              <input
                type="text"
                className="placeh"
                placeholder="Search wallet"
                aria-label="Search"
                onChange={(e) => searchOffer(e)}
              />
            </form>
            <div className="table-responsvie">
              <table className="table">
                <thead className="text-white">
                  <tr>
                    <th>Bid</th>
                    <th>Offer</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="color-E42575">
                  {data.auction?.offers.length > 0 ? (
                    data.auction.offers.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {item.wallet_address.substring(0, 10) +
                              "..." +
                              item.wallet_address.substring(
                                item.wallet_address.length - 5,
                                item.wallet_address.length
                              )}
                          </td>
                          <td>{item.details.length} Items</td>
                          <td>
                            <Link
                              className="btn btn-outline-warning btn-sm"
                              to={
                                "/details/auction-made/" + item.auction_bid_id
                              }
                            >
                              Details
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center">
                      <td colSpan={3}>Data not Available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className="modal fade"
              id="exampleModalStop"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className="modal-content bg-ungu">
                  <div className="modal-header">
                    <h6
                      className="modal-title text-center mx-auto"
                      id="exampleModalLabel"
                    >
                      {" "}
                      Stop Auction
                    </h6>
                  </div>
                  <div className="modal-body">
                    <span>Are you sure want to cancel auction?</span>
                    <div className="d-flex justify-content-center mt-3">
                      <button
                        type="button"
                        className="btn btn-warning w-100"
                        data-bs-dismiss="modal"
                      >
                        No
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-outline-warning w-100"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          cancelAuction();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="card bg-ungu d-none d-sm-block">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <b>Bid</b>
                  </div>
                  <div className="col d-flex justify-content-center">
                    <b>Offer</b>
                  </div>
                  <div className="col d-flex justify-content-end">
                    <b>Time Expired</b>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="d-md-none d-lg-none d-xl-none mt-3">
              <h6> Offer for this item </h6>
            </div> */}
            {/* {data.auction?.offers.length > 0 ? (
              data.auction.offers.map((item, index) => {
                return (
                  <div className="card mt-3 bg-ungu" key={index}>
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <p className="color-E42575">
                            {item.wallet_address.substring(0, 10) +
                              "..." +
                              item.wallet_address.substring(
                                item.wallet_address.length - 5,
                                item.wallet_address.length
                              )}
                          </p>
                        </div>
                        <div className="col">
                          <p className="color-E42575">
                            {item.details.length} Items
                          </p>
                        </div>
                        <div className="col d-none d-sm-block">
                          <div className="d-grid gap-2">
                            <Link
                              className="btn btn-outline-warning"
                              to="/details/auction-made"
                            >
                              Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )} */}
          </div>
        </div>
      </DisplayContent>
    </>
  );
}
