import React from "react";
import NavLink from "./NavLink";
import Logo from "../assets/logo/logo.png";
import iconTele from "../assets/icon/tele.svg";
import iconTwit from "../assets/icon/twit.svg";
import iconIg from "../assets/icon/ig.svg";
import iconDc from "../assets/icon/dc.svg";
import iconSearch from "../assets/icon/search.svg";
import profile from "../assets/profile/profile.png";
import { Link, useNavigate } from "react-router-dom";
// import ModalSearch from "./modal/ModalSearch";
import { useRecoilState } from "recoil";
import { currentUser, searchKeyword } from "../store/recoil";
export default function NavbarMobile({ children }: any) {
  const [keyword, setKeyword] = useRecoilState(searchKeyword);

  const navigate = useNavigate();

  function searchData(e: any) {
    e.preventDefault();
    navigate(`/search?keyword=${keyword}`, { replace: true });
  }
  return (
    <>
      <nav className="navbar navbar-dark bg-ungu d-lg-none d-xl-none fixed-top">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={Logo} width="100" alt="logo" />
          </Link>
          <div>
            <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#exampleModalSearch"
            >
              <img style={{ padding: "0 10px 0 0" }} src={iconSearch} alt="" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasDarkNavbar"
              aria-controls="offcanvasDarkNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <Link to="/profile">
              <img
                style={{ padding: "0 0 0 10px" }}
                src={profile}
                width="50"
                alt=""
              />
            </Link>
          </div>
          <div
            className="offcanvas offcanvas-end bg-ungu"
            id="offcanvasDarkNavbar"
            aria-labelledby="offcanvasDarkNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">
                Menu
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <NavLink href="/explore">Explore</NavLink>
                </li>
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <NavLink href="/auction">Auction</NavLink>
                </li>
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <NavLink href="/barter">Barter</NavLink>
                </li>
                <li className="nav-item">
                  <span className="nav-link">{children}</span>
                </li>
              </ul>
            </div>
            <hr />
            <div className="d-flex justify-content-center mb-3">
              <a href="">
                <img src={iconTele} width="20" alt="" />
              </a>
              &nbsp; &nbsp; &nbsp;
              <a href="">
                <img src={iconTwit} width="20" alt="" />
              </a>
              &nbsp; &nbsp; &nbsp;
              <a href="">
                <img src={iconIg} width="20" alt="" />
              </a>
              &nbsp; &nbsp; &nbsp;
              <a href="">
                <img src={iconDc} width="20" alt="" />
              </a>
            </div>
          </div>
        </div>
      </nav>
      {/* <ModalSearch></ModalSearch> */}
      <div
        className="modal fade"
        id="exampleModalSearch"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content bg-ungu">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Search
              </h1>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <div className="row">
                  <form
                    onSubmit={(e) => {
                      searchData(e);
                    }}
                    className="auction-cari me-auto"
                    role="search"
                  >
                    <img className="serch" src={iconSearch} alt="search" />
                    <input
                      type="text"
                      className="placeh"
                      placeholder="Search"
                      aria-label="Search"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
