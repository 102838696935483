import React from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { searchAuction } from "../../store/recoil";
import "../../assets/css/explore.css";
// import imgDemo from "../assets/img/image_5.png";

export default function Auction() {
  const [data, setData] = useRecoilState(searchAuction);
  return (
    <div className="row row-cols-1 row-cols-md-4 g-4 mt-2">
      {data.length > 0 ? (
        data.map((item: any) => {
          return (
            <div className="col-3 col-6" key={item.aution_id}>
              <Link
                to={`/items/detail/${item.aution_id}/${item.escrow_address}/${item.mint_address}`}
              >
                <img
                  src={item.image_url}
                  width="50"
                  className="card-img-top"
                  alt={item.aution_id}
                />
                <h5 className="card-title">{item.nft_name}</h5>
                <p className="card-text color-E42575">
                  Blatant {item.nft_name}
                </p>
              </Link>
            </div>
          );
        })
      ) : (
        <div className="col-12">
          <h5 className="text-center">Data not found</h5>
        </div>
      )}
    </div>
  );
}
