import React, { useEffect, useRef, useState } from "react";
import DisplayContent from "../component/DisplayContent";
import ModalCardNFT from "../component/modal/ModalCardNFT";
import iconImg from "../assets/icon/img.svg";
import iconSearch from "../assets/icon/search.svg";
import "../assets/css/barter.css";
import ContentLoader from "react-content-loader";
import { currentUser } from "../store/recoil";
import { useRecoilState } from "recoil";
import { metaData } from "../model/metadata";
import {
  Commitment,
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
} from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import client from "../axios/client";
import { useWallet } from "@solana/wallet-adapter-react";

import * as anchor from "@project-serum/anchor";

import IDL from "../program/blatant_program_idl.json";
import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";
import { PROGRAM_ID } from "../utils/program";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../component/Loader";
import { web3 } from "@project-serum/anchor";
import "../assets/css/barter.css";
import ReactGA from 'react-ga';
import baseUrl from "../config/base";

var body: {
  initializer_address: string;
  owner_address: string;
  initializer_escrow_address: string;
  owner_escrow_address: string;
  initializers: {
    name: string;
    imageUrl: string;
    mint_address: string;
    token_account_address: string;
    escrow_token_account_address: string;
    txn?: string;
    sended?: boolean;
  }[];
  owners: {
    name: string;
    imageUrl: string;
    mint_address: string;
    token_account_address: string;
  }[];
} = {
  initializer_address: "",
  owner_address: "",
  initializer_escrow_address: "",
  owner_escrow_address: "",
  initializers: [],
  owners: [],
};

export default function NewBarter() {
  const navigate = useNavigate();
  const [submit, setSubmit] = useState(false);
  const [user, setUser] = useRecoilState(currentUser);
  const [dataMeta, setDataMeta] = useState<metaData>({} as metaData);
  const [dataMeta2, setDataMeta2] = useState<metaData>({} as metaData);
  const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
    "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
  );

  const buttonSend: any = useRef(null);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [sendGas, setSendGas] = useState<boolean>(false);
  const [barterId, setBarterId] = useState<string>("");

  const [keyword, setKeyword] = useState("");


  // const to = "6wZ6QVLnvktRNPQXURBS8v4EnSBXTKwKo2nU95KGk8Vb";
  // async function TransactionTransfer(){
  //   const wallet = user.wallet;
  //   let network =
  //     localStorage.getItem("network") || "https://api.devnet.solana.com";
  //   const connection = new Connection(network, opts.preflightCommitment);
  //   var transaction = new web3.Transaction().add(
  //     web3.SystemProgram.transfer({
  //       fromPubkey: wallet.PublicKey.toString(),
  //       toPubkey: to.PublicKey.toString(),
  //       lamports: 1, // number of SOL to send
  //     }),
  //   );

  //   // Sign transaction, broadcast, and confirm
  //   var signature = await web3.sendAndConfirmTransaction(connection, transaction, [
  //     wallet,
  //   ]);
  //   console.log('SIGNATURE', signature);
  // }
  async function findAssociatedTokenAddress(
    walletAddress: PublicKey,
    tokenMintAddress: PublicKey
  ): Promise<PublicKey> {
    const solana = new Connection(baseUrl);
    const account = await solana.getTokenAccountsByOwner(walletAddress, {
      mint: tokenMintAddress,
    });
    console.log(account.value[0].pubkey.toString());
    return account.value[0].pubkey;
  }
  const handleOnChange = async (event: any) => {
    const userWallet = user.wallet;
    // const userWallet = "7rRVKoUpUQUeyHrwtNuzvo85oCYYGLmpA92Cu4m7P8uy";
    const { checked } = event.target;
    let value = JSON.parse(event.target.value);

    if (checked) {
      const tokenacc = await findAssociatedTokenAddress(
        new PublicKey(userWallet),
        new PublicKey(value.tokenAddress)
      );

      body.initializers.push({
        name: value.name,
        imageUrl: value.imageUrl,
        mint_address: value.tokenAddress,
        token_account_address: tokenacc.toString(),
        escrow_token_account_address: "",
        sended: false,
      });
    } else {
      setSubmit(false);
      body.initializers = body.initializers.filter((item) => {
        return item.mint_address !== value.tokenAddress;
      });
    }
  };

  async function handleOnChange2(event: any) {
    const { checked } = event.target;
    let value = JSON.parse(event.target.value);

    if (checked) {
      const tokenacc = await findAssociatedTokenAddress(
        new PublicKey(keyword),
        new PublicKey(value.tokenAddress)
      );
      console.log("Token akun address nya ", tokenacc.toString());

      body.owners.push({
        name: value.name,
        imageUrl: value.imageUrl,
        mint_address: value.tokenAddress,
        token_account_address: tokenacc.toString(),
      });
    } else {
      body.owners = body.owners.filter((item) => {
        return item.mint_address !== value.tokenAddress;
      });
    }
  }

  function msgBox(type: string = "warning", message: string) {
    if (type == "warning") {
      toast.warning(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "success") {
      toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "error") {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }else if (type == "info") {
      toast.info(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  async function search(e: any) {
    e.preventDefault();
    // const wallet = body.owner_address;
    const wallet = keyword;

    const { data } = await client.get(`/api/member/check-member?wallet=${wallet}`);
    if (data.status == false) {
      msgBox("error", data.message);
      return;
    }

    if (wallet === "") {
      msgBox("error", "Wallet address cannot be empty");
      return;
    }

    setLoading2(true);
    const bodyLoad = {
      method: "qn_fetchNFTs",
      params: [wallet, []],
    };
    const options = {
      method: "POST",
      body: JSON.stringify(bodyLoad),
    };

    const res = await fetch(baseUrl,options
    );

    setDataMeta2(await res.json());

    body.owner_address = wallet;

    getEscrow(new PublicKey(wallet), "owner_escrow_address");
    console.log(dataMeta2);
    setLoading2(false);
  }

  async function getParsedNfts() {
    const walletxx: any = localStorage.getItem("walletAddres");
    // console.log(walletxx);
    // const wallet = user.wallet;
    // const wallet = "7rRVKoUpUQUeyHrwtNuzvo85oCYYGLmpA92Cu4m7P8uy";
    // console.log("Walletnya", wallet);

    const bodyLoad = {
      method: "qn_fetchNFTs",
      params: [walletxx, []],
    };
    const options = {
      method: "POST",
      body: JSON.stringify(bodyLoad),
    };

    const res = await fetch(baseUrl,options
    );

    setDataMeta(await res.json());
    body.initializer_address = walletxx;
    getEscrow(new PublicKey(walletxx));

    console.log(dataMeta);
    setLoading(false);
  }

  let escrow: PublicKey;
  const wallet = useWallet();
  const { sendTransaction } = useWallet();
  const opts = {
    preflightCommitment: "processed" as Commitment,
  };

  const getConnection = () => {
    let network =
      localStorage.getItem("network") || baseUrl;
    const connection = new Connection(network, opts.preflightCommitment);
    return connection;
  };

  const program = new anchor.Program(
    IDL as any as anchor.Idl,
    PROGRAM_ID,
    new anchor.AnchorProvider(
      getConnection(),
      wallet as any as NodeWallet,
      opts
    )
  );
  async function getEscrow(
    address: PublicKey,
    type: string = "initializer_escrow_address"
  ) {
    [escrow] = await PublicKey.findProgramAddress(
      [anchor.utils.bytes.utf8.encode("blatant"), address.toBuffer()],
      program.programId
    );
    if (type === "owner_escrow_address") {
      body.owner_escrow_address = escrow.toString();
    } else {
      body.initializer_escrow_address = escrow.toString();
    }
    console.log("Escrownya ", escrow.toString());
  }
  async function getEscrowTokenAccountAddress(
    seller: PublicKey,
    initTokenAccountA: PublicKey,
    mintNft: PublicKey,
    e: any
  ) {
    // change text button
    e.target.innerHTML = "Sending...";
    e.target.disabled = true;

    [escrow] = await PublicKey.findProgramAddress(
      [anchor.utils.bytes.utf8.encode("blatant"), seller.toBuffer()],
      program.programId
    );
    const escrowedXTokens = Keypair.generate();
    console.log(`escrowedXTokens :: `, escrowedXTokens.publicKey.toString());
    const token_account_address = initTokenAccountA.toString();

    body.initializers.forEach((item) => {
      if (item.token_account_address === token_account_address) {
        item.escrow_token_account_address =
          escrowedXTokens.publicKey.toString();
      }
    });

    const tx = await program.methods
      .initialize(new anchor.BN(1))
      .accounts({
        seller: seller,
        xMint: mintNft,
        sellerXToken: initTokenAccountA.toString(),
        escrow: escrow,
        escrowedXTokens: escrowedXTokens.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        rent: SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
      })
      .signers([escrowedXTokens])
      .rpc({ skipPreflight: true });

    console.log("Tx Hash nya: ", tx);
    body.initializers.forEach((item) => {
      if (item.token_account_address === token_account_address) {
        item.txn = tx;
        item.sended = true;
      }
    });

    refresh();
  }

  function refresh() {
    setLoading(true);
    setLoading(false);
  }
  const [offer, setOffer] = useState(false);
  function display() {
    const validator = validation();
    if (!validator) {
      return false;
    }

    if (body.initializers.length === 0) {
      msgBox("warning", "Please select your NFT to barter");
      return false;
    } else if (body.owners.length === 0) {
      msgBox("warning", "Please select owner NFT to barter");
      return false;
    }

    if (barterId === "" || barterId === undefined || barterId === null) {
      client
        .post(`/api/member/barter`, body)
        .then((res) => {
          msgBox("info", res.data.message);
          setBarterId(res.data.data.id);
        })
        .catch((err) => {
          msgBox("error", err.response.data.message);
        });
    }
    setOffer(true);
  }

  function hideDisplay() {
    setOffer(false);
  }

  function validation() {
    if (body.initializers.length === 0) {
      msgBox("warning", "Please select your NFT to barter");
      return false;
    }
    if (body.owners.length === 0) {
      msgBox("warning", "Please select owner NFT to barter");
      return false;
    }
    if (body.initializer_address === "") {
      msgBox("warning", "Please connect wallet address");
      return false;
    }
    if (body.owner_address === "") {
      msgBox("warning", "Please input wallet address owner");
      return false;
    }
    return true;
  }
  function makeOffer() {
    const validator = validation();
    if (!validator) {
      return false;
    }

    body.initializers.map((item) => {
      if (item.sended === false) {
        msgBox("warning", "Please make sure all NFT is initialized or Sended");
        return false;
      }
    });

    client
      .put(
        `/api/member/barter-submit`,
        {
          barter_id: barterId,
        })
      .then((res) => {
        msgBox("success", res.data.message);
        navigate("/barter");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        msgBox("error", err.response.data.message);
        console.log(err.response.data.message);
      });
  }

  async function sendGasFee() {
    try {
      const fromWallet = new PublicKey(user.wallet);
      const toWallet = new PublicKey(
        "6wZ6QVLnvktRNPQXURBS8v4EnSBXTKwKo2nU95KGk8Vb"
      );
      var transaction = new anchor.web3.Transaction().add(
        anchor.web3.SystemProgram.transfer({
          fromPubkey: fromWallet,
          toPubkey: toWallet,
          lamports: 100000000, // number of SOL to send
        })
      );
      const signature = await sendTransaction(
        transaction,
        program.provider.connection
      );
      await program.provider.connection.confirmTransaction(
        signature,
        "processed"
      );
      console.log("sign gas fee", signature);

      client
        .put(
          `/api/member/barter-send-fee`,
          {
            barter_id: barterId,
            send_fee_txn: signature,
          })
        .then((res) => {
          msgBox("info", res.data.message);
        })
        .catch((err) => {
          msgBox("error", err.response.data.message);
        });

      setSendGas(true);
    } catch (error: any) {
      console.log(error);
    }
  }

  useEffect(() => {
    getParsedNfts();
    const pathName = window.location.hostname +  window.location.pathname + window.location.search;
    ReactGA.pageview(pathName);
  }, []);
  return (
    <>
      <DisplayContent>
        <div className="row d-flex justify-content-center text-white">
          <div className="d-none d-sm-block">
            <div className="d-flex justify-content-center">
              <form
                className="pencarian-barter"
                role="search"
                onSubmit={search}
              >
                <img className="img-barter" src={iconSearch} alt="search" />
                <input
                  type="text"
                  className="placeh"
                  placeholder="Input wallet address that will be invited to barter"
                  aria-label="Search"
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <button
                  style={{
                    borderRadius: "10px",
                    background: "transparent",
                    border: "solid 1px #ffffff",
                  }}
                  className="btn btn-success mx-2"
                  type="submit"
                >
                  Search
                </button>
              </form>
            </div>
          </div>
          <span className="text-center text-send">send barter offer to</span>
          {keyword === "" ? (
            <p></p>
          ) : (
            <p className="text-center text-send">{keyword}</p>
          )}
          <div className="d-md-none d-lg-none d-xl-none">
            <form onSubmit={search} className="d-flex search-box">
              <input
                className=" me-2"
                type="text"
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="Search"
                aria-label="Search"
              />
              <button
                className="btn btn-success"
                style={{
                  borderRadius: "10px",
                  background: "transparent",
                  border: "solid 1px #ffffff",
                }}
                type="submit"
              >
                Search
              </button>
            </form>
          </div>
          <div className="col">
            <div>
              <div className="row match-height">
                <div className="col-lg-6 mt-2">
                  <div className="card bg-ungu">
                    <div className="card-header bg-card-silver">
                      <h6 className="card-title text-center">
                        Items Will Send
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="row mt-2">
                        {loading ? (
                          <>
                            <div className="col-md-4 col-6 mb-1">
                              <Loader></Loader>
                            </div>
                            <div className="col-md-4 col-6 mb-1">
                              <Loader></Loader>
                            </div>
                            <div className="col-md-4 col-6 mb-1">
                              <Loader></Loader>
                            </div>
                            <div className="col-md-4 col-6 mb-1">
                              <Loader></Loader>
                            </div>
                          </>
                        ) : dataMeta.result?.assets?.length > 0 ? (
                          dataMeta.result.assets.map((asset, index: number) => {
                            return (
                              <div className="col-3 col-4" key={index}>
                                <input
                                  type="checkbox"
                                  value={JSON.stringify(asset)}
                                  name={asset.tokenAddress}
                                  onChange={handleOnChange}
                                />
                                <img
                                  src={asset.imageUrl}
                                  alt={asset.tokenAddress}
                                  className="img-fluid rounded"
                                />
                              </div>
                            );
                          })
                        ) : (
                          <p>NFT not Available</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-2">
                  <div className="card bg-ungu">
                    <div className="card-header bg-card-silver">
                      <h6 className="text-center card-title">
                        Items Will Received
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        {loading2 ? (
                          <Loader></Loader>
                        ) : dataMeta2.result?.assets?.length > 0 ? (
                          dataMeta2.result.assets.map((asset) => {
                            return (
                              <div
                                className="col-3 col-4"
                                key={asset.tokenAddress}
                              >
                                <input
                                  type="checkbox"
                                  value={JSON.stringify(asset)}
                                  name={asset.tokenAddress}
                                  onChange={handleOnChange2}
                                />
                                <img
                                  src={asset.imageUrl}
                                  alt={asset.tokenAddress}
                                  className="img-fluid rounded"
                                />
                              </div>
                            );
                          })
                        ) : (
                          <div
                            className="d-flex items-align-center justify-content-center"
                            style={{ height: "100px" }}
                          >
                            NFT not Available
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalCardNFT"
                  >
                    <div className="card bg-ungu mt-3">
                      <div className="card-body mdl-img">
                        <div className="container d-flex justify-content-center img-center">
                          <img src={iconImg} alt="icon" />
                        </div>
                        <h6 className="text-center">
                          place the items to be barter
                        </h6>
                      </div>
                    </div>
                  </a> */}
                </div>
              </div>
            </div>
            <div className="d-grid gap-2 mt-3">
              <button
                className="btn btn-warning"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalOffer"
                onClick={display}
              >
                Make Offer
              </button>

              <div
                className="modal fade"
                id="exampleModalOffer"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content bg-ungu">
                    <div className="modal-header text-white">
                      <h6
                        className="modal-title text-center mx-auto"
                        id="exampleModalLabel"
                      >
                        Confirm Barter Offer
                      </h6>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="card bg-ungu"
                            style={{ border: "1px solid #fff" }}
                          >
                            <div
                              className="card-header"
                              style={{ borderBottom: "1px solid #fff" }}
                            >
                              <h6 className="card-title text-center">
                                Item will send
                              </h6>
                            </div>
                            <div className="card-body">
                              <div className="table-responsive">
                                <table className="table">
                                  <tbody>
                                    {offer ? (
                                      body.initializers.map((item) => {
                                        return (
                                          <tr
                                            key={item.mint_address}
                                            style={{ color: "white" }}
                                          >
                                            <td>{item.name}</td>
                                            <td className="color-E42575">
                                              {item.mint_address
                                                .toString()
                                                .substring(0, 8) +
                                                "..." +
                                                item.mint_address
                                                  .toString()
                                                  .substring(
                                                    item.mint_address.toString()
                                                      .length - 5
                                                  )}
                                            </td>
                                            <td>
                                              {item.sended === false ? (
                                                <button
                                                  className="btn btn-warning"
                                                  onClick={(e) => {
                                                    getEscrowTokenAccountAddress(
                                                      new PublicKey(body.initializer_address),
                                                      new PublicKey(
                                                        item.token_account_address
                                                      ),
                                                      new PublicKey(item.mint_address),
                                                      e
                                                    );
                                                  }}
                                                  ref={buttonSend}
                                                >
                                                  Send
                                                </button>
                                              ) : (
                                                <></>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan={3}>
                                          <ContentLoader
                                            height={140}
                                            speed={1}
                                            backgroundColor={"#333"}
                                            foregroundColor={"#999"}
                                            viewBox="0 0 380 70"
                                          >
                                            {/* Only SVG shapes */}
                                            <rect
                                              x="0"
                                              y="0"
                                              rx="5"
                                              ry="5"
                                              width="70"
                                              height="70"
                                            />
                                            <rect
                                              x="80"
                                              y="17"
                                              rx="4"
                                              ry="4"
                                              width="300"
                                              height="13"
                                            />
                                            <rect
                                              x="80"
                                              y="40"
                                              rx="3"
                                              ry="3"
                                              width="250"
                                              height="10"
                                            />
                                          </ContentLoader>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 mt-3">
                        <div
                          className="card bg-ungu"
                          style={{ border: "1px solid #fff" }}
                        >
                          <div
                            className="card-header"
                            style={{ borderBottom: "1px solid #fff" }}
                          >
                            <h6 className="card-title text-center">
                              Item will Received
                            </h6>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  {offer ? (
                                    body.owners.map((item) => {
                                      return (
                                        <tr
                                          key={item.mint_address}
                                          style={{ color: "white" }}
                                        >
                                          <td>{item.name}</td>
                                          <td className="color-E42575">
                                            {item.mint_address
                                              .toString()
                                              .substring(0, 8) +
                                              "..." +
                                              item.mint_address
                                                .toString()
                                                .substring(
                                                  item.mint_address.toString()
                                                    .length - 5
                                                )}
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={3}>
                                        <ContentLoader
                                          height={140}
                                          speed={1}
                                          backgroundColor={"#333"}
                                          foregroundColor={"#999"}
                                          viewBox="0 0 380 70"
                                        >
                                          {/* Only SVG shapes */}
                                          <rect
                                            x="0"
                                            y="0"
                                            rx="5"
                                            ry="5"
                                            width="70"
                                            height="70"
                                          />
                                          <rect
                                            x="80"
                                            y="17"
                                            rx="4"
                                            ry="4"
                                            width="300"
                                            height="13"
                                          />
                                          <rect
                                            x="80"
                                            y="40"
                                            rx="3"
                                            ry="3"
                                            width="250"
                                            height="10"
                                          />
                                        </ContentLoader>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr style={{ color: "#E42575" }} />
                      {sendGas == false ? (
                        <div className="row mb-5">
                          <div className="col-12 d-flex justify-content-center mb-3">
                            Pay Your Gas Fee to Create Barter:
                          </div>
                          <div className="col-12  d-flex  justify-content-center">
                            <button
                              type="button"
                              className="btn btn-warning"
                              onClick={() => {
                                sendGasFee();
                              }}
                            >
                              Send Gas Fee
                            </button>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {offer && sendGas ? (
                        <div className="d-flex justify-content-center">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              hideDisplay();
                            }}
                          >
                            Cancel
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={() => {
                              makeOffer();
                            }}
                          >
                            Confirm
                          </button>
                          &nbsp;
                        </div>
                      ) : (
                        <p></p>
                      )}
                      {/* <div className="text-center mb-3">
                        Pay Your Gas Fee to Create Barter
                      </div>
                      {offer ? (
                        <div className="d-flex justify-content-center">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={hideDisplay}
                          >
                            Cancel
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={makeOffer}
                          >
                            Confirm
                          </button>
                        </div>
                      ) : (
                        <p></p>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ModalCardNFT></ModalCardNFT>
          </div>
        </div>
      </DisplayContent>
    </>
  );
}
