import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useRecoilState } from "recoil";
import { multicarousel } from "../../store/recoil";
import client from "../../axios/client";
import imgDemo from "../../assets/img/image_5.png";
// import { multiCarousel } from "../../model/auction/multiCarousel";
export default function MultiCarousel() {
    const [auction, setAuction] = useRecoilState(multicarousel);
    // const [dummy, setDummy] = useState<multiCarousel>({} as multiCarousel)
    const [dummy, setDummy] = useState([
        {
            auction_id: "1",
            image_url: imgDemo,
            nft_name: "Blatant 1"
        },
        {
            auction_id: "2",
            image_url: imgDemo,
            nft_name: "Blatant 2"
        },
        {
            auction_id: "3",
            image_url: imgDemo,
            nft_name: "Blatant 3"
        },
        {
            auction_id: "4",
            image_url: imgDemo,
            nft_name: "Blatant 4"
        },
        {
            auction_id: "5",
            image_url: imgDemo,
            nft_name: "Blatant 5"
        }
    ])
    async function getAuction() {
        try {
            const { data } = await client.get('/api/multi-carousel');
            setAuction(data.data);
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        getAuction();
    }, [])

    return (
        <>
            <div className="text-white" style={{ paddingBottom: '30px', position: 'relative', textAlign: 'center' }}>
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlay
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="container"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    partialVisible
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 4,
                            partialVisibilityGutter: 10
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 3,
                            partialVisibilityGutter: 10
                        }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >

                    {auction.length > 0 ? (
                        auction.map((item: any, index: any) => {
                            return (
                                <div key={index}>
                                    <img src={item.image_url} width="169" alt="NFT" />
                                    <div className="title-img">
                                        <span className="bottom-title" style={{ fontSize: "10px" }}>{item.nft_name}</span>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        dummy.map((asset: any, index: any) => {
                            return (
                                <div key={index}>
                                    <img src={asset.image_url} width="169" alt="NFT" />
                                    <div className="title-img">
                                        <div style={{ fontSize: "17px" }}>{asset.nft_name}</div>
                                    </div>
                                </div>
                            )
                        })
                    )}
                </Carousel>
            </div>
        </>
    );

}