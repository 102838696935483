import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DisplayContent from "../component/DisplayContent";
import FotoProfile from "../assets/profile/profile.png";
import iconCopy from "../assets/icon/copy.svg";
import iconTwit from "../assets/icon/twit.svg";
import iconIg from "../assets/icon/ig.svg";
import iconDc from "../assets/icon/dc_p.svg";
import NftImage from "../component/NftImage";
import "../assets/css/profile.css";
import axios from "../axios/client";
import { useRecoilState } from "recoil";
import { currentUser } from "../store/recoil";
import { toast } from "react-toastify";
import AuctionNFT from "../component/auction/AuctionNFT";
import Activity from "../component/auction/Activity";
import Vault from "../component/Vault";
export default function Profile() {
  const [authUser, setAuthUser] = useRecoilState(currentUser);
  const [profile, setProfile] = useState({
    name: "",
    bio: "",
    discord: "",
    instagram: "",
    twitter: "",
  });

  const [countAuction, setCountAuction] = useState(0);
  const [countActivity, setCountActivity] = useState(0);

  function handleChange(event: any) {
    const { name, value } = event.target;
    setProfile({
      ...profile,
      [name]: value,
    });
  }

  function prof() {
    setProfile({
      ...profile,
      name: authUser.name,
      bio: authUser.bio,
      discord: authUser.discord,
      instagram: authUser.instagram,
      twitter: authUser.twitter,
    });
  }
  useEffect(() => {
    async function currentUser() {
      try {
        const { data } = await axios.get("/api/member/profile");
        setAuthUser(data.data);
        setCountAuction(data.CountAuction);
        setCountActivity(data.CountActivity);
        console.log(data);
      } catch (error: any) {
        console.log(error);
      }
    }
    currentUser();
    prof();
  }, []);
  const submitForm = (e: any) => {
    e.preventDefault();

    axios
      .put("/api/member/profile", profile)
      .then((res) => {
        const { data } = res;

        setAuthUser({
          ...authUser,
          name: data.data.name,
          bio: data.data.bio,
          discord: data.data.discord,
          instagram: data.data.instagram,
          twitter: data.data.twitter,
        });
        prof();
        toast.success(`success update`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClick = () => {
    navigator.clipboard.writeText(authUser.wallet);
    toast("Copied to clibboard", {
      position: "top-right",
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  return (
    <>
      <DisplayContent>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-md-2">
                <div className="d-flex justify-content-center">
                  <img
                    className="photo-profile"
                    src={FotoProfile}
                    width="80"
                    alt="profile"
                  />
                </div>
              </div>
              <div className="col-md-10">
                <div className="row color-E42575">
                  <div className="col-sm-2 d-flex justify-content-center">
                    <span className="profile-name">{authUser.name}</span>
                  </div>
                  <div className="col-sm-10 d-flex justify-content-center">
                    <div className="my-wallet" style={{ fontSize: "14px" }}>
                      {authUser.wallet}
                    </div>
                    <span onClick={handleClick}>
                      <img
                        className="mr-2"
                        src={iconCopy}
                        alt="icon"
                        width="15"
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 col-md-5">
                    <div className="row">
                      <div className="col-3 col-sm-2 col-md-6">Auction</div>
                      <div className="col-3 col-sm-4 col-md-6">
                        {countAuction}
                      </div>
                      <div className="col-3 col-sm-2 col-md-6">Activity</div>
                      <div className="col-3 col-sm-4 col-md-6">
                        {countActivity}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="profile-desc mt-4">{authUser.bio}</p>
            <div className="link-sosmed">
              {authUser.twitter ? (
                <a
                  target="_blank"
                  href={`https://twitter.com/${authUser.twitter}`}
                >
                  <img src={iconTwit} width="35" alt="icon" />
                </a>
              ) : (
                <></>
              )}
              &nbsp; &nbsp; &nbsp;
              {authUser.instagram ? (
                <a
                  target="_blank"
                  href={`https://www.instagram.com/${authUser.instagram}`}
                >
                  <img src={iconIg} width="35" alt="icon" />
                </a>
              ) : (
                <></>
              )}
              &nbsp; &nbsp; &nbsp;
              {authUser.discord ? (
                <a
                  target="_blank"
                  href={`https://discord.com/user/${authUser.discord}`}
                >
                  <img src={iconDc} width="35" alt="icon" />
                </a>
              ) : (
                <></>
              )}
            </div>
            <div className="d-grid gap-2 mt-3">
              <button
                className="btn btn-warning"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={() => prof()}
              >
                Edit Profile
              </button>
            </div>
            <div
              className="modal fade"
              id="exampleModal"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content bg-ungu">
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-2 mx-auto">
                        <img src={FotoProfile} width="60" alt="profile" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <form onSubmit={submitForm}>
                          <div className="form-group">
                            <div className="mt-1">
                              <label>Display Name</label>
                              <input
                                type="text"
                                style={{
                                  background: "rgba(255, 255, 255, 0.2)",
                                  color: "#FFF",
                                }}
                                name="name"
                                className="form-control"
                                value={profile.name}
                                onChange={handleChange}
                                id="name"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="mt-2">
                              <label>Bio</label>
                              <textarea
                                style={{
                                  background: "rgba(255, 255, 255, 0.2)",
                                  color: "#FFF",
                                }}
                                name="bio"
                                className="form-control"
                                value={profile.bio}
                                onChange={handleChange}
                                id="bio"
                              ></textarea>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="mt-2">
                              <label>Twitter Link</label>
                              <input
                                type="text"
                                style={{
                                  background: "rgba(255, 255, 255, 0.2)",
                                  color: "#FFF",
                                }}
                                name="twitter"
                                className="form-control"
                                value={profile.twitter}
                                onChange={handleChange}
                                id="twitter"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="mt-2">
                              <label>Instagram Link</label>
                              <input
                                type="text"
                                style={{
                                  background: "rgba(255, 255, 255, 0.2)",
                                  color: "#FFF",
                                }}
                                name="instagram"
                                className="form-control"
                                value={profile.instagram}
                                id="instagram"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="mt-2">
                              <label>Discord Link</label>
                              <input
                                type="text"
                                style={{
                                  background: "rgba(255, 255, 255, 0.2)",
                                  color: "#FFF",
                                }}
                                name="discord"
                                className="form-control"
                                value={profile.discord}
                                id="discord"
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="d-grid gap-2 mt-4 mb-4">
                            <button
                              className="btn btn-warning"
                              type="submit"
                              data-bs-dismiss="modal"
                            >
                              Update Profile
                            </button>

                            <button
                              type="button"
                              className="btn btn-outline-warning"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  style={{ width: "170px" }}
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Auction
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  style={{ width: "170px" }}
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Activity
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  style={{ width: "170px" }}
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-vault"
                  type="button"
                  role="tab"
                  aria-controls="pills-vault"
                  aria-selected="false"
                >
                  Vault
                </button>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <AuctionNFT />
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <Activity />
            </div>
            <div
              className="tab-pane fade"
              id="pills-vault"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <Vault />
            </div>
          </div>
        </div>
      </DisplayContent>
    </>
  );
}
