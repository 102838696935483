import React from "react";

import Logo from "../assets/logo/logo.png";
import iconTele from "../assets/icon/tele.svg";
import iconTwit from "../assets/icon/twit.svg";
import iconIg from "../assets/icon/ig.svg";
import iconDc from "../assets/icon/dc.svg";
import iconSearch from "../assets/icon/search.svg";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { currentUser, searchKeyword } from "../store/recoil";
export default function Footer() {
  const [keyword, setKeyword] = useRecoilState(searchKeyword);

  const navigate = useNavigate();

  function searchData(e: any) {
    e.preventDefault();
    navigate(`/search?keyword=${keyword}`, { replace: true });
  }
  return (
    <>
      <footer id="footer">
        <div className="footer-newsletter">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <a href="#"></a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 footer-contact d-flex justify-content-center">
                <div>
                  <div className="d-flex justify-content-center">
                    <a href="#">
                      <img src={Logo} alt="Blatant" height="56" />
                    </a>
                  </div>
                  <div className="d-flex justify-content-center">
                    <span className="mb-2">Follow Us</span>
                  </div>
                  <div className="social-links mt-3 d-flex justify-content-center">
                    {/* <a href="#"><img src={iconTele} alt="telegram" /></a> */}
                    <a
                      href="https://twitter.com/blatantnft?s=21&t=CUj1GtpFqt-zZ9FuXgyu7A"
                      target={"_blank"}
                    >
                      <img src={iconTwit} alt="Twitter" />
                    </a>
                    {/* <a href="#"><img src={iconIg} alt="twitter" /></a> */}
                    <a href="https://discord.gg/ARewR87gW2" target={"_blank"}>
                      <img src={iconDc} alt="discord" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Blatant</h4>
                <ul>
                  <li>
                    <a href="#">Blatant</a>
                  </li>
                  <li>
                    <a href="#">Sites</a>
                  </li>
                  <li>
                    <a href="#">Blog</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Information</h4>
                <ul>
                  <li>
                    <a href="#">Terms Of Service</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-6 footer-links">
                <form
                  onSubmit={(e) => {
                    searchData(e);
                  }}
                  className="d-none d-lg-block pencarian-bottom"
                >
                  <img className="img-search-bot" src={iconSearch} alt="icon" />
                  <input
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    type="text"
                    placeholder="Search"
                    aria-label="Search"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
