import React from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import ConnectWallet from "./component/ConnectWallet";

const MyWallet: React.FC = () => {
  // let walletAddress = "";

  // if you use anchor, use the anchor hook instead
  // const wallet = useAnchorWallet();
  // const walletAddress = wallet?.publicKey.toString();

  const wallet = useWallet();
  if (wallet.connected && wallet.publicKey) {
    // walletAddress = wallet.publicKey.toString();
  }

  return (
    <div className="text-center">
      {/* {(wallet.connected && <p>Your wallet is {walletAddress}</p>) || (
        <p>Hello! Click the button to connect</p>
      )} */}

      <div className="multi-wrapper">
        <span className="button-wrapper">
          <WalletModalProvider>
            <WalletMultiButton />
          </WalletModalProvider>

          {wallet.connected ? <ConnectWallet /> : ""}
        </span>
      </div>
    </div>
  );
};

export default MyWallet;
