import ContentLoader from "react-content-loader";

const LoaderCardMobile = () => (
    <ContentLoader
        height={380}
        speed={1}
        backgroundColor={"#333"}
        foregroundColor={"#999"}
        viewBox="0 1 37 40"
    >
        <rect x="0" y="2" rx="1" ry="1" width="100%" height="35" />
    </ContentLoader>
);

export default LoaderCardMobile;
