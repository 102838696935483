import React from "react";

export default function ModalCardNFT() {
    return (
        <>
            <div className="modal fade" id="exampleModalCardNFT" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content bg-ungu">
                        <div className="modal-header">
                            <h2 className="modal-title fs-5" id="exampleModalLabel">PLace The Items
                                You Have</h2>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div className="d-flex justify-content-center mb-4 mt-4">
                            <div className="search-modal">
                                <img className="img-b" src="/assets/img/icon/search.svg" alt="" />
                                <input type="text" placeholder="Search" />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="row d-flex justify-content-center">
                                <div className="col-8 mx-auto">

                                    <div className="row row-cols-1 row-cols-md-4 g-4 mt-4">
                                        <div className="col-3 col-6">
                                            <div className="card border-dark bg-transparent">
                                                <img src="/assets/img/item/image_5.png" className="card-img-top"
                                                    alt="..." />
                                                <div className="card-body">
                                                    <span className="card-title">Blatant
                                                    </span>
                                                    <p className="card-text">Blatant</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3 col-6">
                                            <div className="card border-dark bg-transparent">
                                                <img src="/assets/img/item/image_5.png" className="card-img-top"
                                                    alt="..." />
                                                <div className="card-body">
                                                    <span className="card-title">Card title</span>
                                                    <p className="card-text">Blatant</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3 col-6">
                                            <div className="card border-dark bg-transparent">
                                                <img src="/assets/img/item/image_5.png" className="card-img-top"
                                                    alt="..." />
                                                <div className="card-body">
                                                    <span className="card-title">Card title</span>
                                                    <p className="card-text">Blatant</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3 col-6">
                                            <div className="card border-dark bg-transparent">
                                                <img src="/assets/img/item/image_5.png" className="card-img-top"
                                                    alt="..." />
                                                <div className="card-body">
                                                    <span className="card-title">Card title</span>
                                                    <p className="card-text">Blatant</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-8 mx-auto mb-5">
                            <div className="d-grid gap-2 mt-5">
                                <button className="btn btn-warning" type="button">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}