import React, { useEffect, useState } from "react";
import DisplayContent from "../component/DisplayContent";
import ModalFilter from "../component/modal/ModalFilter";
import iconFilter from "../assets/icon/filter.svg";
import iconPlus from "../assets/icon/plus.svg";
import imgDemo from "../assets/img/image_5.png";
import iconSearch from "../assets/icon/search.svg";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { auctionMades } from "../store/recoil";
import { Pagination } from 'react-bootstrap';
import client from "../axios/client";
import { toast } from "react-toastify";
import LoaderCard from "../component/loading/LoaderCard";
import LoaderCardMobile from "../component/loading/LoaderCardMobile";
import {
  Commitment,
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Transaction,
} from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import { useWallet } from "@solana/wallet-adapter-react";

import IDL from "../program/blatant_program_idl.json";
import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";
import { PROGRAM_ID } from "../utils/program";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import baseUrl from "../config/base";
const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);
var id: string;
export default function Auction() {
  const [mades, setMades] = useRecoilState(auctionMades);
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageMade, setPageMade] = useState(1);
  const [totalPagesMade, setTotalPagesMade] = useState(1);
  const [pageOffers, setPageOffers] = useState(1);
  const [totalPagesOffers, setTotalPagesOffers] = useState(1);
  async function getAuction(halaman: number = 1) {
    setPageMade(halaman);
    setPageOffers(halaman);
    try {
      const res = await client.get(`/api/member/auctions?page=${halaman}`);
      setTotalPagesMade(res.data.made_last_page);
      setMades(res.data.data);
      setTotalPagesOffers(res.data.offers_last_page);
      setOffers(res.data.offers.data);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  }

  const handlePageChangeMade = (page: number) => {
    setPageMade(page);
    getAuction(page);
  };

  const handlePageChangeOffers = (page: number) => {
    setPageOffers(page);
    getAuction(page);
  };

  

  function msgBox(type: string = "warning", message: string) {
    if (type == "warning") {
      toast.warning(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "success") {
      toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "error") {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  function stopAuction() {
    client
      .post("/api/member/auctions/stop", {
        id: id,
      })
      .then((res) => {
        msgBox("success", "Auction stopped");
        getAuction();
      })
      .catch((error: any) => {
        msgBox("error", error.response.data.message);
      });
  }

  function cancelOffer(item: any) {
    client
      .put(`/api/member/auction/cancel-offer/${item.auction_bid_id}`)
      .then((res) => {
        msgBox("success", "Auction Cancel");
        getAuction();
      })
      .catch((error: any) => {
        msgBox("error", error.response.data.message);
      });
  }

  async function initUlangTokenAddress(
    buyer: PublicKey,
    mint_address: PublicKey
  ) {
    const tokenAddr = Keypair.generate();
    const txA = await program.methods
      .create()
      .accounts({
        buyer: buyer,
        xMint: mint_address,
        buyerXToken: tokenAddr.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        rent: SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
      })
      .signers([tokenAddr])
      .rpc({ skipPreflight: true });

    console.log("txA", txA);
    console.log("tokenAddr", tokenAddr.publicKey.toString());

    return tokenAddr.publicKey;
  }
  async function findAssociatedTokenAddress(
    walletAddress: PublicKey,
    tokenMintAddress: PublicKey
  ): Promise<PublicKey> {
    const solana = new Connection(baseUrl);
    const account = await solana.getTokenAccountsByOwner(walletAddress, {
      mint: tokenMintAddress,
    });
    if (account.value.length > 0) {
      console.log("token pertama", account.value[0].pubkey);

      return account.value[0].pubkey;
    } else {
      const res = await initUlangTokenAddress(walletAddress, tokenMintAddress);
      console.log("awaitnya", res);
      return res;
    }
  }
  const wallet = useWallet();
  const { sendTransaction } = useWallet();

  const opts = {
    preflightCommitment: "processed" as Commitment,
  };
  function getConnection() {
    let network =
      localStorage.getItem("network") || baseUrl;
    const connection = new Connection(network, opts.preflightCommitment);
    return connection;
  }
  const program = new anchor.Program(
    IDL as any as anchor.Idl,
    PROGRAM_ID,
    new anchor.AnchorProvider(
      getConnection(),
      wallet as any as NodeWallet,
      opts
    )
  );
  let escrow: PublicKey;

  async function cancelAuction(item: any, signature?: string) {
    const id: string = item.id;
    try {
      const { data } = await client.post(`/api/member/auctions/cancel`, {
        aution_id: id,
        signature: signature,
      });
      console.log("data", data);
      msgBox("success", data.message);
      getAuction();
    } catch (error: any) {
      console.log(error);
    }
  }
  async function claim(item: any) {
    const mintAddress = item.mint_address;
    // const buyer = new PublicKey(item.wallet_address); // yang akan mengclaim
    const seller = new PublicKey(item.wallet_address); // yang akan di claim

    console.log("seller", seller.toString());
    // console.log("buyer", buyer.toString());

    [escrow] = await PublicKey.findProgramAddress(
      [anchor.utils.bytes.utf8.encode("blatant"), seller.toBuffer()],
      program.programId
    );

    // console.log(escrow.toString());
    const escrowxTokenxx = await findAssociatedTokenAddress(
      escrow,
      new PublicKey(mintAddress)
    );
    // console.log(escrowxTokenxx.toString());
    const tokenacc = await findAssociatedTokenAddress(
      seller,
      new PublicKey(mintAddress)
    );
    
    try {
      const tx = await program.methods
        .cancel()
        .accounts({
          seller: seller,
          escrow: escrow,
          escrowedXTokens: escrowxTokenxx,
          sellerXToken: tokenacc,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .rpc({ skipPreflight: true });

      // const signature = await sendTransaction(tx, program.provider.connection);
      // await program.provider.connection.confirmTransaction(
      //   signature,
      //   "processed"
      // );
      cancelAuction(item);
      // console.log("TXnya ", signature);
      console.log("TXnya ", tx);
    } catch (error: any) {
      console.log(error);
     }
  }

  useEffect(() => {
    getAuction();
  }, []);

  return (
    <>
      <DisplayContent>
        <div className="d-md-none d-lg-none d-xl-none">
          <div className="row">
            <div className="col-5">
              <button
                className="btn btn-secondary"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                <img src={iconSearch} alt="" />
              </button>

              <div
                className="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content bg-ungu">
                    <div className="modal-header">
                      <h6 className="modal-title" id="staticBackdropLabel">
                        Search
                      </h6>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <form className="auction-cari me-auto" role="search">
                        <img className="serch" src={iconSearch} alt="search" />
                        <input
                          type="text"
                          className="placeh"
                          placeholder="Search"
                          aria-label="Search"
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-7">
              <div className="d-grid gap-2">
                <Link
                  className="btn btn-warning btn-sm text-dark"
                  style={{ fontSize: "16px" }}
                  to="/new/auction"
                >
                  <b>Create New Auction +</b>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="p-2 flex-grow-1">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Auction Made
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Auction Offer
                </button>
              </li>
              &nbsp;&nbsp;&nbsp;
              <li className="nav-item" role="presentation">
                <button
                  type="button"
                  className="filter btn btn-dark d-none d-sm-block"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalFilter"
                >
                  <img src={iconFilter} alt="icon" />
                </button>
              </li>
            </ul>
            <ModalFilter></ModalFilter>
          </div>
          <div className="p-2 flex-grow-1">
            <div className="d-md-none d-lg-none d-xl-none">
              <button
                type="button"
                style={{ borderRadius: "15px", background: "#24182F" }}
                className="btn btn-dark"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalFilter"
              >
                <img src={iconFilter} alt="" />
              </button>
            </div>
            <div className="d-none d-sm-block">
              <div className="d-grid gap-2">
                <Link
                  className="btn btn-warning text-dark"
                  style={{ fontSize: "15px" }}
                  to="/new/auction"
                >
                  <b>Create New Auction</b>{" "}
                  <img width="20" height="20" src={iconPlus} alt="icon" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="d-none d-sm-block">
                <hr />
                <div className="card bg-ungu text-white">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-2">Status</div>
                      <div className="col-4">Items</div>
                      <div className="col-1">Offer</div>
                      <div className="col-2">Time Expired</div>
                      <div className="col-2"></div>
                    </div>
                  </div>
                </div>
                {loading == false ? (
                  mades.length > 0 ? (
                    mades.map((made: any) => {
                      return (
                        <div className="card bg-ungu mt-2" key={made.id}>
                          <div className="card-body text-white">
                            <div className="row">
                              <div className="col-2">
                                {made.status == "Running" ? (
                                  <span className="text-orange">Running</span>
                                ) : made.status == "Stop" ? (
                                  <span className="text-merah">Stoped</span>
                                ) : made.status == "Cancel" ? (
                                  <span className="text-merah">Cancel</span>
                                ) : made.status == "Draft" ? (
                                  <span className="text-warning">Draft</span>
                                ) : (
                                  <span className="text-hijau">Finish</span>
                                )}
                              </div>
                              <div className="col-4">
                                <div className="row">
                                  <div className="col-3">
                                    <img
                                      src={made.image_url}
                                      className="img-fluid"
                                      width="65"
                                      alt="img"
                                    />
                                  </div>
                                  <div className="col-9">{made.nft_name}</div>
                                </div>
                              </div>
                              <div className="col-1">{made.offers.length}</div>
                              <div className="col-2">{made.end}</div>
                              <div className="col-3">
                                <div className="d-grid gap-2">
                                  <Link
                                    className="btn btn-warning text-dark"
                                    to={"/details/auction/" + made.id}
                                  >
                                    Details
                                  </Link>
                                  {made.status == "Running" ? (
                                    <button
                                      className="btn btn-outline-warning"
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModalStop"
                                      onClick={() => {
                                        id = made.id;
                                      }}
                                    >
                                      Stop
                                    </button>
                                  ) : (
                                    <></>
                                  )}

                                  {made.status == "Stop" ? (
                                    <button
                                      className="btn btn-success"
                                      type="button"
                                      onClick={() => {
                                        claim(made);
                                      }}
                                    >
                                      Claim NFT
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-8 mx-auto mt-3">
                      <div className="text-center">No Data Availabe</div>
                    </div>
                  )
                ) : (
                  <>
                    <div className="mt-2">
                      <LoaderCard></LoaderCard>
                      <LoaderCard></LoaderCard>
                    </div>
                  </>
                )}

                <div className="row mt-4">
                  <div className="d-flex justify-content-end">
                    <Pagination>
                      {pageMade > 1 && (
                        <Pagination.Prev onClick={() => handlePageChangeMade(pageMade - 1)} >Previous</Pagination.Prev>
                      )}
                      {pageMade > 3 && (
                        <Pagination.Item onClick={() => handlePageChangeMade(1)}>
                          1
                        </Pagination.Item>
                      )}
                      {pageMade > 3 && <Pagination.Ellipsis />}
                      {pageMade > 2 && (
                        <Pagination.Item onClick={() => handlePageChangeMade(pageMade - 2)}>
                          {pageMade - 2}
                        </Pagination.Item>
                      )}
                      {pageMade > 1 && (
                        <Pagination.Item onClick={() => handlePageChangeMade(pageMade - 1)}>
                          {pageMade - 1}
                        </Pagination.Item>
                      )}
                      <Pagination.Item active>{pageMade}</Pagination.Item>
                      {pageMade < totalPagesMade && (
                        <Pagination.Item onClick={() => handlePageChangeMade(pageMade + 1)}>
                          {pageMade + 1}
                        </Pagination.Item>
                      )}
                      {pageMade < totalPagesMade - 1 && (
                        <Pagination.Item onClick={() => handlePageChangeMade(pageMade + 2)}>
                          {pageMade + 2}
                        </Pagination.Item>
                      )}
                      {pageMade < totalPagesMade - 2 && <Pagination.Ellipsis />}
                      {pageMade < totalPagesMade - 2 && (
                        <Pagination.Item onClick={() => handlePageChangeMade(totalPagesMade)}>
                          {totalPagesMade}
                        </Pagination.Item>
                      )}
                      {pageMade < totalPagesMade && (
                        <Pagination.Next onClick={() => handlePageChangeMade(pageMade + 1)} >Last</Pagination.Next>
                      )}
                    </Pagination>
                  </div>
                </div>
              </div>

              <div className="d-md-none d-lg-none d-xl-none">
                {loading == false ? (
                  mades.length > 0 ? (
                    mades.map((made: any) => {
                      return (
                        <div className="card bg-ungu" key={made.id}>
                          <div className="card-header bg-card-header">
                            <div className="row">
                              <div className="col-5">
                                <div className="card-title">
                                  {made.status == "Running" ? (
                                    <span className="text-orange">Running</span>
                                  ) : made.status == "Stop" ? (
                                    <span className="text-merah">Stoped</span>
                                  ) : made.status == "Cancel" ? (
                                    <span className="text-merah">Cancel</span>
                                  ) : (
                                    <span className="text-hijau">Finished</span>
                                  )}
                                </div>
                              </div>
                              <div className="col-7">
                                <div>Expired {made.end}</div>
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-4">
                                <img
                                  src={made.image_url}
                                  className="w-100"
                                  alt=""
                                />
                              </div>
                              <div className="col-8">
                                <h6>{made.nft_name}</h6>
                                <p className="color-E42575">
                                  {made.mint_address}
                                </p>

                                <span className="mt-4">
                                  {made.offers.length} Offer
                                </span>
                              </div>
                            </div>
                            <div className="d-grid gap-2 mt-4">
                              <Link
                                className="btn btn-sm btn-warning text-dark"
                                to={"/details/auction/" + made.id}
                              >
                                Details
                              </Link>
                              {made.status == "Running" ? (
                                <button
                                  className="btn btn-sm btn-outline-warning"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModalStop"
                                  onClick={() => {
                                    id = made.id;
                                  }}
                                >
                                  Stop
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-8 mx-auto mt-3">
                      <div className="text-center">No Data Availabe</div>
                    </div>
                  )
                ) : (
                  <LoaderCardMobile></LoaderCardMobile>
                )}


              </div>

              <div
                className="modal fade"
                id="exampleModalStop"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-sm modal-dialog-centered">
                  <div className="modal-content bg-ungu">
                    <div className="modal-header">
                      <h6
                        className="modal-title text-center mx-auto"
                        id="exampleModalLabel"
                      >
                        {" "}
                        Stop Auction
                      </h6>
                    </div>
                    <div className="modal-body">
                      <span>Are you sure want to stop auction?</span>
                      <div className="d-flex justify-content-center mt-3">
                        <button
                          type="button"
                          className="btn btn-warning"
                          data-bs-dismiss="modal"
                        >
                          No
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-outline-warning"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            stopAuction();
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="d-none d-sm-block">
                <hr />
                <div className="card bg-ungu">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-1">Status</div>
                      <div className="col-4">Auction Items</div>
                      <div className="col-4">Your Offer</div>
                      <div className="col-3"></div>
                    </div>
                  </div>
                </div>

                {loading == false ? (
                  offers.length > 0 ? (
                    offers.map((item: any, index: number) => {
                      return (
                        <div className="card bg-ungu mt-2" key={index}>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-1">
                                {item.status == "Running" ? (
                                  <span className="text-orange">Running</span>
                                ) : item.status == "Stop" ? (
                                  <span className="text-merah">Stoped</span>
                                ) : item.status == "Cancel" ? (
                                  <span className="text-merah">Cancel</span>
                                ) : item.status == "Apply" ? (
                                  <span className="text-hijau">Apply</span>
                                ) : (
                                  <span className="text-hijau">Finished</span>
                                )}
                              </div>
                              <div className="col-4">
                                <div className="row">
                                  <div className="col-3">
                                    <img
                                      src={item.auction?.image_url}
                                      className="img-fluid"
                                      width="65"
                                      alt="img"
                                    />
                                  </div>
                                  <div className="col-9">
                                    {item.auction?.nft_name}
                                  </div>
                                </div>
                              </div>
                              <div className="col-4">
                                {item.details?.map((j: any, i: number) => {
                                  return (
                                    <div className="row" key={i}>
                                      <div className="col-3">
                                        <img
                                          src={j.image_url}
                                          className="img-fluid"
                                          width="65"
                                          alt="img"
                                        />
                                      </div>
                                      <div className="col-9">{j.nft_name}</div>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="col-3">
                                <div className="d-grid gap-2">
                                  <Link
                                    className="btn btn-warning text-dark"
                                    to={
                                      "/details/auction-bidder/" +
                                      item.auction_bid_id
                                    }
                                  >
                                    Detail
                                  </Link>
                                  {item.status == "Running" ? (
                                    <button
                                      className="btn btn-outline-warning"
                                      type="button"
                                      onClick={() => {
                                        cancelOffer(item);
                                      }}
                                    >
                                      Cancel Offer
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div className="col-8 mx-auto mb-5 mt-5">
                        <div className="text-center">No Data Availabe</div>
                      </div>
                    </>
                  )
                ) : (
                  <>
                    <div className="mt-2">
                      <LoaderCard></LoaderCard>
                      <LoaderCard></LoaderCard>
                    </div>
                  </>
                )}

                <div className="row mt-4">
                  <div className="d-flex justify-content-end">
                    <Pagination>
                      {pageOffers > 1 && (
                        <Pagination.Prev onClick={() => handlePageChangeOffers(pageOffers - 1)} >Previous</Pagination.Prev>
                      )}
                      {pageOffers > 3 && (
                        <Pagination.Item onClick={() => handlePageChangeOffers(1)}>
                          1
                        </Pagination.Item>
                      )}
                      {pageOffers > 3 && <Pagination.Ellipsis />}
                      {pageOffers > 2 && (
                        <Pagination.Item onClick={() => handlePageChangeOffers(pageOffers - 2)}>
                          {pageOffers - 2}
                        </Pagination.Item>
                      )}
                      {pageOffers > 1 && (
                        <Pagination.Item onClick={() => handlePageChangeOffers(pageOffers - 1)}>
                          {pageOffers - 1}
                        </Pagination.Item>
                      )}
                      <Pagination.Item active>{pageOffers}</Pagination.Item>
                      {pageOffers < totalPagesOffers && (
                        <Pagination.Item onClick={() => handlePageChangeOffers(pageOffers + 1)}>
                          {pageOffers + 1}
                        </Pagination.Item>
                      )}
                      {pageOffers < totalPagesOffers - 1 && (
                        <Pagination.Item onClick={() => handlePageChangeOffers(pageOffers + 2)}>
                          {pageOffers + 2}
                        </Pagination.Item>
                      )}
                      {pageOffers < totalPagesOffers - 2 && <Pagination.Ellipsis />}
                      {pageOffers < totalPagesOffers - 2 && (
                        <Pagination.Item onClick={() => handlePageChangeOffers(totalPagesOffers)}>
                          {totalPagesOffers}
                        </Pagination.Item>
                      )}
                      {pageOffers < totalPagesOffers && (
                        <Pagination.Next onClick={() => handlePageChangeOffers(pageOffers + 1)} >Last</Pagination.Next>
                      )}
                    </Pagination>
                  </div>
                </div>
              </div>

              <div className="d-md-none d-lg-none d-xl-none">
                {loading == false ? (
                  offers.length > 0 ? (
                    offers.map((item: any, index: number) => {
                      return (
                        <div className="card bg-ungu" key={index}>
                          <div className="card-header bg-card-header">
                            <div className="row">
                              <div className="col-5">
                                {item.status == "Running" ? (
                                  <span className="card-title text-orange">
                                    Running
                                  </span>
                                ) : item.status == "Stop" ? (
                                  <span className="card-title text-merah">
                                    Stoped
                                  </span>
                                ) : item.status == "Cancel" ? (
                                  <span className="card-title text-merah">
                                    Cancel
                                  </span>
                                ) : item.status == "Apply" ? (
                                  <span className="card-title text-hijau">
                                    Apply
                                  </span>
                                ) : (
                                  <span className="card-title text-hijau">
                                    Finished
                                  </span>
                                )}
                              </div>
                              <div className="col-7">
                                <div>Expired in 02:56:12:12</div>
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-4">
                                <img src={imgDemo} className="w-100" alt="" />
                              </div>
                              <div className="col-8">
                                <h6>{item.auction?.nft_name}</h6>
                                <p className="color-E42575">
                                  {item.auction?.wallet_address.substring(
                                    0,
                                    10
                                  )}
                                  ...
                                  {item.auction?.wallet_address.substring(
                                    item.auction?.wallet_address.length - 10,
                                    item.auction?.wallet_address.length
                                  )}
                                </p>

                                <span className="mt-4 text-sm">
                                  Your Offer{" "}
                                  <span className="color-E42575">
                                    {item.details?.length > 1
                                      ? item.details[0].nft_name +
                                      " + " +
                                      (item.details?.length - 1) +
                                      " more"
                                      : item.details[0].nft_name}
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="d-grid gap-2 mt-4">
                              <Link
                                className="btn btn-warning btn-sm text-dark"
                                to={
                                  "/details/auction-bidder/" +
                                  item.auction_bid_id
                                }
                              >
                                Detail
                              </Link>
                              {item.status == "Running" ? (
                                <button
                                  className="btn btn-outline-warning btn-sm"
                                  type="button"
                                  onClick={() => {
                                    cancelOffer(item);
                                  }}
                                >
                                  Cancel Offer
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div className="col-8 mx-auto mt-3">
                        <div className="text-center">No Data Availabe</div>
                      </div>
                    </>
                  )
                ) : (
                  <>
                    <LoaderCardMobile></LoaderCardMobile>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </DisplayContent>
    </>
  );
}
