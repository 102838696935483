import { useEffect } from "react";
import "../../assets/css/home.css";
import imgDemo from "../../assets/img/image_5.png";
import imgCarousel from "../../assets/img/img_carousel.png";
import client from "../../axios/client";
import { useRecoilState } from "recoil";
import { carousel } from "../../store/recoil";
import { Link } from "react-router-dom";
export default function Carousel() {
    const [auction, setAuction] = useRecoilState(carousel);

    async function getDataRunning() {
        try {
            const { data } = await client.get('/api/carousel')
            setAuction(data.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getDataRunning();
    }, [])
    return (
        <>
            <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {auction.length > 0 ? (
                        auction.map((asset: any, index: any) => {
                            return (
                                <div className={index == 0 ? "carousel-item active" : "carousel-item"} data-bs-interval="7000" key={index}>
                                    <Link to={`/items/detail/${asset.id}/${asset.wallet_address}/${asset.mint_address}`}>
                                        <div className="blatant-img">
                                            <img src={asset.image_url} className="img-fluid img-carousel" alt="..." />
                                            <div className="title-carousel">
                                                {asset.nft_name}
                                                <p className="color-E42575">Blatant</p>
                                                <p>Auction End {asset.end}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    ) : (

                        <>
                            <div className="carousel-item active" data-bs-interval="2000">
                                <div className="blatant-img">
                                    <img src={imgDemo} className="img-fluid img-carousel" alt="dummy" />
                                    <div className="title-carousel">
                                        Blatant #2404
                                        <p className="color-E42575">Blatant</p>
                                        <p>Auction End 16:00:00</p>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item" data-bs-interval="2000">
                                <div className="blatant-img">
                                    <img src={imgCarousel} className="img-fluid img-carousel" alt="dummy" />
                                    <div className="title-carousel">
                                        Blatant #2404
                                        <p className="color-E42575">Blatant</p>
                                        <p>Auction End 18:11:11</p>
                                    </div>
                                </div>
                            </div>
                        </>

                    )}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade"
                    data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade"
                    data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </>
    );
}