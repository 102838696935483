import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

var hitung = 0;
export default function RequiredAuth({ children }: any) {
    const token = localStorage.getItem("token");
    if (token != null) {
        return children
    } else {
        // toast.warn(`you haven't connected your wallet`, {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "colored",
        // });
        return <Navigate to="/" />;
    }
}