import { useState, useEffect } from "react";
import FotoProfile from "../../assets/profile/profile.png";
import { useRecoilState } from "recoil";
import { auctionOffers } from "../../store/recoil";
import client from "../../axios/client";
import { Link } from "react-router-dom";
import LoaderCard from "../loading/LoaderCard";
export default function Collector() {
    const [auctionBid, setAuctionBid] = useRecoilState(auctionOffers);
    const [loading, setLoading] = useState(true);

    async function getDataBidFinish() {
        try {
            const { data } = await client.get('api/auction-bid/activity');
            setAuctionBid(data.data);
            setLoading(false);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDataBidFinish();
    }, []);
    return (
        <>
            <div className="content d-flex flex-column justify-content-center">
                <div className="row mt-2">
                    {loading == false ? (
                        auctionBid.length > 0 ? (
                            auctionBid.map((item: any, index: number) => {
                                return (
                                    <div className="col-md-4 icon-box" key={index}>
                                        <Link to={`/other-user/${item.member_id}`}>
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row" className="text-white">{index + 1}</th>
                                                        <td>
                                                            <img src={FotoProfile} className="img-fluid" width="55" alt="" />
                                                        </td>
                                                        <td className="text-white">
                                                            <h5>{item.name}</h5>
                                                            <p className="color-E42575">{item.activity} Activity</p>
                                                        </td>
                                                        <td className="text-white">
                                                            <h5>&nbsp;</h5>
                                                            {/* <p className="color-E42575">+230%</p> */}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Link>
                                    </div>
                                )
                            })
                        ) : (
                            <div className="col-8 mx-auto mb-5">
                                <div className="text-center">No Data Availabe</div>
                            </div>
                        )
                    ) : (
                        <>
                        <LoaderCard></LoaderCard>
                        </>
                    )}
                </div>

            </div>
        </>
    );
}