import React, { useEffect } from "react";
// import logo from "./logo.svg";
// import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "./custom.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  // getTorusWallet,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import Navbar from "./component/Navbar";
import NavbarMobile from "./component/NavbarMobile";
import Footer from "./component/Footer";
import Router from "./router/Router";
// import DisplayNft from "./component/DisplayNft";
import MyWallet from "./MyWallet";
import client from "./axios/client";
import ReactGA from 'react-ga';
import { useRecoilState } from "recoil";
import { currentUser } from "./store/recoil";
// import ConnectWallet from "./component/ConnectWallet";
// import ConnectWallet from "./component/ConnectWallet";
// import "bootstrap-icons/font/bootstrap-icons.css";

// import { currentUser } from "../src/store/recoil";
// import { useRecoilState } from "recoil";
function App() {
  const TRACKING_ID = "UA-252104530-1"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
  const [authUser, setAuthUser] = useRecoilState(currentUser);
  const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint
  const endpoint = React.useMemo(() => clusterApiUrl("devnet"), [network]);
  // const wallet = useWallet();

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
  // Only the wallets you configure here will be compiled into your application
  const wallets = React.useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getLedgerWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [network]
  );
  function checkScreen() {
    if (window.screen.width <= 768) {
      window.location.href = 'https://m.blatantbarter.com/';
    }
  }
  useEffect(() => {
    async function currentUser() {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const { data } = await client.get("/api/member/profile");
          setAuthUser(data.data);
        } catch (error: any) {
          console.log(error);
        }
      }
    }
    currentUser();
    checkScreen();
    const pathName = window.location.hostname +  window.location.pathname + window.location.search;
    ReactGA.pageview(pathName);
  }, []);

  return (
    <>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <Navbar>
            <MyWallet />
          </Navbar>
          <NavbarMobile>
            <MyWallet />
          </NavbarMobile>
          <main className="min-h-screen pt-8">
            <Router />
            <ToastContainer />
          </main>
          <Footer />
        </WalletProvider>
      </ConnectionProvider>
    </>
  );
}

export default App;
