import { useEffect, useState } from 'react';
import client from '../../axios/client';
import Loader from '../Loader';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
export default function AuctionNFT() {
    const [auction, setAuction] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    function msgBox(type: string = "warning", message: string) {
        if (type == "warning") {
            toast.warning(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else if (type == "success") {
            toast.success(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else if (type == "error") {
            toast.error(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }
    async function getAuction(halaman: number = 1) {
        setCurrentPage(halaman);
        try {
            const { data } = await client.get(`/api/member/profile/auction?page=${halaman}`);
            setAuction(data.data);
            console.log(data);
            setLoading(false);
        } catch (error: any) {
            msgBox('error', error);
            setLoading(false);
        }
    }

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        getAuction(page);
    };
    useEffect(() => {
        getAuction();
    }, [])
    return (
        <div className="row mt-2">
            {loading == false ? (
                auction.length > 0 ? (
                    auction.map((item: any, index: any) => {
                        return (
                            <div className="col-sm-3 col-6" key={index}>
                                <Link to={`/details/auction/${item.id}`}>
                                    <img src={item.image_url} width="100%" className="img-fluid" alt="image NFT" style={{ cursor: "pointer" }} />
                                    <h5 className="card-title">{item.nft_name}</h5>
                                    {item.status == "Running" ? (
                                        <p className="card-text text-orange">{item.status}</p>
                                    ) : item.status == "Stop" ? (
                                        <p className="card-text text-merah">{item.status}</p>
                                    ) : item.status == "Cancel" ? (
                                        <p className="card-text text-merah">{item.status}</p>
                                    ) : item.status == "Draft" ? (
                                        <p className="card-text text-orange">{item.status}</p>
                                    ) : (
                                        <p className="card-text text-hijau">{item.status}</p>
                                    )}
                                </Link>
                            </div>
                        )
                    })
                ) : (
                    <div className="col-8 mx-auto">No Data Availabe</div>
                )
            ) : (
                <>
                    <div className="col-sm-3 col-6">
                        <Loader></Loader>
                    </div>
                    <div className="col-sm-3 col-6">
                        <Loader></Loader>
                    </div>
                    <div className="col-sm-3 col-6">
                        <Loader></Loader>
                    </div>
                    <div className="col-sm-3 col-6">
                        <Loader></Loader>
                    </div>
                </>
            )}


            {auction.length > 12 ? (
                <div className="col-12 d-flex justify-content-end mt-2">
                    <div>
                        <Pagination>
                            {currentPage > 1 && (
                                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} >Previous</Pagination.Prev>
                            )}
                            {currentPage > 3 && (
                                <Pagination.Item onClick={() => handlePageChange(1)}>
                                    1
                                </Pagination.Item>
                            )}
                            {currentPage > 3 && <Pagination.Ellipsis />}
                            {currentPage > 2 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 2)}>
                                    {currentPage - 2}
                                </Pagination.Item>
                            )}
                            {currentPage > 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                                    {currentPage - 1}
                                </Pagination.Item>
                            )}
                            <Pagination.Item active>{currentPage}</Pagination.Item>
                            {currentPage < totalPages && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                                    {currentPage + 1}
                                </Pagination.Item>
                            )}
                            {currentPage < totalPages - 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 2)}>
                                    {currentPage + 2}
                                </Pagination.Item>
                            )}
                            {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
                            {currentPage < totalPages - 2 && (
                                <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                                    {totalPages}
                                </Pagination.Item>
                            )}
                            {currentPage < totalPages && (
                                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} >Last</Pagination.Next>
                            )}
                        </Pagination>
                    </div>
                </div>

            ) : (
                <></>
            )}
        </div>
    )
}