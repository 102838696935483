import React, { useEffect } from "react";
import DisplayContent from "../component/DisplayContent";
import NftImage from "../component/NftImage";
import TableCollector from "../component/TableCollector";
import ModalFilter from "../component/modal/ModalFilter";
import iconFilter from "../assets/icon/filter.svg";
import { useLocation } from "react-router";
import { useRecoilState } from "recoil";
import { searchAuction, searchKeyword } from "../store/recoil";
import client from "../axios/client";
import Auction from "../component/pencarian/Auction";

export default function ResultSearch(props: any) {
  const queryParams = new URLSearchParams(window.location.search);
  const cari = queryParams.get("keyword");
  const [keyword, setKeyword] = useRecoilState(searchKeyword);
  const [auction, setAuction] = useRecoilState(searchAuction);

  async function searching() {
    try {
      const res = await client.get("/api/member/search?nft_name=" + keyword);
      console.log(res.data);
      setAuction(res.data.auctions);
    } catch (error: any) {
      console.log(error);
    }
  }
  useEffect(() => {
    searching();
  }, []);

  return (
    <>
      <DisplayContent>
        <div className="row">
          <div className="d-flex">
            <div className="p-2 flex-grow-1">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    {" "}
                    <img
                      className="icon"
                      src="/assets/img/icon/item.svg"
                      alt=""
                      width="18"
                    />
                    Auction
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    <img
                      className="icon"
                      src="/assets/img/icon/collector.svg"
                      width="18"
                      alt=""
                    />
                    Collector
                  </button>
                </li>
              </ul>
            </div>
            <div className="p-2 flex-grow-1">
              <div className="d-flex flex-row-reverse mt-2">
                <button
                  type="button"
                  className="btn btn-dark"
                  data-bs-toggle="modal"
                  style={{ borderRadius: "15px", background: "#24182F" }}
                  data-bs-target="#exampleModalFilter"
                >
                  <img src={iconFilter} alt="" />
                </button>
              </div>
            </div>
            <ModalFilter></ModalFilter>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <h5>Searh resulth for “{keyword}”</h5>
              <br />
              <Auction></Auction>
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <h5>Searh resulth for “{keyword}”</h5>
              <br />
              <TableCollector></TableCollector>
            </div>
          </div>
        </div>
      </DisplayContent>
    </>
  );
}
