import React, { useEffect, useState } from "react";
import DisplayContent from "../component/DisplayContent";
import { metaData } from "../model/metadata";
import {
  Commitment,
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Transaction,
} from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import * as anchor from "@project-serum/anchor";
import { PROGRAM_ID } from "../utils/program";
import IDL from "../program/blatant_program_idl.json";
import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";
import { useWallet } from "@solana/wallet-adapter-react";
import axios from "axios";
import client from "../axios/client";
import { escrowNft } from "../model/escrowNft";
import msgBox from "../helpers/msgbox";
import baseUrl from "../config/base";
var body: {
  name?: string;
  imageUrl?: string;
  wallet_address: string;
  token_account: string;
  escrow_address: string;
  token_account_escrow: string;
  mint_address: string;
  start: string;
  end: string;
  txn_in: string;
  txn_out: string;
} = {
  name: "",
  imageUrl: "",
  wallet_address: "",
  token_account: "",
  escrow_address: "",
  token_account_escrow: "",
  mint_address: "",
  start: "",
  end: "",
  txn_in: "",
  txn_out: "",
};

export default function Vault() {
  const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
    "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
  );
  const [loading, setLoading] = useState(true);
  const [dataMeta, setDataMeta] = useState<escrowNft>({} as escrowNft);
  const wallet = useWallet();
  const { sendTransaction } = useWallet();
  const opts = {
    preflightCommitment: "processed" as Commitment,
  };

  const walletA: any = localStorage.getItem("walletAddres");
  const getConnection = () => {
    // const url = "https://virulent-dimensional-sanctuary.solana-mainnet.discover.quiknode.pro/a369a0013a3a344c8d8e193174d3ea3f88f17168";
    const url = baseUrl;
    let network = localStorage.getItem("network") || url;
    const connection = new Connection(network, opts.preflightCommitment);
    return connection;
  };
  let escrow: PublicKey;
  const program = new anchor.Program(
    IDL as any as anchor.Idl,
    PROGRAM_ID,
    new anchor.AnchorProvider(
      getConnection(),
      wallet as any as NodeWallet,
      opts
    )
  );

  async function getEscrow(address: PublicKey) {
    [escrow] = await PublicKey.findProgramAddress(
      [anchor.utils.bytes.utf8.encode("blatant"), address.toBuffer()],
      program.programId
    );
    body.escrow_address = escrow.toString();
    console.log("Escrownya ", escrow.toString());
    getParsedNfts(escrow.toString());
  }
  async function getParsedNfts(a: string) {
    try {
      const { data } = await client.post(`/api/escrow-nft`, {
        escrow_address: a,
      });

      console.log(data);
      setLoading(false);
      setDataMeta(data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function claimNft(mint_address: string, owner: string) {
    client
      .post("/api/check-claim-escrow", {
        mint_address: mint_address,
      })
      .then((res) => {
        console.log(res);

        claimUlang(mint_address);
      })
      .catch((err) => {
        console.log(err);
        msgBox("error", err.response.data.message);
      });
  }

  async function initUlangTokenAddress(
    buyer: PublicKey,
    mint_address: PublicKey
  ) {
    const tokenAddr = Keypair.generate();
    const txA = await program.methods
      .create()
      .accounts({
        buyer: buyer,
        xMint: mint_address,
        buyerXToken: tokenAddr.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        rent: SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
      })
      .signers([tokenAddr])
      .rpc({ skipPreflight: true });

    console.log("txA", txA);
    console.log("tokenAddr", tokenAddr.publicKey.toString());

    return tokenAddr.publicKey;
  }
  async function findAssociatedTokenAddress(
    walletAddress: PublicKey,
    tokenMintAddress: PublicKey
  ): Promise<PublicKey> {
    console.log("mintnya", tokenMintAddress.toString());

    const solana = new Connection(baseUrl);
    const account = await solana.getTokenAccountsByOwner(walletAddress, {
      mint: tokenMintAddress,
    });
    // console.log(account.value[0].pubkey.toString());
    if (account.value.length > 0) {
      console.log("token pertama", account.value[0].pubkey);

      return account.value[0].pubkey;
    } else {
      const res = await initUlangTokenAddress(walletAddress, tokenMintAddress);
      console.log("awaitnya", res);
      return res;
    }
  }
  async function claimUlang(
    mintAddress: string,
  ) {
    const userWallet: any = localStorage.getItem("walletAddres");
    const seller = new PublicKey(userWallet); // yg akan di claim
    console.log(seller.toString());
    [escrow] = await PublicKey.findProgramAddress(
      [anchor.utils.bytes.utf8.encode("blatant"), seller.toBuffer()],
      program.programId
    );
    const escrowtokenacc = await findAssociatedTokenAddress(
      escrow,
      new PublicKey(mintAddress)
    );
    const tokenacc = await findAssociatedTokenAddress(
      seller,
      new PublicKey(mintAddress)
    );
    try {
      const tx = await program.methods
        .cancel()
        .accounts({
          seller: seller,
          escrow: escrow,
          escrowedXTokens: escrowtokenacc,
          sellerXToken: tokenacc,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .rpc({ skipPreflight: true });
      // const signature = await sendTransaction(tx, program.provider.connection);
      // await program.provider.connection.confirmTransaction(
      //   signature,
      //   "processed"
      // );

      console.log("TXnya ", tx);
      //    postClaimUlang(item, signature);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getEscrow(new PublicKey(walletA));
  }, []);

  return (
    <>
      <div className="d-none d-sm-block">
        <div className="card bg-ungu text-white">
          <div className="card-body">
            <div className="row">
              <div className="col-4">Items</div>
              <div className="col-5">Mint address</div>
              <div className="col-3">Claim</div>
            </div>
          </div>
        </div>
        {dataMeta.data?.map((item: any, index: number) => {
          return (
            <div className="card bg-ungu mt-2" key={index}>
              <div className="card-body text-white">
                <div className="row">
                  <div className="col-4">
                    <div className="row">
                      <div className="col-3">
                        <img
                          src={item.image}
                          className="img-fluid"
                          width="65"
                          alt="img"
                        />
                      </div>
                      <div className="col-9">{item.name}</div>
                    </div>
                  </div>
                  <div className="col-5 text-orange">
                    {item.mintAddress.toString().substring(0, 10) +
                      "..." +
                      item.mintAddress
                        .toString()
                        .substring(
                          item.mintAddress.toString().length - 10,
                          item.mintAddress.toString().length
                        )}
                  </div>
                  <div className="col-3">
                    <div className="d-grid gap-2">
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          claimNft(item.mintAddress, item.owner);
                        }}
                      >
                        Claim
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
