import React from "react";

export default function ModalFilter() {
  return (
    <>
      <div
        className="modal fade"
        id="exampleModalFilter"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content bg-ungu">
            <div className="modal-header">
              <h5 className="modal-title fs-5" id="exampleModalLabel">
                Filter By
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-check">
                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
                <label className="form-check-label">
                  Default
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" />
                <label className="form-check-label" >
                  Recently Added
                </label>
              </div>

              <div className="form-check">
                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option2" />
                <label className="form-check-label" >
                  Oldest
                </label>
              </div>

              <div className="form-check">
                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="option2" />
                <label className="form-check-label" >
                  Lower Activity
                </label>
              </div>

              <div className="form-check">
                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios5" value="option2" />
                <label className="form-check-label" >
                  Highest Activity
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Clear
              </button>
              <button type="button" className="btn btn-warning">
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
