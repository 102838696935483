import React, { useEffect, useState } from "react";

import DisplayContent from "../component/DisplayContent";
import client from "../axios/client";
import { useParams } from "react-router-dom";
import { auctionBid } from "../model/auctionBid";
import { toast } from "react-toastify";
import {
  Commitment,
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Transaction,
} from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import { useWallet } from "@solana/wallet-adapter-react";

import IDL from "../program/blatant_program_idl.json";
import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";
import { PROGRAM_ID } from "../utils/program";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import baseUrl from "../config/base";
const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);

var escrowTokenInit: string = "";
export default function DetailsAuctionMade() {
  const { auction_bid_id } = useParams();
  const [data, setData] = useState<auctionBid>({} as auctionBid);
  const wallet = useWallet();
  const { sendTransaction } = useWallet();

  const opts = {
    preflightCommitment: "processed" as Commitment,
  };

  function getConnection() {
    let network =
      localStorage.getItem("network") || baseUrl;
    const connection = new Connection(network, opts.preflightCommitment);
    return connection;
  }
  const program = new anchor.Program(
    IDL as any as anchor.Idl,
    PROGRAM_ID,
    new anchor.AnchorProvider(
      getConnection(),
      wallet as any as NodeWallet,
      opts
    )
  );
  async function initUlangTokenAddress(
    buyer: PublicKey,
    mint_address: PublicKey
  ) {
    const tokenAddr = Keypair.generate();
    const txA = await program.methods
      .create()
      .accounts({
        buyer: buyer,
        xMint: mint_address,
        buyerXToken: tokenAddr.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        rent: SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
      })
      .signers([tokenAddr])
      .rpc({ skipPreflight: true });

    console.log("txA", txA);
    console.log("tokenAddr", tokenAddr.publicKey.toString());

    return tokenAddr.publicKey;
  }
  async function findAssociatedTokenAddress(
    walletAddress: PublicKey,
    tokenMintAddress: PublicKey
  ): Promise<PublicKey> {
    const solana = new Connection(baseUrl);
    const account = await solana.getTokenAccountsByOwner(walletAddress, {
      mint: tokenMintAddress,
    });
    if (account.value.length > 0) {
      console.log("token pertama", account.value[0].pubkey);

      return account.value[0].pubkey;
    } else {
      const res = await initUlangTokenAddress(walletAddress, tokenMintAddress);
      console.log("awaitnya", res);
      return res;
    }
  }

  function msgBox(type: string = "warning", message: string) {
    if (type == "warning") {
      toast.warning(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "success") {
      toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "error") {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }
  async function getBid() {
    try {
      const res = await client.get(`/api/member/auction/bid/${auction_bid_id}`);
      setData(res.data);
    } catch (error: any) {
      msgBox("error", error.response.data.message);
    }
  }
  function applyBid() {
    client
      .put(`/api/member/auction/bid-apply/${auction_bid_id}`)
      .then((res) => {
        setData(res.data);
        msgBox("success", "Bid Apply Successfully");
      })
      .catch((err: any) => {
        msgBox("error", err.response.data.message);
        console.log(err);
      });
  }

  function postClaim(auction_bid_id: string, signature?: any) {
    client
      .put(`/api/member/auction/claim-offer/${auction_bid_id}`, {
        txn_out: signature,
      })
      .then((res) => {
        console.log(res.data);
        msgBox("success", "Claim Auction Successfully");
        getBid();
      })
      .catch((err) => {
        console.log(err);
        msgBox("error", err.response.data.message);
      });
  }
  let escrow: PublicKey;

  async function claim(mintAddress: string, auction_bid_detail_id: string) {
    const buyer = new PublicKey(data.data?.auction.wallet_address); // yang akan mengclaim
    const seller = new PublicKey(data.data?.wallet_address); // yang akan di claim

    console.log("seller", seller.toString());
    console.log("buyer", buyer.toString());

    async function reInit(buyer: PublicKey, mint_address: PublicKey) {
      const tokenAddr = Keypair.generate();
      const txA = await program.methods
        .create()
        .accounts({
          buyer: buyer,
          xMint: mint_address,
          buyerXToken: tokenAddr.publicKey,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
          systemProgram: SystemProgram.programId,
        })
        .signers([tokenAddr])
        .rpc({ skipPreflight: true });

      console.log("txA", txA);
      console.log("tokenAddr", tokenAddr.publicKey.toString());

      escrowTokenInit = tokenAddr.publicKey.toString();
    }

    [escrow] = await PublicKey.findProgramAddress(
      [anchor.utils.bytes.utf8.encode("blatant"), seller.toBuffer()],
      program.programId
    );

    console.log(escrow.toString());
    const escrowxTokenxx = await findAssociatedTokenAddress(
      escrow,
      new PublicKey(mintAddress)
    );
    console.log(escrowxTokenxx.toString());
    const tokenacc = await findAssociatedTokenAddress(
      buyer,
      new PublicKey(mintAddress)
    );

    // // const tokenAddr = Keypair.generate();
    // // const txA = await program.methods
    // //   .create()
    // //   .accounts({
    // //     buyer: buyer,
    // //     xMint: new PublicKey(data.data?.auction.mint_address),
    // //     buyerXToken: tokenAddr.publicKey,
    // //     tokenProgram: TOKEN_PROGRAM_ID,
    // //     rent: SYSVAR_RENT_PUBKEY,
    // //     systemProgram: SystemProgram.programId,
    // //   })
    // //   .signers([tokenAddr])
    // //   .rpc({ skipPreflight: true });

    // // console.log("TXnya init ", txA);

    if (tokenacc == null) {
      msgBox("error", "You must initiliazed escrow token first");
      reInit(buyer, new PublicKey(mintAddress));
    }
    if (tokenacc.toString() == "") {
      msgBox("error", "You must initiliazed escrow token first");
      reInit(buyer, new PublicKey(mintAddress));
    }
    console.log(tokenacc.toString());

    const tx = new Transaction();
    tx.add(
      program.transaction.accept({
        accounts: {
          buyer: buyer,
          seller: seller,
          escrow: escrow,
          escrowedXTokens: escrowxTokenxx,
          buyerXTokens: tokenacc,
          tokenProgram: TOKEN_PROGRAM_ID,
        },
      })
    );

    try {
      const signature = await sendTransaction(tx, program.provider.connection);
      await program.provider.connection.confirmTransaction(
        signature,
        "processed"
      );

      console.log("TXnya ", signature);
      postClaim(auction_bid_detail_id, signature);
    } catch (error: any) {
      postClaim(auction_bid_detail_id);
    }
  }
  async function decline(id: string) {
    try {
      const res = await client.put(`/api/member/auction/bid-decline/${id}`);
      msgBox("success", res.data.message);
      getBid();
    } catch (error: any) {
      msgBox("error", error.response.data.message);
    }
  }

  useEffect(() => {
    getBid();
  }, []);
  return (
    <>
      <DisplayContent>
        <div className="row">
          <div className="col-md-4 ">
            <div className="d-none d-sm-block">
              <div className="position-relative">
                <div className="bg-linear position-absolute"></div>
                <img
                  src={data.data?.auction?.image_url}
                  className="rounded"
                  height="355px"
                  width="100%"
                  alt="NFT Image"
                />
              </div>
              <div className="title-img">
                <span className="bottom-title"></span>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="d-none d-sm-block">
              <h5>
                Auction offer From{" "}
                <span className="color-E42575">
                  {data.data?.wallet_address}
                </span>
              </h5>
              <div className="card bg-ungu">
                <div className="card-body">
                  <h6 className="text-center">Item Offer</h6>
                </div>
              </div>
            </div>
            <div className="d-none d-sm-block">
              <div className="card bg-ungu mt-3" style={{ height: "200px" }}>
                <div className="card-body">
                  <div className="row">
                    {data.data?.details?.map((item, index) => {
                      return (
                        <div className="col-5" key={index}>
                          <div className="row">
                            <div className="col-sm-5">
                              <img
                                src={item.image_url}
                                className="rounded"
                                width="100"
                                alt="Foto"
                              />
                            </div>
                            <div className="col-sm-7">
                              <p className="color-E42575">{item.nft_name}</p>
                              {data.data?.status == "Apply" ? (
                                item.status == "Claiming" ? (
                                  <button
                                    className="btn btn-sm btn-success"
                                    onClick={() => {
                                      claim(
                                        item.mint_address,
                                        item.auction_bid_detail_id
                                      );
                                    }}
                                  >
                                    Claim
                                  </button>
                                ) : (
                                  <p className="text-success">Claimed</p>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-md-none d-lg-none d-xl-none">
              <div className="card bg-ungu">
                <div className="card-header">
                  <span>offering from</span>
                  <div>
                    <span className="color-E42575">
                      {data.data?.wallet_address}
                    </span>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row row-cols-1 row-cols-md-4 g-4">
                    {data.data?.details?.map((item, index) => {
                      return (
                        <div className="col-3 col-6" key={index}>
                          <img
                            src={item.image_url}
                            className="img-fluid rounded"
                            alt={item.mint_address}
                          />
                          <h5 className="card-title">{item.nft_name}</h5>
                          {/* <p className="card-text color-E42575">Blatant</p> */}
                          {data.data?.status == "Apply" ? (
                            item.status == "Claiming" ? (
                              <button
                                className="btn btn-sm btn-success"
                                onClick={() => {
                                  claim(
                                    item.mint_address,
                                    item.auction_bid_detail_id
                                  );
                                }}
                              >
                                Claim
                              </button>
                            ) : (
                              <p className="text-success">Claimed</p>
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {data.data?.auction?.status == "Running" &&
            data.data?.status == "Running" ? (
              <div className="row mt-2">
                <div className="col-lg-6 mt-2">
                  <div className="d-grid gap-2">
                    <button
                      className="btn btn-warning"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      Comfirm Offer
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 mt-2">
                  <div className="d-grid gap-2">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => {
                        decline(data.data?.auction_bid_id);
                      }}
                    >
                      Decline
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-sm">
                <div className="modal-content bg-ungu">
                  <div className="modal-header">
                    <div className="col d-flex justify-content-center">
                      <h6
                        className="modal-title text-center"
                        id="staticBackdropLabel"
                      >
                        Confirm Auction Offer
                      </h6>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="d-flex justify-content-center">
                      <h6>Confirm auction offer from</h6> <br />
                    </div>
                    <div className="d-flex justify-content-center">
                      <p className="text-center">
                        {data.data?.wallet_address.substring(0, 10) +
                          "..." +
                          data.data?.wallet_address.substring(
                            data.data?.wallet_address.length - 5,
                            data.data?.wallet_address.length
                          )}
                        ?
                      </p>
                    </div>
                    {data.data?.auction?.status == "Running" ? (
                      <div className="row">
                        <div className="col">
                          <div className="d-grid gap-2">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-grid gap-2">
                            <button
                              type="button"
                              className="btn btn-warning"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                applyBid();
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DisplayContent>
    </>
  );
}
