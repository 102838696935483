import React from "react";
import DisplayContent from "../component/DisplayContent";
import { Link } from "react-router-dom";
export default function NotFound() {
    return (
        <>
            <DisplayContent>
                <div className="row d-flex justify-content-center mt-2 mb-3">
                    <div className="styles-font text-center">
                        <h1 style={{ fontSize: "90px" }}>404</h1>
                        <h4>Not Found</h4>
                        <p>The resource requested could not be found on this server!</p>
                        <Link to="/">
                            <button style={{ borderRadius: "8px" }} className="btn btn-outline-light btn-sm">Go Back Home</button>
                        </Link>
                    </div>
                </div>
            </DisplayContent>
        </>
    )
}