import ContentLoader from "react-content-loader";

const LoaderCard = () => (
  <ContentLoader
    height={140}
    speed={1}
    backgroundColor={"#333"}
    foregroundColor={"#999"}
    viewBox="0 1 306 40"
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="4" ry="2" width="100%" height="35" />
  </ContentLoader>
);

export default LoaderCard;
