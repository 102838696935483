import http from "axios";
import { atom, selector } from "recoil";
import client from "../axios/client";

const currentUser = atom({
  key: "currentUser",
  default: {
    id: "",
    name: "",
    bio: "",
    discord: "",
    instagram: "",
    twitter: "",
    wallet: "",
  },
});

const barters = atom({
  key: "barters",
  default: [],
});

const offers = atom({
  key: "offers",
  default: [],
});

const detailOffers = atom({
  key: "detailOffers",
  default: {
    id: "",
    initializer_address: "",
    owner_address: "",
    initializer_escrow_address: "",
    owner_escrow_address: "",
    status: "",
    initializers: [
      {
        id: "",
        name: "",
        barter_id: "",
        imageUrl: "",
        mint_address: "",
        token_account_address: "",
        escrow_token_account_address: "",
        txn: "",
        txn_out: "",
        status: "",
      },
    ],
    owners: [
      {
        id: "",
        name: "",
        barter_id: "",
        imageUrl: "",
        mint_address: "",
        token_account_address: "",
        escrow_token_account_address: "",
        txn: "",
        txn_out: "",
        status: "",
      },
    ],
  },
});

const auctionMades = atom({
  key: "auctionMades",
  default: [],
});

const auctionOffers = atom({
  key: "auctionOffers",
  default: [],
});
// const authUser = selector({
//   key: "auth-user",
//   get: async () => {
//     var user: any = {
//       id: "",
//       name: "",
//       bio: "",
//       discord: "",
//       instagram: "",
//       twitter: "",
//       wallet: "",
//       created_at: "",
//       updated_at: "",
//     };

//     return user;
//   },
// });

const barterNotif = selector({
  key: "notif-barter",
  get: async () => {
    let count: any = 0;
    // try {
    //   let { data } = await client.get("/api/member/notif-barter");
    //   console.log(data);
    // } catch (error: any) {
    //   console.log(error);
    // }

    return count;
  },
});

const searchKeyword = atom({
  key: "searchKeyword",
  default: "",
});

const searchAuction = atom({
  key: "searchAuction",
  default: [],
});

const itemAuction = atom({
  key: "itemAuction",
  default: {},
});

const allAuction = atom({
  key: "allAuction",
  default: [],
});

const carousel = atom({
  key: "carousel",
  default: [],
});

const multicarousel = atom({
  key: "multiCarousel",
  default: [],
});

const topItems = atom({
  key: "topItems",
  default: [],
});

const otherUser = atom({
  key: "otherUser",
  default: {
    id: "",
    name: "",
    bio: "",
    discord: "",
    instagram: "",
    twitter: "",
    wallet: "",
  },
});
const order = atom({
  key: "order",
  default:""
});
export {
  currentUser,
  barters,
  offers,
  auctionMades,
  auctionOffers,
  searchKeyword,
  searchAuction,
  itemAuction,
  detailOffers,
  allAuction,
  carousel,
  multicarousel,
  topItems,
  otherUser,
  barterNotif,
  order
};
