import React, { useState, useEffect } from "react";
import DisplayContent from "../component/DisplayContent";
import MFilter from "../component/explore/MFilter";
import iconItem from "../assets/icon/item.svg";
import iconCollector from "../assets/icon/collector.svg";
import iconAuction from "../assets/icon/auction.svg";
import iconFilter from "../assets/icon/filter.svg";
import ImgAuctiin from "../component/explore/ImgAuction";
import TopItems from "../component/explore/TopItems";
import Collector from "../component/explore/Collector";
import client from "../axios/client";
import { useRecoilState } from "recoil";
import { allAuction, topItems } from "../store/recoil"
import { Link } from "react-router-dom";
import Loader from "../component/Loader";
import e from "../config/base";
const Explore = () => {
    const [auction, setAuction] = useRecoilState(allAuction);
    const [items, setItems] = useRecoilState(topItems);
    const [order, setOrder] = useState("");
    const [loading, setLoading] = useState(true);

    async function getData(orderby: string = "") {
        setOrder(orderby);
        try {
            const { data } = await client.get(`/api/all/auction?created_at=${orderby}`);
            setAuction(data.data);
            console.log(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    async function getItems(orderby: string = "") {
        setOrder(orderby);
        try {
            const { data } = await client.get(`/api/top-items?created_at=${orderby}&activity=desc`);
            setItems(data.data);
            console.log(data);
            setLoading(false);
        } catch (error) {
            console.log(error)
        }
    }

    function Submit(order: string = "") {
        setLoading(true);
        getItems(order);
        getData(order);
        setLoading(false);
    }

    function handleDesc(order = "DESC") {
        setOrder(order);
        console.log(order);
    }
    function submitFilter() {
        Submit(order);
    }
    function handleASC(order = "ASC") {
        setOrder(order);
    }
    useEffect(() => {
        getItems();
        getData();
        console.log(e);
    }, []);
    return (
        <>
            <DisplayContent>
                <div className="d-flex">
                    <div className="p-2 flex-grow-1">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab"
                                    data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab"
                                    aria-controls="pills-home" aria-selected="true"><img className="icon" src={iconItem}
                                        alt="item" width="16" /> Top Items  </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-profile-tab"
                                    data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab"
                                    aria-controls="pills-profile" aria-selected="false"><img className="icon"
                                        src={iconCollector} width="16" alt="collector" /> Top Collector  </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-contact-tab"
                                    data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab"
                                    aria-controls="pills-contact" aria-selected="false"><img className="icon"
                                        src={iconAuction} width="18" alt="auction" /> Auction  </button>
                            </li>
                        </ul>
                    </div>
                    <div className="p-2 flex-grow-1">
                        <div className="d-flex flex-row-reverse mt-2">
                            <button type="button" style={{ borderRadius: "15px", background: '#24182F' }} className="btn btn-dark btn-sm" data-bs-toggle="modal"
                                data-bs-target="#exampleModalFilter">
                                <img src={iconFilter} alt="filter" />
                            </button>
                        </div>
                        <div
                            className="modal fade"
                            id="exampleModalFilter"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-sm">
                                <div className="modal-content bg-ungu">
                                    <div className="modal-header">
                                        <h5 className="modal-title fs-5" id="exampleModalLabel">
                                            Filter By
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close btn-close-white"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked/>
                                            <label className="form-check-label">
                                                Default
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" onChange={() => {
                                                handleDesc();
                                            }} type="radio" name="exampleRadios" id="exampleRadios2" value="option2" />
                                            <label className="form-check-label" >
                                                Recently Added
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input" onChange={() => {
                                                handleASC();
                                            }} type="radio" name="exampleRadios" id="exampleRadios3" value="option2" />
                                            <label className="form-check-label" >
                                                Oldest
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="option2" />
                                            <label className="form-check-label" >
                                                Lower Activity
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios5" value="option2" />
                                            <label className="form-check-label" >
                                                Highest Activity
                                            </label>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Clear
                                        </button>
                                        <button type="button" onClick={() => {
                                            submitFilter();
                                        }} className="btn btn-warning"
                                            data-bs-dismiss="modal"
                                        >
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <MFilter/> */}
                    </div>
                </div>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        {/* <TopItems /> */}
                        <div className="row row-cols-1 row-cols-md-4 g-4 mt-2">
                            {loading == false ? (
                                items.length > 0 ? (
                                    items.map((asset: any, index: any) => {
                                        return (
                                            <div className="col-3 col-6" key={index}>
                                                <img src={asset.image_url} width="100%" className="img-fluid" alt="image NFT" />
                                                <h5 className="card-title">{asset.nft_name}</h5>
                                                <p className="card-text color-E42575">Blatant</p>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div className="col-8 mx-auto mb-5">
                                        <div className="text-center">No Data Availabe</div>
                                    </div>
                                )
                            ) : (
                                <>
                                    <div className="col-3 col-6">
                                        <Loader></Loader>
                                    </div>
                                    <div className="col-3 col-6">
                                        <Loader></Loader>
                                    </div>
                                    <div className="col-3 col-6">
                                        <Loader></Loader>
                                    </div>
                                    <div className="col-3 col-6">
                                        <Loader></Loader>
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <Collector />
                    </div>
                    <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                        {/* <ImgAuctiin/> */}
                        <div className="row row-cols-1 row-cols-md-4 g-4 mt-2">
                            {loading == false ? (
                                auction.length > 0 ? (
                                    auction.map((item: any, index: any) => {
                                        return (
                                            <div className="col-3 col-6" key={index}>
                                                <Link to={`/items/detail/${item.id}/${item.escrow_address}/${item.mint_address}`}>
                                                    <img src={item.image_url} width="100%" className="img-fluid" alt="image NFT" />
                                                    <h5 className="card-title">{item.nft_name}</h5>
                                                    <p className="card-text color-E42575">Blatant</p>
                                                </Link>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div className="col-8 mx-auto mb-5">
                                        <div className="text-center">No Data Availabe</div>
                                    </div>
                                )
                            ) : (
                                <>
                                    <div className="col-3 col-6">
                                        <Loader></Loader>
                                    </div>
                                    <div className="col-3 col-6">
                                        <Loader></Loader>
                                    </div>
                                    <div className="col-3 col-6">
                                        <Loader></Loader>
                                    </div>
                                    <div className="col-3 col-6">
                                        <Loader></Loader>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </DisplayContent>
        </>
    );
}
export default Explore;