import React, { useEffect, useState } from "react";
import DisplayContent from "../component/DisplayContent";
import client from "../axios/client";
import { useRecoilState } from "recoil";
import { detailOffers } from "../store/recoil";
import { useParams } from "react-router-dom";
import {
  Commitment,
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Transaction,
} from "@solana/web3.js";

import * as anchor from "@project-serum/anchor";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

import IDL from "../program/blatant_program_idl.json";
import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";
import { PROGRAM_ID } from "../utils/program";
import { useWallet } from "@solana/wallet-adapter-react";
import { toast } from "react-toastify";
import baseUrl from "../config/base";
export default function DetailBarter() {
  const [detailBarter, setDetailBarter] = useRecoilState(detailOffers);
  const [submit, setSubmit] = useState(false);
  const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
    "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
  );
  const { barter_id } = useParams();
  async function initUlangTokenAddress(
    buyer: PublicKey,
    mint_address: PublicKey
  ) {
    const tokenAddr = Keypair.generate();
    const txA = await program.methods
      .create()
      .accounts({
        buyer: buyer,
        xMint: mint_address,
        buyerXToken: tokenAddr.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        rent: SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
      })
      .signers([tokenAddr])
      .rpc({ skipPreflight: true });

    console.log("txA", txA);
    console.log("tokenAddr", tokenAddr.publicKey.toString());

    return tokenAddr.publicKey;
  }
  async function findAssociatedTokenAddress(
    walletAddress: PublicKey,
    tokenMintAddress: PublicKey
  ): Promise<PublicKey> {
    const solana = new Connection(baseUrl);
    const account = await solana.getTokenAccountsByOwner(walletAddress, {
      mint: tokenMintAddress,
    });
    if (account.value.length > 0) {
      console.log("token pertama", account.value[0].pubkey);

      return account.value[0].pubkey;
    } else {
      const res = await initUlangTokenAddress(walletAddress, tokenMintAddress);
      console.log("awaitnya", res);
      return res;
    }
  }

  useEffect(() => {
    function FindOne() {
      client.get(`/api/barter/find-one?barter_id=${barter_id}`).then((res) => {
        const dataDetail = res.data.data;
        setDetailBarter(dataDetail);

        hitung();
      });
    }
    function hitung() {
      const len = detailBarter.owners.length;
      let i = 0;
      console.log(detailBarter.owners);

      detailBarter.owners.map((item: any, index: number) => {
        if (item.txn !== null) {
          i++;
        }
      });

      if (i >= len) {
        setSubmit(true);
        console.log(len, i);
      }
    }

    FindOne();
  }, []);

  // async function handleOnChange(event: any) {
  //   const { checked } = event.target;
  //   let value = JSON.parse(event.target.value);
  //   console.log(value);
  //   if (checked) {
  //     const tokenacc = await findAssociatedTokenAddress(
  //       new PublicKey(detailBarter.initializer_address),
  //       new PublicKey(value.tokenAddress)
  //     );

  //     body.owners.push({
  //       name: value.name,
  //       imageUrl: value.imageUrl,
  //       mint_address: value.tokenAddress,
  //       token_account_address: tokenacc.toString(),
  //     });
  //   } else {
  //     body.owners = body.owners.filter((item) => {
  //       return item.mint_address !== value.tokenAddress;
  //     });
  //   }
  // }

  function msgBox(type: string = "warning", message: string) {
    if (type == "warning") {
      toast.warning(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "success") {
      toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "error") {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }
  async function actionBarter(type: string) {
    try {
      const { data } = await client.put(`/api/member/barter/action`, {
        barter_id: barter_id,
        action: type,
      });
      msgBox("success", data.message);
      setDetailBarter({
        ...detailBarter,
        status: type,
      });
    } catch (error: any) {
      msgBox("error", error.response.data.message);
    }
  }

  const wallet = useWallet();
  const { sendTransaction } = useWallet();
  const opts = {
    preflightCommitment: "processed" as Commitment,
  };

  const getConnection = () => {
    let network =
      localStorage.getItem("network") || baseUrl;
    const connection = new Connection(network, opts.preflightCommitment);
    return connection;
  };
  const program = new anchor.Program(
    IDL as any as anchor.Idl,
    PROGRAM_ID,
    new anchor.AnchorProvider(
      getConnection(),
      wallet as any as NodeWallet,
      opts
    )
  );
  let escrow: PublicKey;

  async function getEscrowTokenAccountAddress(
    seller: PublicKey,
    initTokenAccountA: PublicKey,
    mintNft: PublicKey,
    barter_owner_id: string
  ) {
    [escrow] = await PublicKey.findProgramAddress(
      [anchor.utils.bytes.utf8.encode("blatant"), seller.toBuffer()],
      program.programId
    );

    const tokenacc = await findAssociatedTokenAddress(seller, mintNft);

    console.log("token acc nya", tokenacc.toString());

    const escrowedXTokens = Keypair.generate();
    console.log(`escrowedXTokens : `, escrowedXTokens.publicKey.toString());
    const token_account_address = initTokenAccountA.toString();

    setDetailBarter((prevState) => ({
      ...prevState,
      owners: prevState.owners.map((el: any) =>
        el.token_account_address === token_account_address.toString
          ? {
            ...el,
            escrow_token_account_address:
              escrowedXTokens.publicKey.toString(),
          }
          : el
      ),
    }));

    // setDetailBarter((prevState) => ({
    //   ...prevState,
    //   owners: prevState.owners.map((el: any) =>
    //     el.token_account_address === token_account_address.toString
    //       ? {
    //           ...el,
    //           status: "Claiming",
    //         }
    //       : el
    //   ),
    // }));

    // return;

    // const tx = await program.methods
    //   .initialize(new anchor.BN(1))
    //   .accounts({
    //     seller: seller,
    //     xMint: mintNft,
    //     // yMint: y_mint.publicKey,
    //     sellerXToken: tokenacc.toString(),
    //     escrow: escrow,
    //     escrowedXTokens: escrowedXTokens.publicKey,
    //     tokenProgram: TOKEN_PROGRAM_ID,
    //     rent: SYSVAR_RENT_PUBKEY,
    //     systemProgram: SystemProgram.programId,
    //   })
    //   .signers([escrowedXTokens])
    //   .rpc({ skipPreflight: true });

    // console.log("Tx Hash nya: ", tx);

    try {
      const { data } = await client.put(
        `/api/member/barter-owner/${barter_owner_id}`,
        {
          status: "Claiming",
          escrow_token_account_address: escrowedXTokens.publicKey.toString(),
          txn: "adaya", // nanti diupdate diibawah ya!
        }
      );
      msgBox("success", data.message);
    } catch (error: any) {
      msgBox("error", error.response.data.message);
    }
  }

  async function confirmBarter() {
    try {
      const { data } = await client.put(
        `/api/member/barter/confirm/${barter_id}`
      );

      msgBox("success", data.message);
    } catch (error: any) {
      msgBox("error", error.response.data.message);
    }
  }
  async function postClaim(idnya: string, signature?: string) {
    try {
      const { data } = await client.put(
        `/api/member/barter/claim-owner/${idnya}`,
        {
          txn_out: signature,
        }
      );

      msgBox("success", data.message);
      setDetailBarter(data.data);
    } catch (error: any) {
      msgBox("error", error.response.data.message);
    }
  }

  async function reInit(buyer: PublicKey, mint_address: PublicKey) {
    const tokenAddr = Keypair.generate();
    const txA = await program.methods
      .create()
      .accounts({
        buyer: buyer,
        xMint: mint_address,
        buyerXToken: tokenAddr.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        rent: SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
      })
      .signers([tokenAddr])
      .rpc({ skipPreflight: true });

    console.log("txA", txA);
    console.log("tokenAddr", tokenAddr.publicKey.toString());

    // escrowTokenInit = tokenAddr.publicKey.toString();
  }
  async function claimOwner(item: any, e: any) {
    e.target.disabled = true;
    e.target.innerHTML = "Claiming...";
    const buyer = new PublicKey(detailBarter.initializer_address); // yang akan mengclaim
    const seller = new PublicKey(detailBarter.owner_address); // yang akan di claim

    [escrow] = await PublicKey.findProgramAddress(
      [anchor.utils.bytes.utf8.encode("blatant"), seller.toBuffer()],
      program.programId
    );

    const mint_address = item.mint_address;
    const escrowxTokenxx = await findAssociatedTokenAddress(
      escrow,
      new PublicKey(mint_address)
    );
    // console.log("Escrowed", escrowxTokenxx.toString());
    const tokenacc = await findAssociatedTokenAddress(
      buyer,
      new PublicKey(mint_address)
    );

    if (tokenacc == null) {
      msgBox("info", "Reinitializing token account address");
      reInit(buyer, new PublicKey(mint_address));
    }
    if (tokenacc.toString() == "") {
      msgBox("error", "Reinitializing token account address");
      reInit(buyer, new PublicKey(mint_address));
    }

    console.log("tokenacc", tokenacc.toString());

    // return;
    const tx = new Transaction();
    tx.add(
      program.transaction.accept({
        accounts: {
          buyer: buyer,
          seller: seller,
          escrow: escrow,
          escrowedXTokens: escrowxTokenxx,
          buyerXTokens: tokenacc,
          tokenProgram: TOKEN_PROGRAM_ID,
        },
      })
    );
    try {
      const signature = await sendTransaction(tx, program.provider.connection);
      await program.provider.connection.confirmTransaction(
        signature,
        "processed"
      );
      postClaim(item.id, signature);
    } catch (error: any) {
      postClaim(item.id);
    }
  }

  async function postClaimUlangInit(id: string, signature?: string) {
    try {
      const { data } = await client.put(
        `/api/member/barter/claim-init-ulang/${id}`,
        {
          txn_out: signature,
        });
      msgBox("success", data.message);
      setDetailBarter(data.data);
    } catch (error: any) {
      msgBox("error", error);
    }
  }
  async function claimInitUlang(item: any, e: any) {
    e.target.disabled = true;
    e.target.innerHTML = "Claiming...";
    // const buyer = new PublicKey(detailBarter.initializer_address); // yang akan mengclaim
    const seller = new PublicKey(detailBarter.initializer_address); // yang akan di claim
    [escrow] = await PublicKey.findProgramAddress(
      [anchor.utils.bytes.utf8.encode("blatant"), seller.toBuffer()],
      program.programId
    );

    const mint_address = item.mint_address;
    const escrowxTokenxx = await findAssociatedTokenAddress(
      escrow,
      new PublicKey(mint_address)
    );
    // console.log("Escrowed", escrowxTokenxx.toString());
    const tokenacc = await findAssociatedTokenAddress(
      seller,
      new PublicKey(mint_address)
    );
    try {
      const tx = await program.methods
        .cancel()
        .accounts({
          seller: seller,
          escrow: escrow,
          escrowedXTokens: escrowxTokenxx,
          sellerXToken: tokenacc,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .rpc({ skipPreflight: true });
      // const signature = await sendTransaction(tx, program.provider.connection);
      // await program.provider.connection.confirmTransaction(
      //   signature,
      //   "processed"
      // );
      postClaimUlangInit(item.id);
      console.log("TXnya ", tx);
    } catch (error: any) {
      console.log(error);
    }
  }
  return (
    <>
      <DisplayContent>
        <div className="row d-flex justify-content-center text-white">
          {/* <div className="d-none d-sm-block">
            <div className="d-flex justify-content-center">
              <form className="pencarian-barter" role="search">
                <img className="img-barter" src={iconSearch} alt="search" />
                <input
                  type="text"
                  className="placeh"
                  placeholder="Search"
                  aria-label="Search"
                />
              </form>
            </div>
          </div> */}
          {/* <span className="text-center text-send">send barter offer to</span>
          <p className="text-center text-send">
            {detailBarter.initializer_address}
          </p> */}
          <div className="d-md-none d-lg-none d-xl-none">
            <div className="search-box">
              <input type="text" placeholder="Search" />
              <button type="button" className="btn btn-sucess">
                <i className="fa fa-search"></i>
              </button>
            </div>
          </div>
          <div className="col-10">
            <div className="d-none d-sm-block">
              <div className="row">
                <div className="col-6">
                  <div className="card bg-ungu">
                    <div className="card-body">
                      <h6 className="text-center">Items Will Send</h6>
                    </div>
                  </div>
                  <div className="card bg-ungu mt-2">
                    <div className="card-body">
                      {detailBarter.initializers.length > 0 ? (
                        detailBarter.initializers.map(
                          (item: any, index: any) => {
                            return (
                              <div className="row mt-2" key={index}>
                                <div className="col-2">
                                  <img
                                    src={item.imageUrl}
                                    className="rounded"
                                    width={60}
                                    alt=""
                                  />
                                </div>
                                <div className="col-6 d-flex align-items-center">
                                  {item.name}
                                </div>
                                <div className="col-4 d-flex align-items-center">
                                  {detailBarter.status == "Cancel" ? (

                                    item.status != "Claimed" ? (
                                      <button
                                        className="btn btn-success btn-sm btn-sm ml-2 mt-2"
                                        onClick={(e) => {
                                          claimInitUlang(item, e)
                                        }}
                                      >
                                        Re Claim
                                      </button>
                                    ) : (
                                      <></>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <div className="text-center">No Data Availabe</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card bg-ungu">
                    <div className="card-body">
                      <h6 className="text-center">Items Will Received</h6>
                    </div>
                  </div>
                  <div className="card bg-ungu mt-2">
                    <div className="card-body">
                      {detailBarter.owners.length > 0 ? (
                        detailBarter.owners.map((asset) => {
                          return (
                            <div className="row mt-2" key={asset.mint_address}>
                              <div className="col-2">
                                <img
                                  src={asset.imageUrl}
                                  className="rounded"
                                  width={60}
                                  alt=""
                                />
                              </div>
                              <div className="col-10 d-flex align-items-center">
                                {asset.name}
                              </div>
                              <div className="col-10">
                                {detailBarter.status == "Claiming" ? (
                                  <button
                                    className={
                                      asset.status == "Claimed"
                                        ? "btn btn-secondary btn-sm btn-sm ml-2 mt-2"
                                        : "btn btn-success btn-sm btn-sm ml-2 mt-2"
                                    }
                                    onClick={(e) => {
                                      if (asset.status == "Claimed") {
                                        msgBox("error", "NFT already claimed");
                                      } else {
                                        claimOwner(asset, e);
                                      }
                                    }}
                                  >
                                    {asset.status == "Claimed"
                                      ? "Claimed"
                                      : "Claim"}
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="text-center">No Data Availabe</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {detailBarter.status == "Submit" ? (
              <div className="row gap-2 mt-2">
                <div className="col">
                  <button
                    className="btn btn-warning w-100"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalOffer"
                  >
                    Approve
                  </button>
                </div>
                <div className="col">
                  <button
                    className="btn btn-danger w-100"
                    type="button"
                    onClick={() => {
                      const res = window.confirm(
                        "Are you sure, you want to reject this barter"
                      );
                      if (res) {
                        actionBarter("Reject");
                      }
                    }}
                  >
                    Reject
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )} */}

            <div
              className="modal fade"
              id="exampleModalOffer"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content bg-ungu">
                  <div className="modal-header text-white">
                    <h6
                      className="modal-title text-center mx-auto"
                      id="exampleModalLabel"
                    >
                      Confirm Barter Offer
                    </h6>
                  </div>
                  <div className="alert alert-default text-warning">
                    Please send the items before you confirm the barter
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col text-white">Item Send</div>
                      <div className="col"></div>
                      <div className="col"></div>
                    </div>
                    {detailBarter.owners.map((item) => {
                      return (
                        <div className="row" key={item.mint_address}>
                          <div className="col text-white">{item.name}</div>
                          <div className="col color-E42575">
                            <p> {item.mint_address}</p>
                          </div>
                          <div className="col">
                            {item.status == null ? (
                              <button
                                className="btn btn-warning"
                                onClick={() => {
                                  getEscrowTokenAccountAddress(
                                    new PublicKey(detailBarter.owner_address),
                                    new PublicKey(item.token_account_address),
                                    new PublicKey(item.mint_address),
                                    item.id
                                  );
                                }}
                              >
                                Send
                              </button>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <hr style={{ color: "#E42575" }} />
                    {detailBarter ? (
                      <div className="d-flex justify-content-center">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            console.log(detailBarter);
                          }}
                        >
                          Close
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => {
                            confirmBarter();
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="d-grid gap-2 mt-3">
              <button
                className="btn btn-warning"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalOffer"
                onClick={display}
              >
                Make Offer
              </button>

              <div
                className="modal fade"
                id="exampleModalOffer"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content bg-ungu">
                    <div className="modal-header text-white">
                      <h6
                        className="modal-title text-center mx-auto"
                        id="exampleModalLabel"
                      >
                        Confirm Barter Offer
                      </h6>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col text-white">Item Send</div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>

                      {offer ? (
                        body.initializers.map((item) => {
                          return (
                            <div className="row" key={item.mint_address}>
                              <div className="col text-white">{item.name}</div>
                              <div className="col color-E42575">
                                <p> {item.mint_address}</p>
                              </div>
                              <div className="col">
                                <button
                                  className="btn btn-warning"
                                  onClick={() => {
                                    getEscrowTokenAccountAddress(
                                      new PublicKey(body.initializer_address),
                                      new PublicKey(item.token_account_address),
                                      new PublicKey(item.mint_address)
                                    );
                                  }}
                                >
                                  Send
                                </button>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p></p>
                      )}
                      <hr style={{ color: "#E42575" }} />
                      <div className="row">
                        <div className="col text-white">Item Send</div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>

                      {offer ? (
                        body.owners.map((item) => {
                          return (
                            <div className="row" key={item.mint_address}>
                              <div className="col text-white">{item.name}</div>
                              <div className="col color-E42575">
                                <p> {item.mint_address}</p>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p></p>
                      )}
                      <hr style={{ color: "#E42575" }} />
                      {offer ? (
                        <div className="d-flex justify-content-center">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={hideDisplay}
                          >
                            Cancel
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={makeOffer}
                          >
                            Confirm
                          </button>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              
            </div> */}

            {/* <ModalCardNFT></ModalCardNFT> */}
          </div>
        </div>
      </DisplayContent>
    </>
  );
}
