import React, { useEffect, useState, useRef } from "react";
import DisplayContent from "../component/DisplayContent";
import ModalFilter from "../component/modal/ModalFilter";
import iconFilter from "../assets/icon/filter.svg";
import iconPlus from "../assets/icon/plus.svg";
import iconSearch from "../assets/icon/search.svg";
import iconArrow from "../assets/icon/arrow_2.svg";
import "../assets/css/explore.css";
import CardBarterMade from "../component/barter/CardBarterMade";
import CardBarterOffer from "../component/barter/CardBarterOffer";
import CardMobileBarterMade from "../component/barter/CardMobileBarterMade";
import CardMobileBarterOffer from "../component/barter/CardMobileBarterOffer";
import { Link } from "react-router-dom";
import client from "../axios/client";
import { useRecoilState } from "recoil";
import { barters, offers } from "../store/recoil";
import LoaderCard from "../component/loading/LoaderCard";
import LoaderCardMobile from "../component/loading/LoaderCardMobile";
import { toast } from "react-toastify";
import { Pagination } from 'react-bootstrap';
import { metaData } from "../model/metadata";
import ContentLoader from "react-content-loader";
import { useNavigate } from "react-router-dom";
import {
  Commitment,
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
} from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import IDL from "../program/blatant_program_idl.json";
import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";
import { PROGRAM_ID } from "../utils/program";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { useWallet } from "@solana/wallet-adapter-react";
import baseUrl from "../config/base";
var body: {
  initializer_address: string;
  owner_address: string;
  initializer_escrow_address: string;
  owner_escrow_address: string;
  initializers: {
    name: string;
    imageUrl: string;
    mint_address: string;
    token_account_address: string;
    escrow_token_account_address: string;
    txn?: string;
    sended?: boolean;
  }[];
  owners: {
    name: string;
    imageUrl: string;
    mint_address: string;
    token_account_address: string;
  }[];
} = {
  initializer_address: "",
  owner_address: "",
  initializer_escrow_address: "",
  owner_escrow_address: "",
  initializers: [],
  owners: [],
};

var id: string;
export default function Barter() {
  const navigate = useNavigate();
  const [barter, setBarter] = useRecoilState(barters);
  const [offer, setOffers] = useRecoilState(offers);
  const [loading, setLoading] = useState(true);
  const [notif, setNotif] = useState<number>(0);
  const [pageMade, setPageMade] = useState(1);
  const [totalPagesMade, setTotalPagesMade] = useState(1);
  const [pageOffers, setPageOffers] = useState(1);
  const [totalPagesOffers, setTotalPagesOffers] = useState(1);
  const [draft, setDraft] = useState(body);
  const [loadDraft, setLoadDraft] = useState(true);
  const buttonSend: any = useRef(null);
  const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
    "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
  );
  
  async function getBarter(halaman: number = 1) {
    setPageMade(halaman);
    setPageOffers(halaman);
    try {
      const { data } = await client.get(`/api/member/barter?page=${halaman}`);
      setBarter(data.data);
      setTotalPagesMade(data.made_last_page);
      setOffers(data.offers);
      setTotalPagesOffers(data.offers_last_page);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  }

  const handlePageChangeMade = (page: number) => {
    setPageMade(page);
    getBarter(page);
  };

  const handlePageChangeOffers = (page: number) => {
    setPageOffers(page);
    getBarter(page);
  };

  function msgBox(type: string = "warning", message: string) {
    if (type == "warning") {
      toast.warning(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "success") {
      toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "error") {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }
  function cancelBarter() {
    client
      .put(`/api/member/barter/cancel/${id}`)
      .then((res) => {
        getBarter();
        msgBox("success", "Success Cancel Barter");
      })
      .catch((error: any) => {
        msgBox("error", error.response.data.message);
      });
  }

  async function getNotif() {
    try {
      const { data } = await client.get("/api/member/notif-barter");
      setNotif(data);
      console.log(data);
    } catch (error: any) {
      console.log(error);
    }
  }

  async function FindOne(barter_id: string) {
    client.get(`/api/barter/find-one?barter_id=${barter_id}`).then((res) => {
      setDraft(res.data.data);
      console.log(res.data.data);
      setLoadDraft(false);
    }).catch((error: any) => {
      msgBox("error", error.response.data.message);
      setLoadDraft(false);
    })
  }

  let escrow: PublicKey;
  const wallet = useWallet();
  const { sendTransaction } = useWallet();
  const opts = {
    preflightCommitment: "processed" as Commitment,
  };
  const getConnection = () => {
    let network =
      localStorage.getItem("network") || baseUrl;
    const connection = new Connection(network, opts.preflightCommitment);
    return connection;
  };

  const program = new anchor.Program(
    IDL as any as anchor.Idl,
    PROGRAM_ID,
    new anchor.AnchorProvider(
      getConnection(),
      wallet as any as NodeWallet,
      opts
    )
  );
  async function getEscrowTokenAccountAddress(
    seller: PublicKey,
    initTokenAccountA: PublicKey,
    mintNft: PublicKey,
    e: any
  ) {
    // change text button
    e.target.innerHTML = "Sending...";
    e.target.disabled = true;

    // [escrow] = await PublicKey.findProgramAddress(
    //   [anchor.utils.bytes.utf8.encode("blatant"), seller.toBuffer()],
    //   program.programId
    // );
    // const escrowedXTokens = Keypair.generate();
    // console.log(`escrowedXTokens :: `, escrowedXTokens.publicKey.toString());
    const token_account_address = initTokenAccountA.toString();

    // draft.initializers.forEach((item) => {
    //   if (item.token_account_address === token_account_address) {
    //     item.escrow_token_account_address =
    //       escrowedXTokens.publicKey.toString();
    //   }
    // });

    // const tx = await program.methods
    //   .initialize(new anchor.BN(1))
    //   .accounts({
    //     seller: seller,
    //     xMint: mintNft,
    //     sellerXToken: initTokenAccountA.toString(),
    //     escrow: escrow,
    //     escrowedXTokens: escrowedXTokens.publicKey,
    //     tokenProgram: TOKEN_PROGRAM_ID,
    //     rent: SYSVAR_RENT_PUBKEY,
    //     systemProgram: SystemProgram.programId,
    //   })
    //   .signers([escrowedXTokens])
    //   .rpc({ skipPreflight: true });

    // console.log("Tx Hash nya: ", tx);
    draft.initializers.forEach((item) => {
      if (item.token_account_address === token_account_address) {
        item.txn = "10100101";
        item.sended = true;
      }
    });

    refresh();
  }

  function refresh() {
    setLoading(true);
    setLoading(false);
  }
  function validation() {
    if (body.initializers.length === 0) {
      msgBox("warning", "Please select your NFT to barter");
      return false;
    }
    if (body.owners.length === 0) {
      msgBox("warning", "Please select owner NFT to barter");
      return false;
    }
    if (body.initializer_address === "") {
      msgBox("warning", "Please connect wallet address");
      return false;
    }
    if (body.owner_address === "") {
      msgBox("warning", "Please input wallet address owner");
      return false;
    }
    return true;
  }
  function makeOffer(barterId:string) {
    const validator = validation();
    if (!validator) {
      return false;
    }

    draft.initializers.map((item) => {
      if (item.sended === false) {
        msgBox("warning", "Please make sure all NFT is initialized or Sended");
        return false;
      }
    });

    client
      .put(
        `/api/member/barter-submit`,
        {
          barter_id: barterId,
        })
      .then((res) => {
        msgBox("success", res.data.message);
        navigate("/barter");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        msgBox("error", err.response.data.message);
        console.log(err.response.data.message);
      });
  }

  useEffect(() => {
    // FindOne();
    getBarter();
    getNotif();
  }, []);

  return (
    <>
      <DisplayContent>
        <div className="col">
          <div className="row d-md-none d-lg-none d-xl-none">
            <div className="col-5">
              <button
                className="btn btn-secondary"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                <img src={iconSearch} alt="" />
              </button>
              <div
                className="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content bg-ungu">
                    <div className="modal-header">
                      <h6 className="modal-title" id="staticBackdropLabel">
                        Search
                      </h6>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <form className="auction-cari me-auto" role="search">
                        <img className="serch" src={iconSearch} alt="search" />
                        <input
                          type="text"
                          className="placeh"
                          placeholder="Search"
                          aria-label="Search"
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-7">
              <div className="d-grid gap-2">
                <Link
                  className="btn btn-warning btn-sm text-dark"
                  style={{ fontSize: "16px" }}
                  to="/new/barter"
                >
                  Create New Barter +
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="p-2 flex-grow-1">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Barter Made
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Barter Offer{" "}
                  {notif > 0 ? (
                    <span className="badge bg-danger">{notif}</span>
                  ) : (
                    ""
                  )}
                </button>
              </li>
              &nbsp;&nbsp;&nbsp;
              <li className="nav-item" role="presentation">
                <button
                  type="button"
                  className="filter btn btn-dark d-none d-sm-block"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalFilter"
                >
                  <img src={iconFilter} width="20" alt="icon filter" />
                </button>
              </li>
              <ModalFilter></ModalFilter>
            </ul>
          </div>
          <div className="p-2 flex-grow-1">
            <div className="d-md-none d-lg-none d-xl-none">
              <button
                type="button"
                style={{ borderRadius: "15px", background: "#24182F" }}
                className="btn btn-dark"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <img src={iconFilter} alt="" />
              </button>
            </div>
            <div className="d-none d-sm-block">
              <div className="d-grid gap-2">
                <Link
                  className="btn btn-warning btn-sm text-dark"
                  style={{ fontSize: "15px" }}
                  to="/new/barter"
                >
                  Create New Barter{" "}
                  <img width="20" height="20" src={iconPlus} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="tab-content" id="pills-tabContent">
            {/* BarterMade */}
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="d-none d-sm-block">
                <hr />
                <div className="card bg-ungu text-white">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-1">Status</div>
                      <div className="col-4">User</div>
                      <div className="col-4 d-flex justify-content-center">
                        Items Send
                      </div>
                      <div className="col-1"></div>
                      <div className="col-2">Items Receive</div>
                    </div>
                  </div>
                </div>
                {loading == false ? (
                  barter.length > 0 ? (
                    barter.map((item: any) => {
                      return (
                        <div className="card mt-4 bg-ungu" key={item.id}>
                          <div className="card-body">
                            <div className="row">
                              <div
                                className={
                                  item.status == "Claimed"
                                    ? "col-1 text-hijau fs-6"
                                    : "col-1 text-orange fs-6"
                                }
                              >
                                {item.status == "Claiming"
                                  ? "Running"
                                  : item.status}
                              </div>
                              <div className="col-4 text-white">
                                You offered Barter with <br />
                                <span
                                  style={{ color: "#E42575" }}
                                  className="fs-6"
                                >
                                  {item.owner_address}
                                </span>
                              </div>
                              <div className="col-4">
                                <div className="d-flex justify-content-center mt-2">
                                  {item.initializers.map((d: any, j: any) => {
                                    return (
                                      <div className="mx-1" key={j}>
                                        <img
                                          src={d.imageUrl}
                                          className="img-fluid"
                                          width="65"
                                          alt=""
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <div className="col-1 d-flex align-items-center">
                                <div className="d-flex align-items-center">
                                  <img src={iconArrow} alt="" />
                                </div>
                              </div>
                              <div className="col-2 d-flex align-items-center">
                                {item.owners.map((d2: any, j2: any) => {
                                  return (
                                    <div className="mx-1" key={j2}>
                                      <img
                                        src={d2.imageUrl}
                                        className="img-fluid"
                                        width="65"
                                        alt=""
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <hr />
                            <div className="row d-flex justify-content-end">
                              <div className="col-2">
                                {/* {item.status == 'Draft' ? (
                                  <button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModalDraft"
                                    onClick={() => {
                                      FindOne(item.id);
                                    }}
                                  >Draft</button>
                                ) : (
                                  <></>
                                )} */}
                                <div
                                  className="modal fade"
                                  id="exampleModalDraft"
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog modal-md">
                                    <div className="modal-content bg-ungu">
                                      <div className="modal-header text-white">
                                        <h5
                                          className="modal-title text-center mx-auto"
                                          id="exampleModalLabel"
                                        >
                                          Draft
                                        </h5>
                                      </div>
                                      <div className="modal-body">
                                        <div className="row">
                                          <div className="col-12 mt-2">
                                            <div
                                              className="card bg-ungu"
                                              style={{ border: "1px solid #fff" }}>
                                              <div
                                                className="card-header"
                                                style={{ borderBottom: "1px solid #fff" }}>
                                                <h6 className="card-title text-center">
                                                  Item will send
                                                </h6>
                                              </div>
                                              <div className="card-body">
                                                <div className="table-resonsive">
                                                  <table className="table text-white">
                                                    <tbody>
                                                      {loadDraft === false ? (
                                                        draft.initializers.map((d: any) => {
                                                          return (
                                                            <tr key={d.mint_address}>
                                                              <td>{d.name}</td>
                                                              <td className="color-E42575">
                                                                {d.mint_address
                                                                  .toString()
                                                                  .substring(0, 8) +
                                                                  "..." +
                                                                  d.mint_address
                                                                    .toString()
                                                                    .substring(
                                                                      d.mint_address.toString()
                                                                        .length - 5
                                                                    )}
                                                              </td>
                                                              <td>
                                                                {d.sended === false ? (
                                                                  <button
                                                                    className="btn btn-warning"
                                                                    onClick={(e) => {
                                                                      getEscrowTokenAccountAddress(
                                                                        new PublicKey(draft.initializer_address),
                                                                        new PublicKey(
                                                                          d.token_account_address
                                                                        ),
                                                                        new PublicKey(d.mint_address),
                                                                        e
                                                                      );
                                                                    }}
                                                                    ref={buttonSend}
                                                                  >
                                                                    Send
                                                                  </button>
                                                                ) : (
                                                                  <></>
                                                                )}

                                                              </td>
                                                            </tr>
                                                          )
                                                        })
                                                      ) : (
                                                        <tr>
                                                          <td colSpan={3}>
                                                            <ContentLoader
                                                              speed={1}
                                                              backgroundColor={"#333"}
                                                              foregroundColor={"#999"}
                                                              viewBox="0 0 380 70">
                                                              {/* Only SVG shapes */}
                                                              <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                                                              <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                                              <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                                                            </ContentLoader>
                                                          </td>
                                                        </tr>
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-12 mt-2">
                                            <div
                                              className="card bg-ungu"
                                              style={{ border: "1px solid #fff" }}>
                                              <div
                                                className="card-header"
                                                style={{ borderBottom: "1px solid #fff" }}>
                                                <h6 className="card-title text-center">
                                                  Item will Received
                                                </h6>
                                              </div>
                                              <div className="card-body">
                                                <div className="table-resonsive">
                                                  <table className="table text-white">
                                                    <tbody>
                                                      {loadDraft === false ? (
                                                        draft.owners.map((o: any) => {
                                                          return (
                                                            <tr key={o.mint_address}>
                                                              <td>{o.name}</td>
                                                              <td className="color-E42575">
                                                                {o.mint_address
                                                                  .toString()
                                                                  .substring(0, 8) +
                                                                  "..." +
                                                                  o.mint_address
                                                                    .toString()
                                                                    .substring(
                                                                      o.mint_address.toString()
                                                                        .length - 5
                                                                    )}
                                                              </td>

                                                            </tr>
                                                          )
                                                        })
                                                      ) : (
                                                        <tr>
                                                          <td colSpan={3}>
                                                            <ContentLoader
                                                              speed={1}
                                                              backgroundColor={"#333"}
                                                              foregroundColor={"#999"}
                                                              viewBox="0 0 380 70">
                                                              {/* Only SVG shapes */}
                                                              <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                                                              <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                                              <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                                                            </ContentLoader>
                                                          </td>
                                                        </tr>
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row mt-3">
                                          <div className="col-6">
                                            <button
                                              type="button"
                                              className="btn btn-secondary w-100"
                                              data-bs-dismiss="modal"
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                          <div className="col-6">
                                            <button
                                              type="button"
                                              onClick={() => {
                                                makeOffer(item.id);
                                              }}
                                              className="btn btn-warning w-100"
                                              data-bs-dismiss="modal"
                                            >
                                              Confirm
                                            </button>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2">
                                {item.status == "Submit" ? (
                                  <button
                                    type="button"
                                    className="btn btn-outline-warning w-100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModalCancel"
                                    onClick={() => {
                                      id = item.id;
                                    }}
                                  >
                                    Cancel
                                  </button>
                                ) : (
                                  <></>
                                )}

                                <div
                                  className="modal fade"
                                  id="exampleModalCancel"
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog modal-md">
                                    <div className="modal-content bg-ungu">
                                      <div className="modal-header text-white">
                                        <h5
                                          className="modal-title text-center mx-auto"
                                          id="exampleModalLabel"
                                        >
                                          Cancel Barter
                                        </h5>
                                      </div>
                                      <div className="modal-body">
                                        <h6 className="text-center text-white">
                                          Are you sure want to cancel barter with
                                        </h6>
                                        <p className="text-center color-E42575">
                                          {item.owner_address}
                                        </p>

                                        <div className="d-flex justify-content-center">
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                          >
                                            Cancel
                                          </button>
                                          &nbsp;
                                          <button
                                            type="button"
                                            onClick={() => {
                                              cancelBarter();
                                            }}
                                            className="btn btn-warning"
                                            data-bs-dismiss="modal"
                                          >
                                            Confirm
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2">
                                <Link
                                  to={"/detail/barters/" + item.id}
                                  className="btn btn-warning btn-sm text-dark w-100"
                                >
                                  Detail
                                </Link>
                              </div>

                            </div>
                          </div>
                        </div>

                      );
                    })
                  ) : (
                    <div className="col-8 mx-auto mt-3">
                      <div className="text-center">No Data Availabe</div>
                    </div>
                  )
                ) : (
                  // <CardBarterMade></CardBarterMade>
                  <>
                    <div className="mt-2">
                      <LoaderCard></LoaderCard>
                      <LoaderCard></LoaderCard>
                    </div>
                  </>
                )}

                <div className="row mt-4">
                  <div className="d-flex justify-content-end">
                    <Pagination>
                      {pageMade > 1 && (
                        <Pagination.Prev onClick={() => handlePageChangeMade(pageMade - 1)} >Previous</Pagination.Prev>
                      )}
                      {pageMade > 3 && (
                        <Pagination.Item onClick={() => handlePageChangeMade(1)}>
                          1
                        </Pagination.Item>
                      )}
                      {pageMade > 3 && <Pagination.Ellipsis />}
                      {pageMade > 2 && (
                        <Pagination.Item onClick={() => handlePageChangeMade(pageMade - 2)}>
                          {pageMade - 2}
                        </Pagination.Item>
                      )}
                      {pageMade > 1 && (
                        <Pagination.Item onClick={() => handlePageChangeMade(pageMade - 1)}>
                          {pageMade - 1}
                        </Pagination.Item>
                      )}
                      <Pagination.Item active>{pageMade}</Pagination.Item>
                      {pageMade < totalPagesMade && (
                        <Pagination.Item onClick={() => handlePageChangeMade(pageMade + 1)}>
                          {pageMade + 1}
                        </Pagination.Item>
                      )}
                      {pageMade < totalPagesMade - 1 && (
                        <Pagination.Item onClick={() => handlePageChangeMade(pageMade + 2)}>
                          {pageMade + 2}
                        </Pagination.Item>
                      )}
                      {pageMade < totalPagesMade - 2 && <Pagination.Ellipsis />}
                      {pageMade < totalPagesMade - 2 && (
                        <Pagination.Item onClick={() => handlePageChangeMade(totalPagesMade)}>
                          {totalPagesMade}
                        </Pagination.Item>
                      )}
                      {pageMade < totalPagesMade && (
                        <Pagination.Next onClick={() => handlePageChangeMade(pageMade + 1)} >Last</Pagination.Next>
                      )}
                    </Pagination>
                  </div>
                </div>
              </div>
              <div className="d-md-none d-lg-none d-xl-none">
                {loading == false ? (
                  barter.length > 0 ? (
                    barter.map((item: any, index: any) => {
                      return (
                        <div
                          className="card mt-1 bg-ungu"
                          key={index}
                          style={{ maxWidth: "1100px" }}
                        >
                          <div className="card-header bg-card-header">
                            <div className="row">
                              <div className="col-5">
                                <div
                                  className={
                                    item.status == "Claimed"
                                      ? "card-title text-hijau"
                                      : "card-title text-orange"
                                  }
                                >
                                  {item.status == "Claiming"
                                    ? "Running"
                                    : item.status}
                                </div>
                              </div>
                              <div className="col-7 d-flex justify-content-end">
                                {/* <div>Expired in 02:56:12:12</div> */}
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="mt-3">
                              <h6>You offered Barter with kfollows</h6>
                              <p className="color-E42575">
                                {item.owner_address}
                              </p>
                            </div>
                            <div className="mt-5">
                              <h6>Items Send</h6>
                              {item.initializers.map((d: any, j: number) => {
                                return (
                                  <p className="color-E42575" key={j}>
                                    {d.name} {d.mint_address}
                                  </p>
                                );
                              })}
                            </div>
                            <div className="mt-5">
                              <h6>Items Receive</h6>
                              {item.owners.map((d2: any, j2: number) => {
                                return (
                                  <p className="color-E42575" key={j2}>
                                    {d2.name} {d2.mint_address}
                                  </p>
                                );
                              })}
                            </div>

                            <div className="d-grid gap-2 mt-5">
                              {/* <button className="btn btn-warning" type="button">
                            Make New offer
                          </button> */}
                              {item.status == "Submit" ? (
                                <button
                                  className="btn btn-outline-warning"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModalCancelMobile"
                                  onClick={() => {
                                    id = item.id;
                                  }}
                                >
                                  Cancel Offer
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div
                              className="modal fade"
                              id="exampleModalCancelMobile"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-md">
                                <div className="modal-content bg-ungu">
                                  <div className="modal-header text-white">
                                    <h5
                                      className="modal-title text-center mx-auto"
                                      id="exampleModalLabel"
                                    >
                                      Cancel Barter
                                    </h5>
                                  </div>
                                  <div className="modal-body">
                                    <h6 className="text-center text-white">
                                      Are you sure want to cancel barter with
                                    </h6>
                                    <p className="text-center color-E42575">
                                      {item.owner_address}
                                    </p>

                                    <div className="d-flex justify-content-center">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                      >
                                        Cancel
                                      </button>
                                      &nbsp;
                                      <button
                                        type="button"
                                        onClick={() => {
                                          cancelBarter();
                                        }}
                                        className="btn btn-warning"
                                        data-bs-dismiss="modal"
                                      >
                                        Confirm
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div className="col-8 mx-auto mt-3">
                        <div className="text-center">No Data Availabe</div>
                      </div>
                    </>
                  )
                ) : (
                  <LoaderCardMobile></LoaderCardMobile>
                )}
              </div>
              {/* <Pagination></Pagination> */}
              {/* <Pagination data={barter}/> */}
            </div>
            {/* EndBarterMade */}

            {/* BarterOffer */}
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="d-none d-sm-block">
                <hr />
                <div className="card bg-ungu text-white">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-1">Status</div>
                      <div className="col-4">User</div>
                      <div className="col-2">Items Send</div>
                      <div className="col-1"></div>
                      <div className="col-4 d-flex justify-content-center">
                        Items Receive
                      </div>
                    </div>
                  </div>
                </div>
                {loading == false ? (
                  offer.length > 0 ? (
                    offer.map((item: any, index: any) => {
                      return (
                        <div className="card mt-4 bg-ungu" key={index}>
                          <div className="card-body">
                            <div className="row">
                              <div
                                className={
                                  item.status == "Finish"
                                    ? "col-1 text-hijau fs-6"
                                    : "col-1 text-orange fs-6"
                                }
                              >
                                {item.status == "Claiming"
                                  ? "Running"
                                  : item.status}
                              </div>
                              <div className="col-4 text-white">
                                You offered Barter with <br />
                                <span
                                  className="fs-6"
                                  style={{ color: "#E42575" }}
                                >
                                  {item.initializer_address}
                                </span>
                              </div>
                              <div className="col-4">
                                <div className="d-flex justify-content-center mt-2">
                                  {item.initializers.map((d: any, j: any) => {
                                    return (
                                      <div className="mx-1" key={j}>
                                        <img
                                          src={d.imageUrl}
                                          className="img-fluid"
                                          width="65"
                                          alt=""
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <div className="col-1 d-flex align-items-center">
                                <div className="d-flex align-items-center">
                                  <img src={iconArrow} alt="" />
                                </div>
                              </div>
                              <div className="col-2 d-flex align-items-center">
                                {item.owners.map((d2: any, j2: any) => {
                                  return (
                                    <div className="mx-1" key={j2}>
                                      <img
                                        src={d2.imageUrl}
                                        className="img-fluid"
                                        width="65"
                                        alt=""
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <hr />
                            <div className="row d-flex justify-content-end">
                              {/* <div className="col-12">Expired in 02:56:12:12</div> */}
                              <div className="col-2">
                                <Link
                                  to={`/detail/barter/${item.id}`}
                                  type="button"
                                  className="btn btn-warning w-100"
                                >
                                  Detail
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-8 mx-auto mt-3">
                      <div className="text-center">No Data Availabe</div>
                    </div>
                  )
                ) : (
                  <>
                    <div className="mt-2">
                      <LoaderCard></LoaderCard>
                      <LoaderCard></LoaderCard>
                    </div>
                  </>
                )}
                <div className="row mt-4">
                  <div className="d-flex justify-content-end">
                    <Pagination>
                      {pageOffers > 1 && (
                        <Pagination.Prev onClick={() => handlePageChangeOffers(pageOffers - 1)} >Previous</Pagination.Prev>
                      )}
                      {pageOffers > 3 && (
                        <Pagination.Item onClick={() => handlePageChangeOffers(1)}>
                          1
                        </Pagination.Item>
                      )}
                      {pageOffers > 3 && <Pagination.Ellipsis />}
                      {pageOffers > 2 && (
                        <Pagination.Item onClick={() => handlePageChangeOffers(pageOffers - 2)}>
                          {pageOffers - 2}
                        </Pagination.Item>
                      )}
                      {pageOffers > 1 && (
                        <Pagination.Item onClick={() => handlePageChangeOffers(pageOffers - 1)}>
                          {pageOffers - 1}
                        </Pagination.Item>
                      )}
                      <Pagination.Item active>{pageOffers}</Pagination.Item>
                      {pageOffers < totalPagesOffers && (
                        <Pagination.Item onClick={() => handlePageChangeOffers(pageOffers + 1)}>
                          {pageOffers + 1}
                        </Pagination.Item>
                      )}
                      {pageOffers < totalPagesOffers - 1 && (
                        <Pagination.Item onClick={() => handlePageChangeOffers(pageOffers + 2)}>
                          {pageOffers + 2}
                        </Pagination.Item>
                      )}
                      {pageOffers < totalPagesOffers - 2 && <Pagination.Ellipsis />}
                      {pageOffers < totalPagesOffers - 2 && (
                        <Pagination.Item onClick={() => handlePageChangeOffers(totalPagesOffers)}>
                          {totalPagesOffers}
                        </Pagination.Item>
                      )}
                      {pageOffers < totalPagesOffers && (
                        <Pagination.Next onClick={() => handlePageChangeOffers(pageOffers + 1)} >Last</Pagination.Next>
                      )}
                    </Pagination>
                  </div>
                </div>
              </div>
              <div className="d-md-none d-lg-none d-xl-none">
                {loading == false ? (
                  offer.length > 0 ? (
                    offer.map((item: any, index: any) => {
                      return (
                        <div
                          className="card mt-1 bg-ungu"
                          style={{ maxWidth: "1100px" }}
                          key={index}
                        >
                          <div className="card-header bg-card-header">
                            <div className="row">
                              <div className="col-5">
                                <div
                                  className={
                                    item.status == "Finish"
                                      ? "card-title text-hijau"
                                      : "card-title text-orange"
                                  }
                                >
                                  {item.status == "Claiming"
                                    ? "Running"
                                    : item.status}
                                </div>
                              </div>
                              <div className="col-7 d-flex justify-content-end">
                                {/* <div>Expired in {item.end}</div> */}
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="mt-2">
                              <h6>You offered Barter with kfollows</h6>
                              <p className="color-E42575">
                                {item.initializer_address}{" "}
                              </p>
                            </div>
                            <div className="mt-5">
                              <h6>Items Send</h6>
                              {item.owners.length > 0 ? (
                                item.owners.map((i: any, key: number) => {
                                  return (
                                    <p className="color-E42575" key={key}>
                                      {i.name} {i.mint_address.toString()}
                                    </p>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="mt-5">
                              <h6>Items Receive</h6>
                              {item.initializers.length > 0 ? (
                                item.initializers.map((i: any, key: number) => {
                                  return (
                                    <p className="color-E42575" key={key}>
                                      {i.name} {i.mint_address.toString()}
                                    </p>
                                  );
                                })
                              ) : (
                                <div className="text-center">
                                  No Data Availabe
                                </div>
                              )}
                            </div>

                            {/* <div className="d-grid gap-2 mt-5">
                                <button
                                  className="btn btn-warning"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModalOffer"
                                >
                                  Comfirm
                                </button>
            
                              </div> */}
                            <div className="d-grid gap-2 mt-5">
                              <Link
                                type="button"
                                className="btn btn-warning text-dark"
                                to={`/detail/barter/${item.id}`}
                              >
                                Detail
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div className="col-8 mx-auto mt-3">
                        <div className="text-center">No Data Availabe</div>
                      </div>
                    </>
                  )
                ) : (
                  <LoaderCardMobile></LoaderCardMobile>
                )}
              </div>
              {/* <div className="col d-flex justify-content-end">
                <Pagination></Pagination>
              </div> */}
              <div
                className="modal fade"
                id="exampleModalOffer"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-md">
                  <div className="modal-content bg-ungu">
                    <div className="modal-header text-white">
                      <h6
                        className="modal-title text-center mx-auto"
                        id="exampleModalLabel"
                      >
                        Confirm Barter Offer
                      </h6>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col text-white">Item Send</div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>

                      <div className="row">
                        <div className="col text-white">Blatant #1234</div>
                        <div className="col color-E42575">
                          <p> RoLLn5qBN4juQ1D2KFpJy...</p>
                        </div>
                      </div>
                      <hr style={{ color: "#E42575" }} />
                      <div className="row">
                        <div className="col text-white">Item Send</div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>

                      <div className="row">
                        <div className="col text-white">Blatant #1234</div>
                        <div className="col color-E42575">
                          <p> RoLLn5qBN4juQ1D2KFpJy...</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-white">Blatant #1234</div>
                        <div className="col color-E42575">
                          <p> RoLLn5qBN4juQ1D2KFpJy...</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-white">Blatant #1234</div>
                        <div className="col color-E42575">
                          <p> RoLLn5qBN4juQ1D2KFpJy...</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-white">Blatant #1234</div>
                        <div className="col color-E42575">
                          <p> RoLLn5qBN4juQ1D2KFpJy...</p>
                        </div>
                      </div>
                      <hr style={{ color: "#E42575" }} />
                      <div className="d-flex justify-content-center">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        &nbsp;
                        <button type="button" className="btn btn-warning">
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* EndBarterOffer */}
          </div>
        </div>
      </DisplayContent>
    </>
  );
}
