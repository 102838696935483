import React, { useEffect, useRef, useState } from "react";
import DisplayContent from "../component/DisplayContent";
import iconImg from "../assets/icon/img.svg";
import iconSearch from "../assets/icon/search.svg";
import "../assets/css/auction.css";
import { metaData } from "../model/metadata";
import imgDemo from "../assets/img/image_5.png";
import label2 from "../assets/icon/label2.png";
import { Link } from "react-router-dom";
// import { FetchNFTClient } from "@audius/fetch-nft";
import {
  Commitment,
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
} from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import client from "../axios/client";
import ReactGA from 'react-ga';


import * as anchor from "@project-serum/anchor";

import IDL from "../program/blatant_program_idl.json";
import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";
import { PROGRAM_ID } from "../utils/program";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { currentUser } from "../store/recoil";
import { useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import Loader from "../component/Loader";
import { FetchNFTClient } from "@audius/fetch-nft";
import baseUrl from "../config/base";
var body: {
  name?: string;
  imageUrl?: string;
  wallet_address: string;
  token_account: string;
  escrow_address: string;
  token_account_escrow: string;
  mint_address: string;
  start: string;
  end: string;
  jam: string;
  txn_in: string;
  txn_out: string;
} = {
  name: "",
  imageUrl: "",
  wallet_address: "",
  token_account: "",
  escrow_address: "",
  token_account_escrow: "",
  mint_address: "",
  start: "",
  end: "",
  jam: "",
  txn_in: "",
  txn_out: "",
};
export default function NewAuction() {
  const navigate = useNavigate();
  const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
    "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
  );
  const selectedImg = useRef();
  const [user, setUser] = useRecoilState(currentUser);
  const [dataMeta, setDataMeta] = useState<metaData>({} as metaData);
  const [selected, setSelected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sendGas, setSendGas] = useState<boolean>(false);
  const [auctionId, setAuctionId] = useState<string>("");

  // async function getNFT() {
  //   const solanaConfig = {
  //     rpcEndpoint: "https://virulent-dimensional-sanctuary.solana-mainnet.discover.quiknode.pro/a369a0013a3a344c8d8e193174d3ea3f88f17168",
  //   };
  //   const fetchClient = new FetchNFTClient({ solanaConfig });

  //   // Fetching all collectibles for the given wallets
  //   const res = await fetchClient.getSolanaCollectibles([
  //     "7rRVKoUpUQUeyHrwtNuzvo85oCYYGLmpA92Cu4m7P8uy",
  //   ]);
  //   console.log("res");
  //   console.log(res);
  // }
  async function getParsedNfts() {
    // const wallet = user.wallet;
    const wallet: any = localStorage.getItem("walletAddres");

    const bodyLoad = {
      method: "qn_fetchNFTs",
      params: [wallet, []],
    };
    const options = {
      method: "POST",
      body: JSON.stringify(bodyLoad),
    };

    const res = await fetch(baseUrl, options
    );

    setDataMeta(await res.json());
    body.wallet_address = wallet;
    getEscrow(new PublicKey(wallet));

    console.log(dataMeta);
    setLoading(false);
  }

  async function findAssociatedTokenAddress(
    walletAddress: PublicKey,
    tokenMintAddress: PublicKey
  ): Promise<PublicKey> {
    const solana = new Connection(baseUrl);
    const account = await solana.getTokenAccountsByOwner(walletAddress, {
      mint: tokenMintAddress,
    });
    console.log(account.value[0].pubkey.toString());
    return account.value[0].pubkey;
  }
  async function handleChange(item: any) {
    // setSelected(true);
    // const sel = selectedImg.current;

    // const userWallet = user.wallet;
    const userWallet: any = localStorage.getItem("walletAddres");

    const tokenacc = await findAssociatedTokenAddress(
      new PublicKey(userWallet),
      new PublicKey(item.tokenAddress)
    );

    body.name = item.name;
    body.imageUrl = item.imageUrl;
    body.wallet_address = userWallet;
    body.mint_address = item.tokenAddress;
    body.token_account = tokenacc.toString();

    console.log(body);

    const mint_address = new PublicKey(item.tokenAddress);

    [escrow] = await PublicKey.findProgramAddress(
      [anchor.utils.bytes.utf8.encode("blatant"), mint_address.toBuffer()],
      program.programId
    );
    body.token_account_escrow = escrow.toString();

    confirm();
  }
  function msgBox(type: string = "warning", message: string) {
    if (type == "warning") {
      toast.warning(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "success") {
      toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "error") {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type == "info") {
      toast.info(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  function display() {
    const validator = validation();
    if (!validator) {
      return false;
    }
    if (body.imageUrl === "" || body.imageUrl === undefined || body.imageUrl === null) {
      msgBox("warning", "Please select your NFT to new Auction");
      return false;
    }

    if (auctionId === "" || auctionId === undefined || auctionId === null) {
      client
        .post("/api/member/auctions", body)
        .then((res) => {
          console.log(res);
          msgBox("info", res.data.message);
          setAuctionId(res.data.data.id);
        })
        .catch((err: any) => {
          msgBox("error", err.response.data.message);
        });
    }
  }
  async function getEscrowTokenAccountAddress(
    seller: PublicKey,
    initTokenAccountA: PublicKey,
    mintNft: PublicKey
  ) {
    [escrow] = await PublicKey.findProgramAddress(
      [anchor.utils.bytes.utf8.encode("blatant"), seller.toBuffer()],
      program.programId
    );
    const escrowedXTokens = Keypair.generate();
    console.log(`escrowedXTokens :: `, escrowedXTokens.publicKey.toString());

    body.token_account_escrow = escrowedXTokens.publicKey.toString();

    const tx = await program.methods
      .initialize(new anchor.BN(1))
      .accounts({
        seller: seller,
        xMint: mintNft,
        sellerXToken: initTokenAccountA.toString(),
        escrow: escrow,
        escrowedXTokens: escrowedXTokens.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        rent: SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
      })
      .signers([escrowedXTokens])
      .rpc({ skipPreflight: true });

    console.log("Tx Hash nya: ", tx);

    body.txn_in = tx;

    client
      .put(
        `/api/member/auction-running`,
        {
          aution_id: auctionId,
          txn_in: tx
        })
      .then((res) => {
        msgBox("success", res.data.message);
        navigate("/auction");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        msgBox("error", err.response.data.message);
        console.log(err.response.data.message);
      });
    // client
    //   .post("/api/member/auctions", body)
    //   .then((res) => {
    //     console.log(res);

    //     msgBox("success", res.data.message);
    //     navigate("/auction");
    //   })
    //   .catch((err: any) => {
    //     msgBox("error", err.response.data.message);
    //   });
  }

  const wallet = useWallet();
  const { sendTransaction } = useWallet();
  const opts = {
    preflightCommitment: "processed" as Commitment,
  };
  const getConnection = () => {
    // const url = "https://virulent-dimensional-sanctuary.solana-mainnet.discover.quiknode.pro/a369a0013a3a344c8d8e193174d3ea3f88f17168";
    const url = baseUrl;
    let network = localStorage.getItem("network") || url;
    const connection = new Connection(network, opts.preflightCommitment);
    return connection;
  };
  let escrow: PublicKey;

  const program = new anchor.Program(
    IDL as any as anchor.Idl,
    PROGRAM_ID,
    new anchor.AnchorProvider(
      getConnection(),
      wallet as any as NodeWallet,
      opts
    )
  );
  async function getEscrow(address: PublicKey) {
    [escrow] = await PublicKey.findProgramAddress(
      [anchor.utils.bytes.utf8.encode("blatant"), address.toBuffer()],
      program.programId
    );
    body.escrow_address = escrow.toString();
    console.log("Escrownya ", escrow.toString());
  }

  function confirm() {
    if (selected == true) {
      setSelected(!selected);
    } else {
      setSelected(true);
    }
  }
  function validation() {
    if (body.imageUrl === "" || body.imageUrl === undefined || body.imageUrl === null) {
      msgBox("warning", "Please select your NFT to new Auction");
      return false;
    }
    return true;
  }
  function submitAuction() {
    const userWallet: any = localStorage.getItem("walletAddres");
    getEscrowTokenAccountAddress(
      new PublicKey(userWallet),
      new PublicKey(body.token_account),
      new PublicKey(body.mint_address)
    );
  }

  async function sendGasFee() {
    try {
      const fromWallet = new PublicKey(user.wallet);
      const toWallet = new PublicKey(
        "6wZ6QVLnvktRNPQXURBS8v4EnSBXTKwKo2nU95KGk8Vb"
      );
      var transaction = new anchor.web3.Transaction().add(
        anchor.web3.SystemProgram.transfer({
          fromPubkey: fromWallet,
          toPubkey: toWallet,
          lamports: 20000000, // number of SOL to send
        })
      );
      const signature = await sendTransaction(
        transaction,
        program.provider.connection
      );
      await program.provider.connection.confirmTransaction(
        signature,
        "processed"
      );
      console.log("sign gas fee", signature);

      client
        .put(
          `/api/member/auction-send-fee`,
          {
            aution_id: auctionId,
            send_fee_txn: signature,
          })
        .then((res) => {
          msgBox("info", res.data.message);
        })
        .catch((err) => {
          msgBox("error", err.response.data.message);
        });

      setSendGas(true);
    } catch (error: any) {
      console.log(error);
    }
  }

  useEffect(() => {
    getParsedNfts();
    // getNFT();
    const pathName = window.location.hostname +  window.location.pathname + window.location.search;
    ReactGA.pageview(pathName);
  }, []);

  return (
    <>
      <DisplayContent>
        <div className="card bg-ungu d-none d-sm-block">
          <div className="card-body">
            <h6 className="text-center">New Auction</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mt-2">
            <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">
              <div className="card bg-ungu">
                <div className="card-header">
                  <h6 className="text-center text-white">Item For Auction</h6>
                </div>
                <div className="card-body mdl-img mb-5">
                  {selected == false ? (
                    <>
                      <div className="container d-flex justify-content-center img-center">
                        <img src={iconImg} width="45" alt="icon" />
                      </div>
                      <h6 className="text-center" style={{ fontSize: "16px" }}>
                        Place the items to be barter
                      </h6>
                    </>
                  ) : (
                    <div className="row justify-content-center">
                      <div className="col-6">
                        <img
                          src={body.imageUrl}
                          className="img-fluid rounded"
                          alt={"icon"}
                          style={{ cursor: "pointer" }}
                          onClick={confirm}
                        />
                        <span className="card-title mb-5">{body.name}</span>
                        {/* <div className="card border-dark bg-transparent border-0">
                          <div className="card-body">
                          </div>
                        </div> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </a>
            <div
              className="modal fade"
              id="exampleModal"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content bg-ungu">
                  <div className="modal-header">
                    <h2 className="modal-title fs-5" id="exampleModalLabel">
                      Place the items you have
                    </h2>
                    <button
                      type="button"
                      className="btn-close btn-close-white"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="d-flex justify-content-center mb-4 mt-4">
                    <div className="search-modal">
                      <img className="img-b" src={iconSearch} alt="" />
                      <input type="text" placeholder="Search" />
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="row d-flex justify-content-center">
                      <div className="col-10 mx-auto">
                        <div className="row row-cols-1 row-cols-md-4 g-4 mt-4">
                          {loading == false ? (
                            dataMeta.result?.assets?.length > 0 ? (
                              dataMeta.result.assets.map((asset) => {
                                return (
                                  <div
                                    className="col-3 col-6"
                                    key={asset.tokenAddress}
                                  >
                                    <div className="card border-dark bg-transparent border-0">
                                      {/* <div className="img-label1">Save</div>
                                      <div className="img-label2">
                                        <img
                                          src={label2}
                                          width="15"
                                          alt="icon"
                                        /> */}
                                      {/* </div> */}
                                      <img
                                        src={asset.imageUrl}
                                        className="img-fluid img-init"
                                        alt={asset.tokenAddress}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          handleChange(asset);
                                        }}
                                        data-bs-dismiss="modal"
                                      />
                                      <span className="card-title">
                                        {asset.name}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="col-8 mx-auto">
                                <p className="text-center">NFT not available </p>
                              </div>
                            )
                          ) : (
                            <>
                              <div className="col-3 col-6">
                                <Loader></Loader>
                              </div>
                              <div className="col-3 col-6">
                                <Loader></Loader>
                              </div>
                              <div className="col-3 col-6">
                                <Loader></Loader>
                              </div>
                              <div className="col-3 col-6">
                                <Loader></Loader>
                              </div>
                            </>
                          )}
                          {/* <div className="col-3 col-12 col-md-6 col-lg-3 col-xl-3">
                            <div className="card border-dark bg-transparent border-0">
                              <div className="img-label1">save</div>
                              <div className="img-label2">
                                <img src={label2} width="15" alt="icon" />
                              </div>
                              <img
                                src={imgDemo}
                                className="card-img-top"
                                alt="img"
                                style={{ cursor: "pointer" }}
                              />
                              <div className="card-body">
                                <span className="card-title">blatan</span>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {body.mint_address !== "" ? ( */}
                  {/* <div className="col-8 mx-auto mb-5">
                    <div className="d-grid gap-2 mt-5">
                      <button
                        className="btn btn-warning"
                        type="button"
                        data-bs-dismiss="modal"
                        onClick={confirm}
                      >
                        Confirm
                      </button>
                    </div>
                  </div> */}
                  {/* ) : (
                    <></>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-2">
            <div className="card bg-ungu">
              <div className="card-body mb-5">
                <form action="">
                  <div className="form-group">
                    <div className="mt-4">
                      <label>Expired Date</label>
                      <input
                        type="date"
                        className="form-control"
                        style={{
                          background: "rgba(255, 255, 255, 0.2)",
                          border: "none",
                          color: "#fff",
                        }}
                        onChange={(e) => {
                          body.end = e.target.value;
                        }}
                      />
                    </div>
                    <div className="mt-2">
                      <input
                        type="time"
                        className="form-control"
                        style={{
                          background: "rgba(255, 255, 255, 0.2)",
                          border: "none",
                          color: "#fff",
                        }}
                        onChange={(e) => {
                          body.jam = e.target.value;
                          console.log(body.jam);
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="d-grid gap-2 mt-4">
              <button
                className="btn btn-warning"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalConfirm"
                onClick={display}
              >
                Confirm
              </button>
            </div>
            <div className="d-md-none d-lg-none d-xl-none">
              <div className="d-grid gap-2 mt-4">
                <Link to="/auction" className="btn btn-outline-warning" type="button">
                  Cancel
                </Link>
              </div>
            </div>
            <div
              className="modal fade"
              id="exampleModalConfirm"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-sm">
                <div className="modal-content bg-ungu">
                  <div className="modal-header">
                    <h2 className="modal-title fs-5" id="exampleModalLabel">
                      Confirm New Auction?
                    </h2>
                    <button
                      type="button"
                      className="btn-close text-white"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="d-flex justify-content-center">
                      <img
                        src={body.imageUrl}
                        width="150"
                        alt={body.mint_address}
                      />
                    </div>

                    <p className="text-center">{body.name}</p>
                    <p className="text-center">
                      {body.mint_address.toString().substring(0, 10) +
                        "..." +
                        body.mint_address
                          .toString()
                          .substring(
                            body.mint_address.toString().length - 8,
                            body.mint_address.toString().length
                          )}
                    </p>
                    <div className="row mt-4">
                      {sendGas == false ? (
                        <div>
                          <div style={{ fontSize: '16px' }} className="col-12">
                            <div className="d-flex justify-content-center">
                              Pay Your Gas Fee to Create Auction:
                            </div>
                          </div>
                          <div className="col-12 mt-2">
                            <div className="d-grid gap-2">
                              <button
                                type="button"
                                className="btn btn-warning w-100"
                                onClick={() => {
                                  sendGasFee();
                                }}
                              >
                                Send Gas Fee
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {sendGas ? (
                        <>
                          <div className="col">
                            <div className="d-grid gap-2">
                              <button
                                className="btn btn-secondary"
                                type="button"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                          <div className="col">
                            <div className="d-grid gap-2">
                              <button
                                className="btn btn-warning"
                                type="button"
                                onClick={() => {
                                  submitAuction();
                                }}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DisplayContent>
    </>
  );
}
