import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/css/explore.css";
import imgDemo from "../assets/img/image_5.png";
import client from "../axios/client";
import Loader from "./Loader";
export default function NftImage() {
    const [auction, setAuction] = useState([]);
    const [loading, setLoading] = useState(true);

    async function getData() {
        try {
            const { data } = await client.get('/api/all/auction');
            setAuction(data.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <div className="row row-cols-1 row-cols-md-4 g-4 mt-2">
            {loading == false ? (
                auction.length > 0 ? (
                    auction.map((item: any, index: any) => {
                        return (
                            <div className="col-3 col-6" key={index}>
                                <Link to={`/items/detail/${item.escrow_address}/${item.mint_address}`}>
                                    <img src={item.image_url} width="100%" className="img-fluid" alt="image NFT" />
                                    <h5 className="card-title">{item.nft_name}</h5>
                                    <p className="card-text color-E42575">Blatant</p>
                                </Link>
                            </div>
                        )
                    })
                ) : (
                    <div className="text-center">No Data Availabe</div>
                )
            ) : (
                <>
                    <div className="col-3 col-6">
                        <Loader></Loader>
                    </div>
                    <div className="col-3 col-6">
                        <Loader></Loader>
                    </div>
                    <div className="col-3 col-6">
                        <Loader></Loader>
                    </div>
                    <div className="col-3 col-6">
                        <Loader></Loader>
                    </div>
                </>
            )}
        </div>
    );
}