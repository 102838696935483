import { PublicKey, Connection, Commitment } from "@solana/web3.js";

// export const PROGRAM_ID = new PublicKey("6bkZGYEj2nUW6sMLsKNcL8c3YCUq4SPMCLbwqacggPtm");
// export const PROGRAM_ID = new PublicKey("eXiS27GC9cVt4caaUJt3QrL2Hk48WPSLrE9f2frLnCX");
export const PROGRAM_ID = new PublicKey("skBgTDiDPpyyz76dZqKDReJF8zZMq3RFWG5oeYcLAYy");

const opts = {
    preflightCommitment: "processed" as Commitment,
};

export const getConnection = () => {
    let network = localStorage.getItem("network") || "https://virulent-dimensional-sanctuary.solana-mainnet.discover.quiknode.pro/a369a0013a3a344c8d8e193174d3ea3f88f17168";
    const connection = new Connection(network, opts.preflightCommitment);
    return connection;
};