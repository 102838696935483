import { useEffect, useState, useMemo } from "react";
import client from "../../axios/client";
import iconArrow from "../../assets/icon/arrow_2.svg";
import iconHummer from "../../assets/icon/hammer.svg";
import { toast } from "react-toastify";
import { Pagination } from 'react-bootstrap';
export default function Activity() {
  const [activity, setActivity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  function getActivity(halaman: number = 1) {
    setCurrentPage(halaman);
    client.get(`/api/member/activity?page=${halaman}`).then((res) => {
      setActivity(res.data.data);
      setTotalPages(res.data.last_page);
      console.log(res.data)
      setLoading(false);
    }).catch((error: any) => {
      console.log(error);
      setLoading(false);
    });
  }

  useEffect(() => {
    getActivity();
  }, []);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getActivity(page);
  };
  return (
    <>
      <div className="col-12">
        <div className="card bg-ungu mt-4">
          <div className="card-header">
            <h5>Last Activity</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-borderless text-white">
                <thead>
                  <tr>
                    <th scope="col">Activity</th>
                    <th scope="col">Transcation hash</th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {loading == false ? (
                    activity.length > 0 ? (
                      activity.map((item: any, index: number) => {
                        return (
                          <tr key={index}>
                            <th scope="row">
                              <img
                                src={
                                  item.activity == "Auction"
                                    ? iconHummer
                                    : iconArrow
                                }
                                alt=""
                              />{" "}
                              &nbsp;
                              {item.activity}
                            </th>
                            <td>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  navigator.clipboard.writeText(item.txn);
                                  toast("Copied to clibboard", {
                                    position: "top-right",
                                    autoClose: 500,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                  });
                                }}
                              >
                                {item.txn.substring(0, 10) +
                                  "..." +
                                  item.txn.substring(
                                    item.txn.length - 5,
                                    item.txn.length
                                  )}
                              </span>
                            </td>
                            <td>
                              {item.from.name == "name"
                                ? "@noname"
                                : "@" + item.from.name}
                            </td>
                            <td>
                              {item.to.name == "name"
                                ? "@noname"
                                : "@" + item.to.name}
                            </td>
                            <td>{item.created_at}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          Activity not available
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center">
                        <h5 className="text-center">
                          Loading.....
                        </h5>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              
              {activity.length > 0 ?(
                  <div>
                    <div className="d-flex justify-content-end">
                      <Pagination>
                        {currentPage > 1 && (
                          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} >Previous</Pagination.Prev>
                        )}
                        {currentPage > 3 && (
                          <Pagination.Item onClick={() => handlePageChange(1)}>
                            1
                          </Pagination.Item>
                        )}
                        {currentPage > 3 && <Pagination.Ellipsis />}
                        {currentPage > 2 && (
                          <Pagination.Item onClick={() => handlePageChange(currentPage - 2)}>
                            {currentPage - 2}
                          </Pagination.Item>
                        )}
                        {currentPage > 1 && (
                          <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                            {currentPage - 1}
                          </Pagination.Item>
                        )}
                        <Pagination.Item active>{currentPage}</Pagination.Item>
                        {currentPage < totalPages && (
                          <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                            {currentPage + 1}
                          </Pagination.Item>
                        )}
                        {currentPage < totalPages - 1 && (
                          <Pagination.Item onClick={() => handlePageChange(currentPage + 2)}>
                            {currentPage + 2}
                          </Pagination.Item>
                        )}
                        {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
                        {currentPage < totalPages - 2 && (
                          <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                            {totalPages}
                          </Pagination.Item>
                        )}
                        {currentPage < totalPages && (
                          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} >Last</Pagination.Next>
                        )}
                      </Pagination>
                    </div>
                  </div>
                
              ): (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}