import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "./RequiredAuth";
import Home from "../pages/Home";
import Explore from "../pages/Explore";
import ItemsDetail from "../pages/ItemsDetail";
import Auction from "../pages/Auction";
import NewAuction from "../pages/NewAuction";
import DetailsAuction from "../pages/DetailsAuction";
import DetailsAuctionMade from "../pages/DetailsAuctionMade";
import DetailsAuctionOffer from "../pages/DetailsAuctionOffer";
import Barter from "../pages/Barter";
import DetailBarter from "../pages/DetailBarter";
import DetailBarterInit from "../pages/DetailBarterInit";
import NewBarter from "../pages/NewBarter";
import Profile from "../pages/Profile";
import OtherUser from "../pages/OtherUser";
import ResultSearch from "../pages/ResultSearch";
import ClaimAuctionBidder from "../pages/ClaimAuctionBidder";
import NotFound from "../pages/NotFound";
import Vault from "../pages/Vault";
export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="explore" element={<Explore />} />
      <Route
        path="items/detail/:auction_id/:wallet/:mint"
        element={<ItemsDetail />}
      />
      <Route
        path="auction"
        element={
          <RequireAuth>
            <Auction />
          </RequireAuth>
        }
      />
      <Route
        path="new/auction"
        element={
          <RequireAuth>
            <NewAuction />
          </RequireAuth>
        }
      />
      <Route
        path="details/auction/:auction_id"
        element={
          <RequireAuth>
            <DetailsAuction />
          </RequireAuth>
        }
      />
      <Route
        path="details/auction-made/:auction_bid_id"
        element={
          <RequireAuth>
            <DetailsAuctionMade />
          </RequireAuth>
        }
      />
      <Route
        path="details/auction-bidder/:auction_bid_id"
        element={
          <RequireAuth>
            <ClaimAuctionBidder />
          </RequireAuth>
        }
      />
      <Route
        path="details/auction-offer/:auction_id"
        element={
          <RequireAuth>
            <DetailsAuctionOffer />
          </RequireAuth>
        }
      />
      <Route
        path="barter"
        element={
          <RequireAuth>
            <Barter />
          </RequireAuth>
        }
      />
      <Route
        path="detail/barter/:barter_id"
        element={
          <RequireAuth>
            <DetailBarter />
          </RequireAuth>
        }
      />
      <Route
        path="detail/barters/:barter_id"
        element={
          <RequireAuth>
            <DetailBarterInit />
          </RequireAuth>
        }
      />
      <Route
        path="new/barter"
        element={
          <RequireAuth>
            <NewBarter />
          </RequireAuth>
        }
      />
      <Route
        path="profile"
        element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        }
      />
      <Route path="other-user/:member_id" element={<OtherUser />} />
      <Route path="search" element={<ResultSearch />} />
      <Route path="not-found" element={<NotFound />} />
      <Route path="vault" element={<Vault/>} />
    </Routes>
  );
}
