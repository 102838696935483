import React, { useEffect, useState } from "react";
import DisplayContent from "../component/DisplayContent";
import FotoProfile from "../assets/profile/profile.png";
import iconCopy from "../assets/icon/copy.svg";
import iconTwit from "../assets/icon/twit.svg";
import iconIg from "../assets/icon/ig.svg";
import iconDc from "../assets/icon/dc_p.svg";
import NftImage from "../component/NftImage";
import "../assets/css/profile.css";
import client from "../axios/client";
import { useRecoilState } from "recoil";
import { otherUser } from "../store/recoil";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
export default function OtherUser() {
    const [other, setOther] = useRecoilState(otherUser);

    const { member_id } = useParams();

    async function getDataOther() {
        try {
            const { data } = await client.get(`/api/other-member/${member_id}`);
            setOther(data.data);
            console.log(data.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getDataOther();
    }, []);
    const handleClick = () => {
        navigator.clipboard.writeText(other.wallet);
        toast('Copied to clibboard', {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    return (
        <>
            <DisplayContent>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="d-flex justify-content-center">
                                    <img className="photo-profile" src={FotoProfile} width="80" alt="profile" />
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div className="row color-E42575">
                                    <div className="col-sm-2 d-flex justify-content-center">
                                        <span className="profile-name">{other.name}</span>
                                    </div>
                                    <div className="col-sm-10 d-flex justify-content-center">
                                        <div style={{ fontSize: '13px' }}>{other.wallet}</div>
                                        &nbsp;&nbsp;
                                        <span onClick={handleClick}>
                                            <img
                                                className="mr-2"
                                                src={iconCopy} alt="icon"
                                                style={{ cursor: "pointer" }}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 col-md-5">
                                        <div className="row">
                                            <div className="col-3 col-sm-2 col-md-6">
                                                Items
                                            </div>
                                            <div className="col-3 col-sm-4 col-md-6">
                                                1320
                                            </div>
                                            <div className="col-3 col-sm-2 col-md-6">
                                                Activity
                                            </div>
                                            <div className="col-3 col-sm-4 col-md-6">
                                                1320
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="profile-desc mt-4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam aenean rhoncus aliquet morbi
                            ornare. Tellus aliquam ultricies nec ipsum. Diam volutpat euismod augue semper. Diam, netus
                            turpis cras risus tellus, sit platea duis neque. Tellus vitae condimentum facilisis luctus odio.
                        </p>
                        <div className="link-sosmed">
                            <a href=""><img src={iconTwit} width="35" alt="icon" /></a>
                            &nbsp; &nbsp; &nbsp;
                            <a href=""><img src={iconIg} width="35" alt="icon" /></a>
                            &nbsp; &nbsp; &nbsp;
                            <a href=""><img src={iconDc} width="35" alt="icon" /></a>
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <button className="btn btn-warning" type="button">Make Offer</button>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row d-flex justify-content-center">
                    <NftImage></NftImage>
                </div>
            </DisplayContent>
        </>
    );
}