// client.ts
import axios from "axios";

let token = localStorage.getItem("token");

// watch token from local storage
// if token changes, update axios default header
// if token is removed, remove axios default header
window.addEventListener("storage", () => {
  token = localStorage.getItem("token");
});

if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
const client = axios.create({
  // baseURL: "http://127.0.0.1:8000", // just an example
  baseURL: "https://blatant-be.red88.id", // just an example
});

export default client;
